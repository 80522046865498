import React, { ReactText, useEffect, useState } from 'react'
import styles from './SearchSelect.module.scss'
import { IState, IUncCells } from '@root-gipro/store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { setUncCellsFullInfo } from '@root-gipro/modules/userProjects/store/actions'

interface Props {
	uncCells: IUncCells[]
	refreshFilterValue: (fieldName: string, value: string[] | string | undefined | boolean) => void
}

const SearchSelect: React.FC<Props> = ({ uncCells, refreshFilterValue }) => {
	const options: IUncCells[] = uncCells
	const dispatch = useDispatch()
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [selectedOption, setSelectedOption] = useState<IUncCells | null>(null)
	const [hasMatches, setHasMatches] = useState(true)
	const [focusToInput, setFocusToInput] = useState(false)
	const uncCodes = useSelector((state: IState) => state.filter.uncCodes)
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value)
		setSelectedOption(null)
		const searched = options.find(item => item.code.toLocaleLowerCase() === event.target.value.toLocaleLowerCase())
		if (searched) {
			setSelectedOption(searched)
		}
	}

	const handleSearchClick = (e: any) => {
		e.preventDefault()
		dispatch(setUncCellsFullInfo(selectedOption?.id as ReactText))
		setSearchTerm('')
		setSelectedOption(null)
	}
	const handleSelectUncCodes = (unc: IUncCells) => {
		setSelectedOption(unc)
		setSearchTerm(unc.code)
	}
	useEffect(() => {
		if (searchTerm) {
			if (options.length)
				setHasMatches(!!options.find(item => item.code.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())))
		} else {
			setHasMatches(true)
		}
	}, [options, searchTerm])
	useEffect(() => {
		refreshFilterValue('uncCellId', uncCodes.map(item => item.id).join(','))
	}, [uncCodes])
	return (
		<div className={styles.searchSelect}>
			<input
				type='text'
				placeholder='Поиск...'
				value={searchTerm}
				onChange={handleInputChange}
				className={`${styles.searchInput} ${!hasMatches && styles.errorInput}`}
				onBlur={() => setTimeout(() => setFocusToInput(false), 200)}
				onFocus={() => setFocusToInput(true)}
			/>
			{hasMatches && focusToInput && (
				<ul className={styles.optionList}>
					{options
						.filter((option: IUncCells) => option.code.toLowerCase().includes(searchTerm.toLowerCase()))
						.map((option: IUncCells) => (
							<li
								key={option.code}
								onClick={() => handleSelectUncCodes(option)}
								className={`${styles.optionItem} ${
									uncCodes.find(item => item.id.toString() === option.id.toString()) && styles.disableOption
								}`}
							>
								{option.code}
							</li>
						))}
				</ul>
			)}
			<button onClick={handleSearchClick} className={styles.searchButton} disabled={!selectedOption}>
				Применить
			</button>
		</div>
	)
}

export default SearchSelect
