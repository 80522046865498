import React, { MutableRefObject, memo, useEffect, useRef, useState, FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { BreadcrumbsComponent } from '@root-gipro/shared/BreadcrumbsComponent/BreadcrumbsComponent'
import {
	getSelectCommonProject,
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import { IState } from '@root-gipro/store/interfaces/state'
import { Routes } from '@root-gipro/router'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum.ts'
import { TypesNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'

const SelectedContainer: FC = memo(({ children }) => {
	const { projectObj, originalObj } = useSelector((state: IState) => state.userProjects)
	const { selectedProject } = useSelector((state: IState) => state.commonProjects)
	const { selectedPurchase } = useSelector((state: IState) => state.purchases)
	const { groups, company } = useSelector((state: IState) => state.public)
	const [typesRoute, setTypesRoute] = useState<TypesNavLink[]>()
	const [group, setGroup] = useState<number>()
	const { pathRoute, nameLink }: { currentPath: string; pathRoute: string; nameLink: string } = usePageLink()
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	let urlId: MutableRefObject<string | undefined> = useRef()

	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')

		const checkUrl =
			pathname.includes('parameters') || pathname.includes('rates') || pathname.includes('construction-resources')

		if (pathRoute === Routes.COMMON_PROJECTS && checkUrl && urlId.current) {
			dispatch(getSelectCommonProject(urlId.current))
			dispatch(getUncsCommonProject(urlId.current))
			dispatch(getUncTechnicalSpecificationsCommonProject(urlId.current))
		}

		const routeCommonProjectsParameters = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)
		const routeCommonProjectsRates = Routes.COMMON_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeCommonProjectsConstructionResources = Routes.COMMON_PROJECTS_CONSTRUCTION_RESOURCES.replace(
			/:id/g,
			urlId.current
		)

		const routeUserProjectsRates = Routes.USER_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeUserProjectsParameters = Routes.USER_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)

		const routePurchase = [PurchasesTypeEnum.Plan, PurchasesTypeEnum.Notice].map(type =>
			Routes.PURCHASES_DETAIL.replace(/:id/g, urlId?.current?.toString()!).replace(/:type/g, type)
		)

		if (
			pathname === routeCommonProjectsParameters &&
			groups &&
			selectedProject &&
			selectedProject?.groupId !== '' &&
			selectedProject?.groupId !== undefined
		) {
			setGroup(searchByKey(groups, 'id', selectedProject?.groupId))
		}

		if (routePurchase.includes(pathname)) {
			setGroup(searchByKey(company, 'id', selectedPurchase?.company_id))
		}

		const typesRoute = [
			{
				title: 'Параметры',
				path: pathRoute === Routes.COMMON_PROJECTS ? routeCommonProjectsParameters : routeUserProjectsParameters,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS],
			},
			{
				title: 'Расценки',
				path: pathRoute === Routes.COMMON_PROJECTS ? routeCommonProjectsRates : routeUserProjectsRates,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS],
			},
			{
				title: 'Строительные ресурсы',
				path: routeCommonProjectsConstructionResources,
				tags: [Routes.COMMON_PROJECTS],
			},
		]

		setTypesRoute(typesRoute)
	}, [groups, pathname, selectedProject?.groupId, selectedPurchase?.company_id])

	return (
		<>
			<div className='container'>
				<BreadcrumbsComponent
					typesRoute={typesRoute as TypesNavLink[]}
					group={groups[group as number] || originalObj}
					path={pathRoute && pathRoute}
					nameLink={nameLink}
					urlId={urlId.current}
					nameProject={selectedProject?.code}
				/>
			</div>
			{children}
		</>
	)
})
export default SelectedContainer
