import React, { ChangeEvent, useCallback, useEffect, useRef, useState, FocusEvent } from 'react'
import { withStyles, Theme, MenuItem, ClickAwayListener, Popper, IconButton, Tooltip } from '@material-ui/core'
import styles from './SelectSearch.module.scss'
import cn from 'classnames'
import Input from '../Input/Input'
import { Item } from './Item'
import { trimString } from '@root-gipro/utils/helpers/trimStrin'

export interface ISelectSearchProps {
	value: string[]
	setValue(fieldValue: string, value: string[] | string): void
	name?: string
	fieldName: string
	items: string[]
	placeholder?: string
	isDisable?: boolean
}

const SelectSearch: React.FC<ISelectSearchProps> = ({
	value,
	setValue,
	name,
	placeholder,
	isDisable,
	fieldName,
	items,
}) => {
	const [selectValue, setSelectValue] = useState<string>('')
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const inputRef = useRef<null | HTMLElement>(null)
	const handleMenuItemClick = useCallback(
		(event: React.MouseEvent<HTMLElement>, value: string) => {
			const last = selectValue.trim().slice(-1)
			let splitArray = selectValue.trim().split('|')

			if (last === '|') {
				const str = selectValue + value
				setValue(fieldName, str)
				setSelectValue(str)
			} else if (last !== '|' && splitArray.length > 0 && splitArray.lastIndexOf('')) {
				const index = splitArray.length - 1
				splitArray[index] = value
				setSelectValue(splitArray.join('|'))
				setValue(fieldName, splitArray.join('|'))
			} else {
				setValue(fieldName, value)
				setSelectValue(value)
			}
		},
		[value]
	)
	const showOptions = (event: FocusEvent<HTMLElement>) => {
		if (!event?.currentTarget) return
		setAnchorEl(event.currentTarget)
	}

	const handleClickAway = () => {
		setAnchorEl(null)
	}

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		const last = e.currentTarget.value.trim().slice(-1)

		if (last === '|') {
			setValue(fieldName, '')
			setSelectValue(e.currentTarget.value)
		} else {
			setSelectValue(e.currentTarget.value)
			setValue(fieldName, e.currentTarget.value)
		}
	}

	const toggleOptions = (event: React.MouseEvent<HTMLElement>) => {
		if (!event?.currentTarget) return
		setAnchorEl(anchorEl ? null : inputRef.current)
	}

	const clearValue = () => {
		setSelectValue('')
		setValue(fieldName, '')
	}

	return (
		<>
			{name && <h4 className='universal-select-title'>{name}</h4>}
			{placeholder && <label className='universal-select-label'>{placeholder}</label>}
			<ClickAwayListener onClickAway={handleClickAway}>
				<div>
					<div className={styles.wrapper}>
						<Input
							inputRef={inputRef}
							onFocus={showOptions}
							inputValue={selectValue}
							handleInput={handleInput}
							classes='full-width'
						/>
						<IconButton
							onClick={toggleOptions}
							type='button'
							className={cn(styles.dropdown, { [styles.rotate]: !!anchorEl })}
						>
							<span className='dropdown-icon' />
						</IconButton>
						<IconButton
							className={cn(styles.close, { [styles.rotate]: !!anchorEl })}
							onClick={clearValue}
							type='button'
						>
							<span className='close-icon' />
						</IconButton>
					</div>
					<Popper className={styles.list} open={!!anchorEl} anchorEl={anchorEl} transition placement='bottom'>
						{items.length > 0 ? (
							items.slice(0, 5).map((option, index) => (
								<Item disableGutters={false} key={index} onClick={event => handleMenuItemClick(event, option)}>
									<Tooltip key={option} title={option} enterDelay={500} enterNextDelay={500} placement='top'>
										<div>{trimString(option, 19)}</div>
									</Tooltip>
								</Item>
							))
						) : (
							<div className={styles.nothing}>Нет данных</div>
						)}
					</Popper>
				</div>
			</ClickAwayListener>
		</>
	)
}
export default SelectSearch
