import { Color } from '@material-ui/lab/Alert'
import { notifyActions, NotifyTypes } from '@root-gipro/modules/notify/store/actions'
import { INotifyState } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

const notifyState: INotifyState = {
	isOpen: false,
	message: '',
	type: 'success',
	duration: 6000,
}

const notify = (draft: Draft<INotifyState>, action: NotifyTypes) => {
	switch (action.type) {
		case notifyActions.SHOW_NOTIFY:
			const { message, type, duration } = action.payload
			draft.isOpen = true
			draft.message = message
			draft.type = type
			draft.duration = duration ? duration : draft.duration
			break
		case notifyActions.CLOSE_NOTIFY:
			draft.isOpen = false
			draft.message = ''
			draft.duration = 6000
			break
		default:
			return draft
	}
}
export const notifyReducer = produce(notify, notifyState)
