import { IPurchasesState } from '@root-gipro/modules/purchases/models/interfaces/purchases-state.model'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import { IPurchaseFilterState } from '@root-gipro/modules/purchases/models/interfaces/purchase-filter-state.model'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { IPurchasesList } from '@root-gipro/modules/purchases/models/interfaces/purchases-list-request'
import { PurchasesType } from '../models/interfaces/purchases.action'
import { ICommonProject } from '@root-gipro/store/interfaces'

export const actionFavoritePurchases = (projectId: string | 'multiple', action: 'DELETE' | 'POST') => ({
	type: PurchasesType.ACTIONS_FAVORITE_PURCHASES,
	projectId,
	action,
})

export const SetPurchasesFavorite = (list: IPurchase[]) => ({
	type: PurchasesType.SET_PURCHASE_FAVORITE,
	list,
})

export const getAllFavoritePurchasesAction = () => ({
	type: PurchasesType.GET_ALL_FAVORITE_PURCHASES,
})

export const getPurchasesList = () => ({
	type: PurchasesType.GET_LIST,
})

export const refreshList = (list: IPurchasesList[], isFavorite: boolean) => ({
	type: PurchasesType.REFRESH_LIST,
	list,
	isFavorite,
})

export const toggleLoadingPurchaseList = () => ({
	type: PurchasesType.TOGGLE_LOADING_LIST,
})

export const setPurchases = (state: IPurchasesState, isFavorite: boolean) => ({
	type: PurchasesType.SET_PURCHASES,
	state,
	isFavorite,
})

export const refreshPurchasesState = (fieldName: string, value: boolean) => ({
	type: PurchasesType.REFRESH_PURCHASES_STATE,
	fieldName,
	value,
})

export const setExpandCardState = (purchase: IPurchase) => ({
	type: PurchasesType.EXPAND_PURCHASE_CARD_LIST,
	purchase,
})

export const sortPurchaseList = (name: string, sortName: string, order?: boolean) => ({
	type: PurchasesType.SORT_PURCHASES,
	name,
	sortName,
	order,
})

export const changePurchasesType = (purchasesType: PurchasesTypeEnum) => ({
	type: PurchasesType.CHANGE_PURCHASES_TYPE,
	purchasesType,
})

export const setFilterCountResult = (count: number, fieldName: string, value: string) => ({
	type: PurchasesType.SET_FILTER_COUNT_RESULT,
	count,
	fieldName,
	value,
})

export const showFilterResult = (filterParams: IPurchaseFilterState) => ({
	type: PurchasesType.SHOW_FILTER_RESULT,
	filterParams,
})

export const selectPurchase = (purchaseID: number | string, purchaseType: PurchasesTypeEnum) => ({
	type: PurchasesType.SELECT_PURCHASE,
	purchaseID,
	purchaseType,
})

export const setSelectedPurchase = (selectedPurchase: IPurchase) => ({
	type: PurchasesType.SET_SELECTED_PURCHASE,
	selectedPurchase,
})

export const appendPurchases = () => ({
	type: PurchasesType.APPEND_PURCHASES,
})
export const setPurchasesPaginationCounter = (paginationCounter: number) => ({
	type: PurchasesType.SET_PURCHASES_PAGINATION_COUNTER,
	paginationCounter,
})

export const setPurchaseItems = (items: IPurchase[]) => ({
	type: PurchasesType.SET_PURCHASE_ITEMS,
	items,
})

export const setFilterIdsPurchases = (IDs: string[]) => ({
	type: PurchasesType.SET_FILTER_IDS_PURCHASES,
	IDs,
})

export const appendPurchaseInfo = (guid: string, fieldName: string, value: ICommonProject[] | undefined) => ({
	type: PurchasesType.APPEND_PURCHASE_INFO,
	guid,
	fieldName,
	value,
})
