import { ProjectType } from '../models/enums/projects.enum'

export const generateWithArray = (type: ProjectType.PROJECTS | ProjectType.PURCHASES) => {
	switch (type) {
		case ProjectType.PROJECTS:
		default:
			return ['resultsCnt', 'valuationPlanForecastSum', 'projects', 'isFavorite']
		case ProjectType.PURCHASES:
			return ['resultsCnt', 'projects', 'isFavorite', 'purchases', 'maximumPriceSum', 'valuationPlanForecastSum']
	}
}
