import { TariffsType } from '@root-gipro/modules/tariffs/models/interfaces/tariffs.actions'
import { Payment, TariffPlans } from '@root-gipro/modules/tariffs/models/interfaces/tariffs-state.model'

export const paymentCreate = (payment: Payment) => ({
	type: TariffsType.PAYMENT_CREATE,
	payment,
})

export const setReportType = (reportType: string) => ({
	type: TariffsType.SET_REPORT_TYPE,
	reportType,
})

export const setCalcCountMember = (calcUnlimit: boolean) => ({
	type: TariffsType.SET_CALC_COUNT_MEMBER,
	calcUnlimit,
})

export const getTariffPlans = () => ({
	type: TariffsType.GET_TARIFFS_PLANS,
})
export const setTariffPlans = (tariffPlans: TariffPlans) => ({
	type: TariffsType.SET_TARIFFS_PLANS,
	tariffPlans,
})

export const selectTarif = (id: string, unlimit: boolean) => ({
	type: TariffsType.SELECT_TARIF,
	id,
	unlimit,
})
