import React from 'react'
import { IPopupProps } from '@root-gipro/shared/Windows/DropDown/drop-down.props'
import { Popover } from '@material-ui/core'
import '@root-gipro/shared/Windows/DropDown/DropDown.scss'

export const DropDown: React.FC<IPopupProps> = ({
	anchorEl,
	setAnchorEl,
	classes,
	style,
	transformOrigin,
	anchorOrigin,
	children,
	setElem,
	setOpen,
	container,
}) => {
	const handleClose = () => {
		setAnchorEl(null!)
	}

	return (
		<Popover
			className={classes}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			anchorEl={anchorEl}
			keepMounted
			open={!!anchorEl}
			onClose={handleClose}
			container={container}
			PaperProps={{
				style: style,
			}}
		>
			<div className='drop-down'>
				<div className='drop-down__container'> {children}</div>
			</div>
		</Popover>
	)
}
