import { IPurchasesState } from '@root-gipro/modules/purchases/models/interfaces/purchases-state.model'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'

export const purchasesState: IPurchasesState = {
	list: [],
	count: 0,
	purchasesType: PurchasesTypeEnum.Plan,
	state: {},
	mSum: 0,
	iSum: 0,
	sortParams: {},
	paginationCounter: 0,
	IDs: null,
	isFavorite: false,
	linkedProjects:[]
}
