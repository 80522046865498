import { ProjectsYears } from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.model'

export const generatingArrayOfYears = (years: { [key: string]: ProjectsYears }) => {
	const yearsArray = Object.keys(years)
	let newYearsArray: string[] = []

	if (yearsArray.length) {
		yearsArray.map((year: string) => newYearsArray.push(year))
	}

	return newYearsArray
}
