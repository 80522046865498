import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '@root-gipro/store/interfaces';
import styles from './Statistics.module.css';
import { getStatistics } from '@root-gipro/modules/investmentPrograms/store/actions';


const Statistics = () => {
  const dispatch = useDispatch();
  const statistics = useSelector((state: IState) => state.investmentPrograms.statistics);

  useEffect(() => {
    dispatch(getStatistics());
  }, []);

  return (
    <div className="admin-container">
        <div className="admin-form__header">
            <h3>Статистика обновления базы ИПР</h3>

            
        </div>
      <div className={styles.statisticBlock}>
        <div className={styles.statisticItem}>
          <span className={styles.statisticLabel}>Всего проектов в базе:</span>
          <span className={styles.statisticValue}> {statistics[0]?.data?.find((item:any)=>item.param == "Всего проектов в БД дев сервера Гипро")?.value}</span>
        </div>
        <div className={styles.statisticItem}>
          <span className={styles.statisticLabel}>Актуальных:</span>
          <span className={styles.statisticValue}> {statistics[0]?.data?.find((item:any)=>item.param == "из них актуальных проектов")?.value}</span>
        </div>
        <div className={styles.statisticItem}>
          <span className={styles.statisticLabel}>Не актуальных:</span>
          <span className={styles.statisticValue}> {statistics[0]?.data?.find((item:any)=>item.param == "из них неактуальных проектов")?.value}</span>
        </div>
        
      </div>
      
      {/* История загрузок */}
      <div className={styles.historyHeader}>История загрузок:</div>
      {
        statistics.map((stat)=>{
            return (
      <div className={styles.statisticBlock}>
        <div className={styles.historyDate}>{new Date(stat.created_at as number * 1000).toLocaleDateString('ru')}</div>
        {stat.data.map((item:any)=>{
            return (
        <div className={styles.historyItem}>
          <span className={styles.historyLabel}>{item.param}</span>
          <span className={styles.historyValue}>{item.value}</span>
        </div>

            )
        })}
      </div>

            )
        })
      }

    </div>

  );
};

export default Statistics;