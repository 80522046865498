import { TableCell, TableRow, Theme, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from '@root-gipro/router'
import { useDispatch } from 'react-redux'
import './PurchaseTable.css';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bodyCell: {
			textAlign: 'center',
			maxWidth: '200px', // Limit cell width
			whiteSpace: 'nowrap', // Prevent text from wrapping
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		row: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
			'&:hover': {
				backgroundColor: '#d4d4d480',
			},
		},
	})
)

interface TableBodyRowProps {
	row: any
	company: string
	region: string
}

const TableBodyRowCommonProject: FC<TableBodyRowProps> = ({ row, company, region }) => {
	const routePath = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, row.id)
	const classes = useStyles()
	const history = useHistory()

	const dispatch = useDispatch()
	const handleBtn = () => {
		history.push(routePath)
		// getProjectById && getProjectById(row.id)
		// dispatch(setTargetProjectId(row.id))
	}
	const lots: any = JSON.parse(row.lots)[0].items[0]
    const purchase_name = JSON.parse(row.lots)[0]?.name
    const okpd2Code = lots.okpd2?.length > 0 ? lots.okpd2[0]?.code : null;
    const okved2Code = lots.okved2?.length > 0 ? lots.okved2[0]?.code : null;

	return (
		<TableRow key={row.id} className={classes.row} onClick={handleBtn}>
			<TableCell className={classes.bodyCell}>{row.id}</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.registration_number}>
					<Typography noWrap>{row.registration_number}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={company?company:"не указано"}>
					<Typography noWrap>{company?company:"не указано"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={purchase_name}>
					<Typography noWrap>{purchase_name}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.status}>
					<Typography noWrap>{row.status}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.price?row?.price:"-"}>
					<Typography noWrap>{row?.price?row?.price:"-"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.request_receiving_end_date?row.request_receiving_end_date:"не указано"}>
					<Typography noWrap>{row.request_receiving_end_date?row.request_receiving_end_date:"не указано"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.linkedProjectIds?.length}>
					<Typography noWrap>{row?.linkedProjectIds?.length}</Typography>
				</Tooltip>
			</TableCell>
					<TableCell className={classes.bodyCell}>
						<Tooltip title={row?.estimatedOfferCurrent}>
							<Typography noWrap>{okpd2Code}</Typography>
						</Tooltip>
					</TableCell>
					<TableCell className={classes.bodyCell} >
						<Tooltip title={row?.estimatedOfferCurrent}>
							<Typography noWrap>{okved2Code}</Typography>
						</Tooltip>
					</TableCell>
					<TableCell className={classes.bodyCell}>
						<Tooltip title={row?.publish_date?row?.publish_date:"нет данных"}>
							<Typography noWrap>{row?.publish_date?row?.publish_date.slice(0, 10):"нет данных"}</Typography>
						</Tooltip>
					</TableCell>
					<TableCell className={classes.bodyCell}>
						<Tooltip title={row?.plan_expiration_date?row?.plan_expiration_date:"нет данных"}>
							<Typography noWrap>{row?.plan_expiration_date?row?.plan_expiration_date.slice(0, 10):"нет данных"}</Typography>
						</Tooltip>
					</TableCell>
                    <TableCell className={classes.bodyCell}>
						<Tooltip title={row.region}>
							<Typography noWrap>{row.region}</Typography>
						</Tooltip>
					</TableCell>
                    <TableCell className={classes.bodyCell}>
						<Tooltip title={row?.isSMB?"Да":"Нет"}>
							<Typography noWrap>{row?.isSMB?"Да":"Нет"}</Typography>
						</Tooltip>
					</TableCell>
                    <TableCell className={classes.bodyCell}>
						<Tooltip title={row.type}>
							<Typography noWrap>{row.type}</Typography>
						</Tooltip>
					</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.publish_date}>
					<Typography noWrap>{row.publish_date.slice(0, 10)}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.request_receiving_end_date?row.request_receiving_end_date:"нет данных"}>
					<Typography noWrap>{row.request_receiving_end_date?row.request_receiving_end_date:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.request_examination_date?row?.request_examination_date:"нет данных"}>
					<Typography noWrap>{row?.request_examination_date?row?.request_examination_date:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.result_date?row.result_date:"нет данных"}>
					<Typography noWrap>{row.result_date?row.result_date:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_price?row?.winner?.winner_price:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_price?row?.winner?.winner_price:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_inn?row?.winner?.winner_inn:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_inn?row?.winner?.winner_inn:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_kpp?row?.winner?.winner_kpp:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_kpp?row?.winner?.winner_kpp:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_ogrn?row?.winner?.winner_ogrn:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_ogrn?row?.winner?.winner_ogrn:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_phone?row?.winner?.winner_phone:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_phone?row?.winner?.winner_phone:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row?.winner?.winner_email?row?.winner?.winner_email:"нет данных"}>
					<Typography noWrap>{row?.winner?.winner_email?row?.winner?.winner_email:"нет данных"}</Typography>
				</Tooltip>
			</TableCell>
			
		</TableRow>
	)
}

export default TableBodyRowCommonProject
