export const tabsParams = [
	{
		name: 'Общая информация',
		parameter: 'common',
	},
	{
		name: 'ПСД',
		parameter: 'psd',
	},
	{
		name: 'УНЦ',
		parameter: 'unc',
	},
	{
		name: 'Финансирование',
		parameter: 'cost',
	},
	{
		name: 'Показать все',
		parameter: 'all',
	},
]
export const types = [
	// {
	// 	title: 'Корректировка',
	// 	value: 'offer',
	// },
	// {
	// 	title: 'План',
	// 	value: 'plan',
	// },
]
