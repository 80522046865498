import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { closeNotify } from '@root-gipro/modules/notify/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'

export const Notify = () => {
	const { isOpen, type, message, duration } = useSelector((state: IState) => state.notify)
	const dispatch = useDispatch()

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch(closeNotify())
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={isOpen}
			autoHideDuration={duration}
			onClose={handleClose}
		>
			<Alert variant='filled' onClose={handleClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	)
}
