import React from 'react'
import { Tab as MuiTab, TabProps, Tabs, Theme, withStyles } from '@material-ui/core'
// rf component
const TabsGroup = withStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},

	indicator: {
		display: 'none',
	},
}))(Tabs)

const Tab = withStyles((theme: Theme) => ({
	root: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: '13px',
		lineHeight: '16px',
		borderRadius: '4px',
		height: 32,
		minHeight: 32,
		padding: '0 8px',
	},
}))((props: TabProps) => <MuiTab disableRipple {...props} />)

export { TabsGroup, Tab }
