import { withStyles, Theme, MenuItem } from '@material-ui/core'

export const Item = withStyles((theme: Theme) => {
	return {
		root: {
			display: 'block',
			whiteSpace: 'inherit',
			paddingRight: 0,
		},
	}
})(MenuItem)
