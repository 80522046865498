import React, { useEffect, useState } from 'react'
import { FilterItemSelectProps } from '@root-gipro/shared/Filter/models/filter-item-select.props'
import { isEqual } from 'lodash'
import { Select } from '@root-gipro/shared/FormElements'

const FilterItemSelect: React.FC<FilterItemSelectProps> = ({
	value,
	setValue,
	name,
	placeholder,
	isDisable,
	fieldName,
	items,
	withCheckbox,
	isMultiple,
	convertValue,
	disableCloseOnSelect,
}) => {
	const [selectValue, setSelectValue] = useState(value || [])
	useEffect(() => {
		if (!isEqual(value, selectValue)) {
			setSelectValue(value || [])
		}
	}, [value])

	return (
		<div className='aside-filter__select-filter'>
			<Select
				value={value}
				setValue={setValue}
				name={name}
				placeholder={placeholder}
				isDisable={isDisable}
				fieldName={fieldName}
				items={items}
				withCheckbox={withCheckbox}
				isMultiple={isMultiple}
				convertValue={convertValue}
				disableCloseOnSelect={disableCloseOnSelect}
			/>
		</div>
	)
}

export default FilterItemSelect
