export enum MediaType {
	UPLOAD_IPR_PDF = 'UPLOAD_IPR_PDF',
	UPLOAD_SIPR_PDF = 'UPLOAD_SIPR_PDF',
}

interface UploadIprPdf {
	type: MediaType.UPLOAD_IPR_PDF
	media: any
}
interface UploadSiprPdf {
	type: MediaType.UPLOAD_SIPR_PDF
	media: any
}

export type MediaTypes = UploadIprPdf | UploadSiprPdf
