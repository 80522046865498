import React, { useState } from 'react'
import { ICommonProject, Notices, Plans } from '@root-gipro/store/interfaces'
import { formatDate, prettyCost } from '@root-gipro/utils/helpers/common'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { generatingArrayOfYears } from '../../utils/generatingArrayOfYears'
import styled from 'styled-components'

export interface IParametersDetailedData {
	title(year?: string): string | JSX.Element | null
	value(year?: string): JSX.Element | string | number | undefined | null
	tabsParams: string[]
	clickTitle?: (() => void | null | undefined) | void | null
	classesTitle?: string
	classesValue?: string
	iteration?: string[] | null
}

const WrapperBlock = styled.div`
	border: 1px solid #e0e0e0;
	height: 32px;
	margin-top: 5px;
	display: flex;
	align-items: center;
	padding-left: 8px;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	justify-content: space-between;
	span {
		color: #aeaeae;
		margin-right: 6px;
	}
`
const BlockPlanWithOfferDiv = styled.div`
	/* box-sizing:border-box; */
	/* border: 1px solid #e0e0e0; */
	height: 32px;
	margin-top: 5px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	/* justify-content: space-between; */
	span {
		color: #aeaeae;
		margin-right: 6px;
	}
`
const OfferDiv = styled.div`
	border: 1px solid #3f4f5e;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: 8px;
	span {
		padding-left: 8px;
	}
`
const PlanDiv = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: 8px;
	border: 1px solid #e0e0e0;
	span {
		padding-left: 8px;
	}
`
const PlanDivNumber = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`

const BlurredDiv = styled.div<{ isBlurred: boolean }>`
	filter: ${props => (props.isBlurred ? 'blur(3px)' : 'none')};
	transition: filter 0.3s;
`

interface NumberDisplayProps {
	number: number
}

const NumberDisplay: React.FC<NumberDisplayProps> = ({ number }) => {
	const [isBlurred, setIsBlurred] = useState(true)

	const handleBlurToggle = () => {
		setIsBlurred(!isBlurred)
	}

	const formatNumber = (num: number) => {
		if (num) {
			const [integerPart, decimalPart] = num.toFixed(6).split('.')
			const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
			const firstTwoDecimals = decimalPart.slice(0, 2)
			const lastFourDecimals = decimalPart.slice(2, 6)
			return (
				<>
					{formattedInteger}.{firstTwoDecimals}
					<BlurredDiv isBlurred={isBlurred}>{lastFourDecimals}</BlurredDiv>
				</>
			)
		}
		return '0.00'
	}

	return (
		<PlanDivNumber onClick={handleBlurToggle}>
			{formatNumber(number)} <span>млн.р</span>
		</PlanDivNumber>
	)
}

export const parametersDetailedData = (
	showModalInvestmentProjects: (project: Notices[] | Plans[]) => void,
	toggleType: string,
	project: ICommonProject,
	minusYear: (date: string | null) => void
): IParametersDetailedData[] => {
	return [
		{
			title: () => 'Стадия реализации',
			value: () => {
				return <WrapperBlock>{project.phase ?? 'Нет данных'}</WrapperBlock>
			},
			tabsParams: ['common', 'all'],
		},
		{
			title: () => 'Год раскрытия информации',
			value: () => {
				return <WrapperBlock>{project.yearDisclosure ?? 'Нет данных'}</WrapperBlock>
			},
			tabsParams: ['common', 'all'],
		},
		{
			title: () => 'Дата публикации на сайте Минэнерго',
			value: () => {
				return (
					<WrapperBlock>{project.publicationDate ? formatDate(project.publicationDate) : 'Нет данных'}</WrapperBlock>
				)
			},
			tabsParams: ['common', 'all'],
		},
		{
			title: () => 'Период реализации',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							<span>с</span>
							{project.yearBegin}
						</PlanDiv>
						<PlanDiv>
							<span>по</span>
							{project.yearEndPlan}
						</PlanDiv>
						<OfferDiv>{project.yearEndOffer}</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['common', 'all'],
		},
		{
			title: () => {
				return 'План закупок '
			},
			value: () => {
				return <WrapperBlock>{project.plans ? project.plans.length : 0}</WrapperBlock>
			},
			tabsParams: ['common', 'all'],
			clickTitle: project.plans && project.plans.length ? showModalInvestmentProjects(project.plans) : null,
			classesTitle: project.plans && project.plans.length ? 'link link-blue' : '',
		},
		{
			title: () => 'Cтоимость по ПСД в базисном уровне цен',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.estimatedPlanBase ? prettyCost(project.estimatedPlanBase / 10 ** 6) : 'Нет данных'}
							{!!project.estimatedPlanBase && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.estimatedOfferBase ? prettyCost(project.estimatedOfferBase / 10 ** 6) : 'Нет данных'}
							{!!project.estimatedOfferBase && <span>млн.р</span>}
						</OfferDiv>
						{/* {project.estimatedPlanBase || project.estimatedOfferBase
						? toggleType === 'offer' && project.estimatedOfferBase
							? addHTML(prettyCost(project.estimatedOfferBase / 10 ** 6))
							: addHTML(prettyCost(project.estimatedPlanBase / 10 ** 6))
						: 'Нет данных'} */}
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['psd', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Стоимость по ПСД в текущем уровне цен',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.estimatedPlanCurrent ? prettyCost(project.estimatedPlanCurrent / 10 ** 6) : 'Нет данных'}
							{!!project.estimatedPlanCurrent && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.estimatedOfferCurrent ? prettyCost(project.estimatedOfferCurrent / 10 ** 6) : 'Нет данных'}
							{!!project.estimatedOfferCurrent && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['psd', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Дата составления сметной документации',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.estimatedPlanYM !== null && project.estimatedPlanYM !== 'NULL'
								? project.estimatedPlanYM
								: 'Нет данных'}
						</PlanDiv>
						<OfferDiv>
							{project.estimatedOfferYM !== null && project.estimatedOfferYM !== 'NULL'
								? project.estimatedOfferYM
								: 'Нет данных'}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['psd', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Оценка полной стоимости по УНЦ в текущих ценах',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.valuationUncPlanCurrent ? prettyCost(project.valuationUncPlanCurrent / 10 ** 6) : 'Нет данных'}
							{!!project.valuationUncPlanCurrent && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.valuationUncOfferCurrent ? prettyCost(project.valuationUncOfferCurrent / 10 ** 6) : 'Нет данных'}
							{!!project.valuationUncOfferCurrent && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['unc', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Оценка полной стоимости по УНЦ в прогнозных ценах',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.valuationUncPlanForecast ? prettyCost(project.valuationUncPlanForecast / 10 ** 6) : 'Нет данных'}
							{!!project.valuationUncPlanForecast && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.valuationUncOfferForecast
								? prettyCost(project.valuationUncOfferForecast / 10 ** 6)
								: 'Нет данных'}
							{!!project.valuationUncOfferForecast && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['unc', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Сумма ненормируемых затрат',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.additionalFundingPlan ? prettyCost(project.additionalFundingPlan / 10 ** 6) : 'Нет данных'}
							{!!project.additionalFundingPlan && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.additionalFundingOffer ? prettyCost(project.additionalFundingOffer / 10 ** 6) : 'Нет данных'}
							{!!project.additionalFundingOffer && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['unc', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Оценка стоимости затрат на ПИР в прогнозных ценах',
			value: () => {
				return <WrapperBlock>Нет данных</WrapperBlock>
			},
			tabsParams: ['unc', 'all'],
		},
		{
			title: () => 'Оценка полной стоимости в прогнозных ценах',
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.valuationPlanForecast ? prettyCost(project.valuationPlanForecast / 10 ** 6) : 'Нет данных'}
							{!!project.valuationPlanForecast && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.valuationOfferForecast ? prettyCost(project.valuationOfferForecast / 10 ** 6) : 'Нет данных'}
							{!!project.valuationOfferForecast && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['unc', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => 'Размер платы за технологическое присоединение',
			value: () => {
				return <WrapperBlock>{addHTML(prettyCost(project.connectionFee / 10 ** 6))}</WrapperBlock>
			},
			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => {
				if (project.actualFundingDate !== null) {
					return `Фактический объем финансирования на ${formatDate(project.actualFundingDate)}`
				} else {
					return `Фактический объем финансирования на`
				}
			},
			value: () => {
				return <WrapperBlock>{addHTML(prettyCost(project.actualFunding / 10 ** 6))}</WrapperBlock>
			},
			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => `Остаток финансирования в прогнозных ценах на 01.01.2023`,
			value: () => {
				return (
					<WrapperBlock>
						{project.balancePlanOnActual
							? addHTML(prettyCost(project.balancePlanOnActual / 10 ** 6))
							: 'Не предусмотрено'}
					</WrapperBlock>
				)
			},
			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
		},
		{
			title: () => `Остаток финансирования в прогнозных ценах на 01.01.2024`,
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.balancePlanOnDisclosure
								? prettyCost(project.balancePlanOnDisclosure / 10 ** 6)
								: 'Не предусмотрено'}
							{!!project.balancePlanOnDisclosure && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.balanceOffer ? prettyCost(project.balanceOffer / 10 ** 6) : 'Не предусмотрено'}
							{!!project.balanceOffer && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
		},
		{
			title: (year?: string) =>
				`Финансирование в прогнозных ценах за ${project.years && year && project.years[year].year}`,
			value: (year?: string) => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							<NumberDisplay number={Number(project.years && year && project.years[year].plan / 10 ** 6)} />
						</PlanDiv>
						<OfferDiv>
							{prettyCost(project.years && year && project.years[year].offer / 10 ** 6) || 'Не предусмотрено'}
							{<span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},

			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
			iteration: project.years ? generatingArrayOfYears(project.years) : null,
		},
		{
			title: () => `Итого финансирование в прогнозных ценах`,
			value: () => {
				return (
					<BlockPlanWithOfferDiv>
						<PlanDiv>
							{project.totalInvestmentPlan ? prettyCost(project.totalInvestmentPlan / 10 ** 6) : 'Нет данных'}
							{!!project.totalInvestmentPlan && <span>млн.р</span>}
						</PlanDiv>
						<OfferDiv>
							{project.totalInvestmentOffer ? prettyCost(project.totalInvestmentOffer / 10 ** 6) : 'Нет данных'}
							{!!project.totalInvestmentOffer && <span>млн.р</span>}
						</OfferDiv>
					</BlockPlanWithOfferDiv>
				)
			},
			tabsParams: ['cost', 'all'],
			classesValue: 'space-between',
		},
	]
}
