export const listTKP = [
	{
		labelName: 'Параметр1',
		inputName: 'parameter1',
	},
	{
		labelName: 'Параметр2',
		inputName: 'parameter2',
	},
]

export const listSubscription = [
	{
		labelName: 'Имя',
		inputName: 'name',
	},
	{
		labelName: 'Телефон',
		inputName: 'phone',
		mask: '+7(999)999-9999',
	},
	{
		labelName: 'Email',
		inputName: 'email',
	},
	{
		labelName: 'Компания',
		inputName: 'company',
	},
	{
		labelName: 'Стадия разработки проекта',
		inputName: 'projectDevelopmentStage',
	},
]
