import React, { FC, ReactText, useEffect } from 'react'
import { Tooltip, Chip, Typography } from '@material-ui/core'
import './filterSwitcherStyles.scss'
import { IState, IUncCell, IUncCells } from '@root-gipro/store/interfaces'
import SearchSelect from './IputForSearch'
import { useDispatch, useSelector } from 'react-redux'
import { cleanUncCodeFromFilter, deleteUncCodeFromFilter } from '../store/actions'
import { setFilterUncsModalState } from '../store/actions'
import { setUncCellsFullInfo } from '@root-gipro/modules/userProjects/store/actions'

interface Props {
	uncCells: IUncCells[]
	refreshFilterValue: (fieldName: string, value: string[] | string | undefined | boolean) => void
}

const FilterSwitcher: FC<Props> = ({ uncCells, refreshFilterValue }) => {
	const dispatch = useDispatch()
	const uncCodes = useSelector((state: IState) => state.filter.uncCodes)
	const selectedUncCodes = useSelector((state: IState) => state.filter.commonProjects.resultFilterState.uncCellId)
	const { uncMains } = useSelector((state: IState) => state.public)
	const handleDeleteSelectedItem = (unc: IUncCell) => {
		dispatch(deleteUncCodeFromFilter(unc))
	}

	useEffect(() => {
		if (!uncCodes.length && selectedUncCodes) {
			const uncCodesArr = selectedUncCodes.toString().split(',')

			if (Array.isArray(uncCodesArr)) {
				uncCodesArr.forEach(id => dispatch(setUncCellsFullInfo(id as ReactText)))
			}
		}
	}, [selectedUncCodes])
	const getTableName = (code: string) => {
		const [uncCode] = code.split('-')
		return uncMains.find(unc => unc.code === uncCode)
	}

	useEffect(() => {
		return () => {
			dispatch(cleanUncCodeFromFilter())
		}
	}, [])

	return (
		<div className='switcher'>
			<div className='switcher_main'>
				<div className='switcher_main_header'>
					<SearchSelect uncCells={uncCells} refreshFilterValue={refreshFilterValue} />
				</div>
				<div className='switcher_content'>
					<button
						type='button'
						className='choose_button'
						onClick={() => {
							dispatch(setFilterUncsModalState(true))
						}}
					>
						Выбрать компонент
					</button>
				</div>
				<div className='selected_items'>
					<div className='selected_items_title'>Набор примененных элементов</div>
					<div className='selected_items_block'>
						{uncCodes.length ? (
							uncCodes?.map((unc: IUncCell) => {
								return unc?.id ? (
									<Tooltip
										key={unc?.id}
										title={
											<div>
												<Typography>
													<span className='selected_item__info_title'>Таблица: </span>
													<span>{getTableName(unc.code)?.name}</span>
												</Typography>
												<Typography>
													<span className='selected_item__info_title'>Номер расценок: </span>
													<span>{unc.code}</span>
												</Typography>
											</div>
										}
										placement='top'
										enterDelay={1000}
									>
										<Chip label={unc.code} onDelete={() => handleDeleteSelectedItem(unc)} />
									</Tooltip>
								) : (
									<Chip label={222} onDelete={() => handleDeleteSelectedItem(unc)} />
								)
							})
						) : (
							<div className='selected_items_empty'>нет примененных элементов</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FilterSwitcher
