import { all } from 'redux-saga/effects'
import publicSaga from './public'
import authSaga from './auth'
import userProjectsSaga from '../../modules/userProjects/store/saga'
import commonProjectsSaga from '../../modules/commonProjects/store/saga'
import watchPurchases from '@root-gipro/modules/purchases/store/saga'
import watchFilter from '@root-gipro/modules/filter/store/saga'
import { watchTariffs,watchPaymentCreate } from '@root-gipro/modules/tariffs/store/saga'

import watchFavorites from '@root-gipro/modules/favorites/store/sagas'
import watchInvestmentPrograms from '@root-gipro/modules/investmentPrograms/store/saga'
import watchDevelopmentSchemesAndPrograms from '@root-gipro/modules/developmentSchemesAndPrograms/store/saga'
import watchAdminInvestmentPrograms from '@root-gipro/modules/admin/investmentPrograms/store/saga'
import watchAdminDevelopmentSchemesAndPrograms from '@root-gipro/modules/admin/developmentSchemesAndPrograms/store/saga'
import watchMedia from '@root-gipro/modules/media/store/saga'

export default function* rootSaga() {
	yield all([
		watchDevelopmentSchemesAndPrograms(),
		watchInvestmentPrograms(),
		authSaga(),
		publicSaga(),
		userProjectsSaga(),
		commonProjectsSaga(),
		watchPurchases(),
		watchFilter(),
		watchTariffs(),
		watchPaymentCreate(),
		watchFavorites(),
		watchAdminInvestmentPrograms(),
		watchAdminDevelopmentSchemesAndPrograms(),
		watchMedia()
	])
}
