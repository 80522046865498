import React, { useState, useRef } from 'react'
import { ModalContext } from './modal-context'

const ModalProvider: React.FC = props => {
	const [accessory, setAccessory] = useState<string | undefined>()
	const [open, setOpen] = useState(false)
	const [elem, setElem] = useState<HTMLElement | null>()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const accessoryRef = useRef()

	return (
		<ModalContext.Provider
			value={{
				accessory,
				anchorEl,
				setOpen,
				setAnchorEl,
				setAccessory,
				accessoryRef,
				open,
				elem,
				setElem,
			}}
		>
			{props.children}
		</ModalContext.Provider>
	)
}
export { ModalProvider }
