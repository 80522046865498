import React, { useContext } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { listTKP, listSubscription } from '@root-gipro/modules/commonProjects/models/const/cost-works-form-lists.const'
import { ModalContext } from '@root-gipro/context/modal-context'
import { SubscriptionForm } from '@root-gipro/modules/commonProjects/components/CostWorks/SubscriptionForm'
import { filterTitle } from '@root-gipro/modules/commonProjects/utils/filterTitle'
import { filterWidthWindow } from '@root-gipro/modules/commonProjects/utils/filterWidthWindow'
import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

export const CostWorksModalContainer = () => {
	const { accessory, setAnchorEl, anchorEl, setAccessory, accessoryRef } = useContext(ModalContext)

	const filterButtonOptions = (step: string) => {
		switch (step) {
			case StepType.BUILD_WORK:
				return {
					PrimaryBtn: {
						handler: () => {
							setAccessory!(StepType.RECEIVE_BUILD_WORK)
							if (accessoryRef) accessoryRef.current = accessory
						},
						value: 'Получить',
						classes: 'primary-btn',
					},
					ExtraButtonBtn: {
						handler: () => {
							setAccessory!(StepType.TKP_BUILD_WORK)
							if (accessoryRef) accessoryRef.current = accessory
						},
						value: 'ТКП',
						classes: 'third-btn',
					},
					SecondaryBtn: {
						handler: () => setAnchorEl!(null!),
						value: 'Отмена',
						classes: 'secondary-btn',
					},
				}

			case StepType.DESIGN_WORK:
				return {
					PrimaryBtn: {
						handler: () => {
							setAccessory!(StepType.RECEIVE_DESIGN_WORK)
							if (accessoryRef) accessoryRef.current = accessory
						},
						value: 'Получить',
						classes: 'primary-btn',
					},
					ExtraButtonBtn: {
						handler: () => {
							setAccessory!(StepType.TKP_DESIGN_WORK)
							if (accessoryRef) accessoryRef.current = accessory
						},
						value: 'ТКП',
						classes: 'third-btn',
					},
					SecondaryBtn: {
						handler: () => setAnchorEl!(null!),
						value: 'Отмена',
						classes: 'secondary-btn',
					},
				}

			default:
				break
		}
	}

	return (
		<Modal
			buttonOptions={accessory ? filterButtonOptions(accessory) : null}
			setAnchorEl={setAnchorEl!}
			anchorEl={anchorEl}
			title={filterTitle(accessory)}
			widthWindow={filterWidthWindow(accessory)}
			keyBtn={accessory}
		>
			{accessory === StepType.BUILD_WORK && (
				<div>
					Данное предложение не является офертой, для получения более подробной информации, Вы можете связаться с нашими
					экспертами
				</div>
			)}
			{accessory === StepType.DESIGN_WORK && (
				<div>
					Данное предложение не является офертой, для получения более подробной информации, Вы можете связаться с нашими
					экспертами
				</div>
			)}
			{(accessory === StepType.TKP_DESIGN_WORK || accessory === StepType.TKP_BUILD_WORK) && (
				<SubscriptionForm
					list={listTKP}
					handlerStepBack={() => {
						const current: string = accessoryRef?.current || ''
						setAccessory!(current)
					}}
					accessory={accessory}
				/>
			)}
			{(accessory === StepType.RECEIVE_DESIGN_WORK || accessory === StepType.RECEIVE_BUILD_WORK) && (
				<SubscriptionForm
					list={listSubscription}
					handlerStepBack={() => {
						const current: string = accessoryRef?.current || ''
						setAccessory!(current)
					}}
					accessory={accessory}
				/>
			)}
		</Modal>
	)
}
