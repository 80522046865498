import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'

import { history } from '@root-gipro/router'
import rootReducer from './reducers/rootReducer'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))

export const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
