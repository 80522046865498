import React from 'react'
import { ICardDetailedInfo } from '@root-gipro/shared/Card/CardDetailedInfo/card-detailed-info.props'
const CardDetailedInfo: React.FC<ICardDetailedInfo> = ({ fullBlock, children }) => (
	<div
		className={`card-container__detailed-info ${
			fullBlock ? 'purchases-detailed-width' : 'common-projects-detailed-width'
		}`}
	>
		{children}
	</div>
)

export default CardDetailedInfo
