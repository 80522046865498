import { Tooltip } from '@material-ui/core'
import {
	ICommonProject,
	Notices,
	Plans,
} from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.model'
import React from 'react'
import { Link } from 'react-router-dom'
import { History } from 'history'

export interface IBodyParamsPurchases {
	textAlign: string
	value: (project: Notices | Plans | ICommonProject, index: number) => JSX.Element
}
export const bodyParams = (history: History) => [
	{
		value: (project: Notices | Plans | ICommonProject, index: number): JSX.Element =>
			(<span>{index + 1}.</span>) as JSX.Element,
		textAlign: 'textAlignLeft',
	},
	{
		value: (project: Notices | Plans | ICommonProject, index: number): JSX.Element =>
			(
				<Link
					to={{
						pathname: `/common-projects/project/${project.id}/parameters`,
						state: {
							from: history.location.pathname,
							fromName: 'Инвестиционные проекты',
							previousState: {
								isOpenPopover: true,
							},
						},
					}}
					className='project__code link link-blue'
				>
					{(project as ICommonProject).code}
				</Link>
			) as JSX.Element,
		textAlign: 'textAlignLeft',
	},
	{
		value: (project: ICommonProject, index: number): JSX.Element =>
			(
				<Tooltip title={project.name} placement='top'>
					<span>{project.name}</span>
				</Tooltip>
			) as JSX.Element,
		textAlign: 'textAlignLeft',
	},
]
