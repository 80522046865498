export const gridTemplateColumns = '32px 156px 516px'

export const theadData = [
	{
		title: '№',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Наименование',
		textAlign: 'textAlignLeft',
	},
]
