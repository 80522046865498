export const toggleFilter = (isFilterOpen: boolean) => {
	const $header: HTMLElement | null = document.querySelector('[data-header-animate]')
	const $wrapper: HTMLElement | null = document.querySelector('[data-wrapper-animate]')
	const wrapperWidth = $wrapper!.getBoundingClientRect().width
	const $filter = document.querySelector('[data-filter-animate]')
	const filterWidth = $filter!.getBoundingClientRect().width
	if (isFilterOpen) {
		const sum = filterWidth + wrapperWidth
		$wrapper!.style.minWidth = `${wrapperWidth}px`
		const div = createElement('div', 'header-substrate')
		div.style.width = `${sum}px`
		$header!.appendChild(div)
		// $filter?.classList.add('mr')
	} else {
		removeElement($header, 'header-substrate')
		$wrapper?.removeAttribute('style')
		// $filter?.classList.remove('mr')
	}
}
const createElement = (elem: string, className: string) => {
	const $elem = document.createElement(elem)
	$elem.classList.add(className)
	return $elem
}
const removeElement = ($elemContainer: any, waste: string) => {
	if ($elemContainer.children.length > 0) {
		;[...$elemContainer.children].map((elem: any) => {
			if (elem.classList.contains(waste)) elem.remove()
		})
	}
}
