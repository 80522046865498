import React, { FC, MouseEventHandler } from 'react'
import { Modal as ModalComponents, Backdrop, CircularProgress } from '@material-ui/core/'
import { PrimaryBtn, SecondaryBtn, ExtraButtonBtn } from '@root-gipro/shared/Button/Button'
import style from '@root-gipro/shared/Modal/Modal.module.scss'
import cn from 'classnames'
import { CrossSVG } from '@root-gipro/shared/SVGComponents/CrossSVG'

type Title = {
	classes?: string
	value?: string
	action?: (() => void) | undefined
	icon?: boolean
}

type Buttons = {
	[x: string]: {
		value: string
		classes?: string
		disabled?: boolean
		fullWidth?: boolean
		handler: () => void
	}
}

interface ModalProps {
	title?: Title[] | null
	widthWindow?: string
	buttonOptions?: Buttons | null
	disabledButton?: boolean
	keyBtn?: string
	anchorEl: (EventTarget & HTMLElement) | null | string | undefined | boolean
	setAnchorEl(event: HTMLElement): void
	classes?: string
	loader?: boolean
}

export const Modal: FC<ModalProps> = ({
	buttonOptions,
	children,
	title,
	widthWindow = '240px',
	disabledButton,
	keyBtn = 'btn',
	anchorEl,
	setAnchorEl,
	classes,
	loader,
}) => {
	return (
		<ModalComponents
			open={!!anchorEl}
			onClose={() => setAnchorEl(null!)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<div className={cn(style.modal, classes)} style={{ width: widthWindow }}>
				<div className={style.container}>
					{title && (
						<div className={style.blockTitle}>
							{title.map((item: Title) => (
								<div
									key={item.classes}
									className={cn({
										[style.rightTitleGray]: item.classes === 'rightTitleGray',
										[style.rightTitleBlue]: item.classes === 'rightTitleBlue',
										[style.leftTitle]: item.classes === 'leftTitle',
									})}
									onClick={() => item.action && item.action()}
								>
									{!item.icon && item.value && item.value}
									{item.icon && !item.value && <CrossSVG className={style.svg} />}
								</div>
							))}
						</div>
					)}
					<div className={style.body}> {children}</div>
					<div
						className={cn(style.flex, {
							[style.justifyCenter]: loader,
							[style.spaceBetween]: !loader,
						})}
					>
						{loader ? (
							<CircularProgress color='inherit' />
						) : (
							<>
								{buttonOptions?.PrimaryBtn && (
									<PrimaryBtn
										fullWidth={buttonOptions?.PrimaryBtn.fullWidth}
										key={keyBtn + '1'}
										disabled={disabledButton || buttonOptions.PrimaryBtn.disabled}
										onClick={buttonOptions?.PrimaryBtn.handler && buttonOptions?.PrimaryBtn.handler}
										className={buttonOptions?.PrimaryBtn.classes}
									>
										{buttonOptions?.PrimaryBtn.value}
									</PrimaryBtn>
								)}
								{buttonOptions?.ExtraButtonBtn && (
									<ExtraButtonBtn
										fullWidth={buttonOptions?.ExtraButtonBtn.fullWidth}
										key={keyBtn + '2'}
										disabled={disabledButton || buttonOptions.ExtraButtonBtn.disabled}
										onClick={buttonOptions?.ExtraButtonBtn.handler && buttonOptions?.ExtraButtonBtn.handler}
										className={buttonOptions?.ExtraButtonBtn.classes}
									>
										{buttonOptions?.ExtraButtonBtn.value}
									</ExtraButtonBtn>
								)}
								{buttonOptions?.SecondaryBtn && (
									<SecondaryBtn
										fullWidth={buttonOptions?.SecondaryBtn.fullWidth}
										key={keyBtn + '3'}
										disabled={disabledButton || buttonOptions.SecondaryBtn.disabled}
										onClick={buttonOptions?.SecondaryBtn.handler && buttonOptions?.SecondaryBtn.handler}
										className={buttonOptions?.SecondaryBtn.classes}
									>
										{buttonOptions?.SecondaryBtn.value}
									</SecondaryBtn>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</ModalComponents>
	)
}
