import produce, { Draft } from 'immer'
import {
	UserProjectsType,
	UserProjectActionsTypes,
} from '@root-gipro/modules/userProjects/models/interfaces/user-project.actions'
import {
	IUserProjectState,
	IUserProject,
	IUnc,
} from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import sortList from '@root-gipro/modules/commonProjects/models/const/sort-list.const'

const clearProject: IUserProject = {
	code: '',
	name: '',
	sumUncCost: null,
	sumUncCostWithNds: null,
	totalInvestmentUncOffer: null,
	yearBegin: null,
	yearEndOffer: null,
	actualFunding: null,
	valuationOfferForecast: null,
	additionalFundingOffer: null,
	offerRationale: '',
	years: {},
	regionIds: [],
	nds: null,
	t7Id: '',
}

const initialState: IUserProjectState = {
	projectList: [],
	totalInvestmentUncOfferSum: 0,
	userProjectsCnt: 0,
	originalObj: clearProject,
	projectObj: clearProject,
	isNew: false,
	activeUncs: [],
	uncs: [],
	targetVoltageValues: {},
	targetVoltageId: null,
	uncTableData: null,
	selectedElem: null,
	uncAddBuffer: [],
	uncFilter: '',
	projectFilter: '',
	interpolation: {},
	projectErrors: null,
	sectionIds: [
		{ id: '1', name: 'Подготовка территории' },
		{ id: '2', name: 'ПИР' },
		{ id: '3', name: 'Задвоения с ячейками' },
		{ id: '4', name: 'Задвоения прочие' },
		{ id: '5', name: 'ВЛ' },
		{ id: '6', name: 'КЛ' },
	],
	loading: false,
	uncEditMode: false,
	projectsErrors: false,
	sort: {
		name: sortList[0].name,
		sortName: sortList[0].sortName,
		order: true,
	},
}

const userProjects = (draft: Draft<IUserProjectState>, action: UserProjectActionsTypes) => {
	switch (action.type) {
		case UserProjectsType.FETCH_REQUEST:
			draft.loading = true
			break

		case UserProjectsType.SET_USER_PROJECTS:
			draft.projectList = action.projects
			draft.totalInvestmentUncOfferSum = action.totalInvestmentUncOfferSum
			draft.userProjectsCnt = action.userProjectsCnt
			break

		case UserProjectsType.CHOOSE_PROJECT:
			draft.projectObj = action.project
			break

		case UserProjectsType.SET_ORIGINAL_OBJ:
			draft.originalObj = action.project
			break

		case UserProjectsType.SET_IS_NEW_PROJECT:
			draft.isNew = action.isNew
			break

		case UserProjectsType.SET_ACTIVE_UNCS:
			draft.activeUncs = action.uncs
			break
		case UserProjectsType.SET_PROJECT_UNCS:
			draft.loading = false
			draft.uncs = action.uncs
			// draft.activeUncs = action.uncs
			break
		case UserProjectsType.SET_UNC_EDIT_MODE:
			draft.uncEditMode = action.mode
			break
		case UserProjectsType.CREATE_PROJECT_TEMPLATE:
			draft.projectObj = clearProject
			draft.originalObj = clearProject
			break
		case UserProjectsType.SHOW_PROJECT_ERRORS:
			draft.projectsErrors = action.show
			break
		case UserProjectsType.SET_PROJECT_NAME:
			draft.projectObj.name = action.name
			break

		case UserProjectsType.SET_PROJECT_СODE:
			draft.projectObj.code = action.code
			break
		case UserProjectsType.SET_PROJECT_YEARS:
			draft.projectObj.years = action.years
			break

		case UserProjectsType.SET_YEARS:
			draft.projectObj.yearBegin = action.begin
			draft.projectObj.yearEndOffer = action.end
			break

		case UserProjectsType.SET_REGION_IDS:
			draft.projectObj.regionIds = action.regionIds
			draft.originalObj.regionIds = action.regionIds
			break

		case UserProjectsType.SET_VALUATION_OFFER_FORECAST:
			draft.projectObj.valuationOfferForecast = action.valuation
			break
		case UserProjectsType.SET_ADDITIONAL_FUNDING_OFFER:
			draft.projectObj.additionalFundingOffer = action.offer
			break
		case UserProjectsType.SET_OFFER_RATIONALE:
			draft.projectObj.offerRationale = action.offerRationale
			break
		case UserProjectsType.SET_ACTUAL_FUNDING:
			draft.projectObj.actualFunding = action.actualFunding
			break
		case UserProjectsType.SET_NDS:
			draft.projectObj.nds = action.nds
			break
		case UserProjectsType.SET_UNC_TABLE_DATA:
			draft.uncTableData = action.uncTableData
			break
		case UserProjectsType.SELECT_TABLE_ELEM:
			draft.selectedElem = action.selectedElem
			break
		case UserProjectsType.SET_UNC_ADD_BUFFER:
			draft.loading = false
			draft.uncAddBuffer = action.buffer
			break
		case UserProjectsType.SET_UNC_FILTER:
			draft.uncFilter = action.filter
			break
		case UserProjectsType.SET_INTERPOLATION:
			draft.interpolation = action.obj
			break
		case UserProjectsType.SET_PROJECT_FILTER:
			draft.projectFilter = action.filter
			break
		case UserProjectsType.SET_PROJECT_ERRORS:
			draft.projectErrors = action.errors
			break
		case UserProjectsType.SET_VOLTAGE:
			const indUncAddBuffer = draft.uncAddBuffer.findIndex(item => {
				if (item.id === action.uncId) return item
			})
			const indUncs = draft.uncs.findIndex(item => item.id === action.uncId)
			const newArrUncAddBuffer = draft.uncAddBuffer
			const newArrUncs = draft.uncs
			if (indUncAddBuffer >= 0) {
				newArrUncAddBuffer[indUncAddBuffer]['targetVoltageValues'] = action.targetVoltageValues
			}
			if (indUncs >= 0) {
				newArrUncs[indUncs]['targetVoltageValues'] = action.targetVoltageValues
			}
			draft.targetVoltageValues = action.targetVoltageValues // возможно нужно удаалить
			draft.uncAddBuffer = [...newArrUncAddBuffer]
			draft.uncs = [...newArrUncs]
			break
		case UserProjectsType.SET_VOLTAGE_ID:
			const indUncAddBuffer_ID = draft.uncAddBuffer.findIndex(item => item.id === action.uncId)
			const indUncs_ID = draft.uncs.findIndex(item => item.id === action.uncId)

			const newArrUncAddBuffer_ID = draft.uncAddBuffer
			const newArrUncs_ID: IUnc[] = draft.uncs
			if (indUncAddBuffer_ID >= 0) {
				newArrUncAddBuffer_ID[indUncAddBuffer_ID]['targetVoltageId'] = action.targetVoltageId
			}
			if (indUncs_ID >= 0) {
				newArrUncs_ID[indUncs_ID]['targetVoltageId'] = action.targetVoltageId
			}
			draft.targetVoltageId = action.targetVoltageId // возможно нужно удаалить
			draft.uncAddBuffer = [...newArrUncAddBuffer_ID]
			draft.uncs = [...newArrUncs_ID]
			break

		case UserProjectsType.SORT_USER_PROJECTS:
			draft.sort.name = action.name
			draft.sort.sortName = action.sortName
			draft.sort.order = action.order
			break
		default:
			return draft
	}
}

export const userProjectsReducer = produce(userProjects, initialState)
