import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminInput from '@root-gipro/modules/admin/partials/AdminInput'
import {
	createInvestmentProgram,
	setInvestmentProgramCost,
	setInvestmentProgramFinishedAt,
	setInvestmentProgramPublishedAt,
	setInvestmentProgramQuantity,
	setInvestmentProgramResource,
	setInvestmentProgramShortTitle,
	setInvestmentProgramSince,
	setInvestmentProgramStartedAt,
	setInvestmentProgramTitle,
	setInvestmentProgramIndustry,
} from '@root-gipro/modules/admin/investmentPrograms/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import { prepareValidationErrors } from '@root-gipro/utils/helpers/handlerErrors'
import { uploadIprPdf } from '@root-gipro/modules/media/store/actions'
import { Button } from '@material-ui/core'

const Create = () => {
	const dispatch = useDispatch()
	const program = useSelector((state: IState) => state.adminInvestmentPrograms.program)
	const errors = useSelector((state: IState) => state.adminInvestmentPrograms.errors)
	const mediaIsLoading = useSelector((state: IState) => state.adminInvestmentPrograms.mediaIsLoading)
	const [mediaName, setMediaName] = useState('файл не выбран')

	const emptyErrors = {
		title: undefined,
		shortTitle: undefined,
		cost: undefined,
		since: undefined,
		startedAt: undefined,
		finishedAt: undefined,
		quantity: undefined,
		publishedAt: undefined,
		resource: undefined,
		industry: undefined,
	}
	const [preparedErrors, setPreparedErrors] = useState(emptyErrors)

	const onFileUpload = (e: React.FormEvent<HTMLInputElement>) => {
		const formData = new FormData()
		// @ts-ignore
		formData.append('media', e.currentTarget.files[0], e.currentTarget.files[0].name)
		// @ts-ignore
		const media = formData.get('media')
		// @ts-ignore
		const mediaName = media.name
		setMediaName(mediaName)
		formData.append('entity', 'ipr')
		dispatch(uploadIprPdf(formData))
	}

	const onSubmit = (e: React.FormEvent<HTMLElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setPreparedErrors(emptyErrors)
		dispatch(createInvestmentProgram(program))
	}

	useEffect(() => {
		setPreparedErrors(prepareValidationErrors(errors))
	}, [errors])

	return (
		<form className='admin-container' onSubmit={onSubmit}>
			<div className='admin-form__header'>
				<h3>Создание ИПР</h3>

				<div className='admin-form__header-buttons'>
					<button type='submit' className='admin-button primary'>
						Сохранить
					</button>
				</div>
			</div>

			<div>
				<fieldset className='admin-fieldset admin-fieldset--cols-3'>
					<AdminInput
						label='Полное наименование'
						placeholder='Акционерное общество «Альфа Банк»'
						error={preparedErrors?.title}
						value={program.title}
						onInput={setInvestmentProgramTitle}
					/>
					<AdminInput
						label='Краткое наименование'
						placeholder='АО «Альфа Банк»'
						error={preparedErrors?.shortTitle}
						value={program.shortTitle}
						onInput={setInvestmentProgramShortTitle}
					/>

					<AdminInput
						label='Оценка полной стоимости в прогн. ценах (с НДС)'
						placeholder='1498235.35'
						inputType='number'
						error={preparedErrors?.cost}
						postfix='млн.р'
						value={program.cost}
						onInput={setInvestmentProgramCost}
					/>
				</fieldset>

				<fieldset className='admin-fieldset admin-fieldset--cols-3'>
					<AdminInput
						label='Год утверждения инвестиционной программы'
						placeholder='2020'
						inputType='number'
						error={preparedErrors?.since}
						value={program.since}
						onInput={setInvestmentProgramSince}
					/>
					<AdminInput
						label='Год начала реализации программы'
						placeholder='2021'
						inputType='number'
						error={preparedErrors?.startedAt}
						value={program.startedAt}
						onInput={setInvestmentProgramStartedAt}
					/>
					<AdminInput
						label='Год завершения реализации программы'
						placeholder='2022'
						inputType='number'
						error={preparedErrors?.finishedAt}
						value={program.finishedAt}
						onInput={setInvestmentProgramFinishedAt}
					/>
				</fieldset>

				<fieldset className='admin-fieldset admin-fieldset--cols-2'>
					<AdminInput
						label='Отрасль'
						placeholder='Банк'
						error={preparedErrors?.industry}
						value={program.industry}
						onInput={setInvestmentProgramIndustry}
					/>
					<AdminInput
						label='Открытый источник информации'
						placeholder='https://minenergo.gov.ru/node/4174'
						error={preparedErrors?.resource}
						value={program.resource}
						onInput={setInvestmentProgramResource}
					/>
				</fieldset>

				<fieldset className='admin-fieldset admin-fieldset--cols-2'>
					<AdminInput
						label='Количество инвестиционных проектов'
						placeholder='250'
						inputType='number'
						error={preparedErrors?.quantity}
						value={program.quantity}
						onInput={setInvestmentProgramQuantity}
					/>
					<AdminInput
						label='Публикация в открытых источниках'
						inputType='date'
						error={preparedErrors?.publishedAt}
						value={program.publishedAt}
						onInput={setInvestmentProgramPublishedAt}
					/>
				</fieldset>

				<fieldset className='admin-fieldset admin-fieldset--cols-3'>
					<label className='admin-label'>
						<p>Файл (zip, максимум 50 Мб)</p>
						<div className='admin-upload-wrap'>
							<Button
								variant='outlined'
								color='primary'
								component='label'
								fullWidth={true}
								disabled={mediaIsLoading}
								className='admin-input admin-upload-btn'
							>
								{!mediaIsLoading ? (
									'Загрузить'
								) : (
									<img className='btn-spinner' src={'/assets/images/gif/loading.gif'} alt='loading' />
								)}
								<input
									onChange={onFileUpload}
									className='admin-input'
									disabled={mediaIsLoading}
									hidden
									accept='file/*'
									type='file'
								/>
							</Button>
							<span className='admin-upload-filename'>{mediaName}</span>
						</div>
					</label>
				</fieldset>
			</div>
		</form>
	)
}

export default React.memo(Create)
