import React from 'react'
import { Tab, TabsGroup } from '@root-gipro/shared/TabsComponents/CustomTabs'
import { ITabsToggle } from '@root-gipro/shared/TabsComponents/tabs.props'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import cn from 'classnames'
import { TypesToggle } from '@root-gipro/shared/TabsComponents/tabs.props'

import { Link } from 'react-router-dom'

export const TabsComponents: React.FC<ITabsToggle> = ({ types, editToggleType, toggleType }) => {
	return (
		<TabsGroup value={toggleType}>
			{types.map((type: TypesToggle) => (
				<Tab
					key={type.title}
					className={cn({
						[style.active]: toggleType === type.value,
					})}
					onClick={() => editToggleType(type.value)}
					label={type.title}
					value={toggleType}
					disableRipple
					//@ts-ignore
					to={type.value === 'PROJECTS' ? '/favorites-common-projects' : '/favorites-purchases'}
					component={Link}
				/>
			))}
		</TabsGroup>
	)
}
export const TabsToggle = React.memo(TabsComponents)
