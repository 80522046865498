import React, { memo, FC } from 'react'
import { Collapse } from '@material-ui/core'
import { ISectionIds } from '@root-gipro/store/interfaces'

interface ErrorsListProps {
	projectErrors: {
		[key: string]: string[]
	} | null
	sectionIds: ISectionIds[]
	handleClick: (panel: number) => void
	indexPanel: number | undefined
	isExpand: boolean
}

const ErrorsList: FC<ErrorsListProps> = memo(({ projectErrors, sectionIds, handleClick, indexPanel, isExpand }) => {
	return (
		<div className='errors-list'>
			{projectErrors ? (
				Object.keys(projectErrors).map((name: string, index: number) => {
					return (
						<div key={index} className='errors-item'>
							<div onClick={() => handleClick(index)} className='errors-item__title'>
								<span className={`dropdown-icon-bold mr-8 ${indexPanel === index && isExpand ? 'open' : ''}`}></span>
								{sectionIds[parseInt(name) - 1].name}
								{projectErrors[name].length > 0 && (
									<span className='count-error ml-8'> {projectErrors[name].length} ошибка</span>
								)}
							</div>
							<Collapse in={indexPanel === index && isExpand} unmountOnExit>
								{projectErrors[name].length > 0 ? (
									projectErrors[name].map((textErr: string, i: number) => (
										<div key={i + textErr} className='errors-item__desc'>
											<span className='error-icon'></span>
											<span className='errors-item__error-shrt-txt'>{textErr}</span>
										</div>
									))
								) : (
									<div key={'errors-item'} className='errors-item__success'>
										<span className='success-icon'></span>
										<span className='errors-item__success-shrt-txt'>Ошибок не обнаружено!</span>
									</div>
								)}
							</Collapse>
						</div>
					)
				})
			) : (
				<>
					{sectionIds.map((item: ISectionIds) => (
						<div key={item.id} className='errors-item'>
							<div className='errors-item__title'>{item.name}</div>
							<div className='errors-item__success'>
								<span className='success-icon'></span>
								<span className='errors-item__success-shrt-txt'>Ошибок не обнаружено!</span>
							</div>
						</div>
					))}
				</>
			)}
			{/* <div className='errors__wrapper__btn'>
				<PrimaryBtn onClick={handlerClick} variant='contained'>
					Готово
				</PrimaryBtn>
			</div> */}
		</div>
	)
})
export { ErrorsList }
