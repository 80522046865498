import React, { FC, useEffect, useMemo, useState } from 'react'
import './configureFilterStyles.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { useDispatch } from 'react-redux'
import { setFilterConfigureModalState, setFilterConfigures } from '../store/actions'
import { IUncTableData } from '@root-gipro/store/interfaces'
import ConfigureSelect from './ConfigureSelect'

interface Props {
	uncTableData: IUncTableData | null
	setSelectedCells: React.Dispatch<React.SetStateAction<{ x: number; y: number }[]>>
	selectedElemCode: any
}

const ConfigureFilter: FC<Props> = ({ uncTableData, setSelectedCells, selectedElemCode }) => {
	const [configValues, setConfigValues] = useState<any>()
	const [configs, setConfigs] = useState<any>([])

	const [bodyData, setBodyData] = useState<any>()
	const [headerData, setHeaderData] = useState<any>()
	const [isHardTable, setIsHardTable] = useState(false)

	const dispatch = useDispatch()
	const handleCloseConfigure = () => {
		dispatch(setFilterConfigureModalState(false))
	}

	const setConfig = () => {
		if (filteredBodyData && Array.isArray(filteredBodyData)) {
			const res = filteredBodyData
			if (isHardTable) {
				const idCell = configValues[0]
				const result = res[0].filter((item: any) => {
					if (item.uncCellId) {
						if (item.x === idCell.z) {
							return item
						}
					} else {
						return item
					}
				})

				setSelectedCells([...result, { x: idCell.z, y: res[0][0].y, id: result[result.length - 1]?.uncCellId }])
				dispatch(setFilterConfigures({ id: result[result.length - 1]?.uncCellId, x: result[0]?.x }))
			} else {
				const resPosition = res[0][res[0]?.length - 1]
				setSelectedCells([...res[0], { x: resPosition?.x, y: resPosition?.y, id: resPosition?.uncCellId }])
				dispatch(setFilterConfigures({ id: resPosition?.uncCellId, x: resPosition?.x }))
			}
			dispatch(setFilterConfigureModalState(false))
		}
	}
	const getCorrectBodyData = (lastElements: any) => {
		if (Array.isArray(uncTableData?.body)) {
			if (lastElements) {
				const arrBody = [...(uncTableData?.body as any)]
				const index = lastElements.length
				const corrLast = lastElements.map((item: any) => {
					if(!item.class){
						if(selectedElemCode=== "Р4"){
							const newItem = { ...item, z: 3 }
						return newItem
						}
						const newItem = { ...item, z: item.x }
						return newItem
					}
				}).filter((it:any)=>it)
				corrLast.map((item: any) => (item.x = corrLast[0].z))

				return [
					...arrBody.slice(0, index),
					...corrLast.map((item: any) => {
						item.x = corrLast[0].z
						return [item]
					}),
					...arrBody.slice(index),
				]
			}
		}
	}
	useEffect(() => {
		if (uncTableData && Array.isArray(uncTableData?.header[0])) {
			if (uncTableData?.header.length > 1) {
				setIsHardTable(true)
				const data = [...(uncTableData?.header[0] as any)]
				// Удаляю элементы с объектами, содержащими наименование "Норматив цены"
				const filteredData = data.filter(item => {
					if (selectedElemCode && selectedElemCode == 'Р4') {
						if (item?.name.includes('Напряжение') || item?.name.includes('Мощность')) {
							return true
						} else {
							return false
						}
					}
					if (selectedElemCode && selectedElemCode == 'Р2') {
						if (item?.name.includes('Напряжение') || item?.name.includes('Номинальный ток')) {
							return true
						} else {
							return false
						}
					}
					if (selectedElemCode && selectedElemCode == 'Л4') {
						if (item?.name.includes('Напряжение') || item?.name == 'Тип опор и количество цепей') {
							return true
						} else {
							return false
						}
					}
					if (selectedElemCode && selectedElemCode == 'Б2') {
						if (
							item?.name.includes('Напряжение') ||
							item?.name.includes('Субъект') ||
							item?.name.includes('Количество прокладываемых цепей КЛ')
						) {
							return true
						} else {
							return false
						}
					}
					if (selectedElemCode && selectedElemCode == 'П9') {
						if (
							item?.name.includes('Напряжение') ||
							item?.name.includes('объект') ||
							item?.name.includes('Линейный') ||
							item?.name.includes('Протяженность')
						) {
							return true
						} else {
							return false
						}
					}
					if (
						item?.name &&
						item?.class !== 'invis' &&
						!item?.name.includes('Номер') &&
						item?.name !== 'Стоимость работ' &&
						item?.name !== 'Порядковый номер'
					) {
						return !item.name.includes('Норматив цены')
					}
				})

				if (selectedElemCode && selectedElemCode == 'Б2') {
					const [lastheader, lastElements] = uncTableData?.header.slice(-3).map((item: any) => Object.values(item))
					const lastTwoElements = lastheader.filter((item: any) => item.class === undefined)

					// Объединяю последние два элемента в один массив
					const mergedArray = filteredData.concat(...lastTwoElements)
					
					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElements))
				} 
				else if (selectedElemCode && selectedElemCode == 'Л4') {
					const [lastheader, lastElements] = uncTableData?.header.slice(-2).map((item: any) => Object.values(item))
					const lastTwoElements:any  = lastheader.filter((item: any) => item.class === undefined)
					// Объединяю последние два элемента в один массив
					const mergedArray = filteredData.concat(lastTwoElements[0])
					
					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElements))
				} 

				else if (selectedElemCode && selectedElemCode == 'Р4') {
					const [lastheader, lastElementsNames, lastElements] = uncTableData?.header
						.slice(-3)
						.map((item: any) => Object.values(item))
					const lastTwoElements = lastheader?.filter((item: any) => {
						if (typeof item?.name == 'string') {
							if (item?.name.includes('Норматив цены')) {
								return true
							}
						} else return false
					})

					// Объединяю последние два элемента в один массив
					
					const mergedArray = filteredData.concat(...lastTwoElements)
					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElements))

				} else if (selectedElemCode && selectedElemCode == 'Р2') {
					const [lastheader, lastElements] = uncTableData?.header.slice(-4, -2).map((item: any) => Object.values(item))
					// console.log(uncTableData?.header,lastheader,lastElements)
					const lastTwoElements = lastheader.filter((item: any) => item.class === undefined)

					// Объединяю последние два элемента в один массив

					const mergedArray = filteredData.concat(...lastTwoElements)
					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElements))
				} 
				
				
				else if (selectedElemCode && selectedElemCode == 'К3') {
					const [lastElementsNames, lastheader, lastElements] = uncTableData?.header
						.slice(-3)
						.map((item: any) => Object.values(item))
					const lastTwoElements = lastheader.filter((item: any) => item.class === undefined)
					const lastElementsCorrect = lastElements.map((item: any, ind: number) => {
						// @ts-ignore
						return { ...item, name: ` ${item.name} ${lastElementsNames[ind]?.name}` }
					})
					// Объединяю последние два элемента в один массив
					const mergedArray = filteredData.concat(...lastTwoElements)
					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElementsCorrect))
				} else if (selectedElemCode && selectedElemCode == 'П9') {
					setIsHardTable(false)

					setHeaderData(
						[...(uncTableData.header[0] as any)].filter(item => {
							if (
								item?.name.includes('Напряжение') ||
								item?.name.includes('объект') ||
								item?.name.includes('Линейный') ||
								item?.name.includes('Протяженность')
							) {
								return true
							} else {
								return false
							}
						})
					)
					setBodyData([...(uncTableData?.body as any)])
				} else {
					const [lastheader, lastElements] = uncTableData?.header.slice(-2).map((item: any) => Object.values(item))
					const lastTwoElements = lastheader.filter((item: any) => item.class === undefined)

					// Объединяю последние два элемента в один массив
					const mergedArray = filteredData.concat(...lastTwoElements)

					setHeaderData(mergedArray)
					setBodyData(getCorrectBodyData(lastElements))
				}

				// Беру последние два элемента из отфильтрованного массива
			} else {
				setIsHardTable(false)

				setHeaderData(
					[...(uncTableData.header[0] as any)].filter(item => {
						if (selectedElemCode && selectedElemCode == 'Р4') {
							if (
								item?.name.includes('Напряжение') ||
								item?.name.includes('Мощность') ||
								item?.name == 'Норматив цены для отдельных элементов в составе расценки'
							) {
								return true
							} else {
								return false
							}
						}
						if (selectedElemCode && selectedElemCode == 'Л4') {
							if (item?.name.includes('Напряжение') || item?.name == 'Тип опор и количество цепей') {
								return true
							} else {
								return false
							}
						}
						if (selectedElemCode && selectedElemCode == 'Б2') {
							if (
								item?.name.includes('Напряжение') ||
								item?.name.includes('Субъект') ||
								item?.name.includes('Количество прокладываемых цепей КЛ')
							) {
								return true
							} else {
								return false
							}
						}
						if (
							item?.name &&
							item?.class !== 'invis' &&
							!item?.name.includes('Номер') &&
							item?.name !== 'Стоимость работ' &&
							item?.name !== 'Порядковый номер'
						) {
							return !item.name.includes('Норматив цены')
						}
					})
				)
				setBodyData([...(uncTableData?.body as any)])
			}
		}
	}, [])

	const filteredBodyData = useMemo(() => {
		if (!configs.length) return bodyData
		const res = bodyData?.filter((item: any) => {
			const configures = configs.map((item: any) => Object.values(item)[0])
			const results = configures.every((it: any) => !!item.find((itm: any) => itm.name == it))
			return results
			// }
		})
		return res
	}, [configValues, bodyData, configs])

	const checkDissabled = () => {
		if (configs?.length === headerData?.length || (configs?.length === headerData?.length - 1 && configValues)) {
			return false
		}
		return true
	}
	return (
		<>
			<div className='configure_zone'></div>
			<div className='configure'>
				<div className='content'>
					<div className='content_header'>Укажите значения</div>
					<div className='configure_block'>
						{headerData?.map((item: any, ind: number) => {
							 
							return (
								<ConfigureSelect
									bodyData={bodyData}
									setConfigValues={setConfigValues}
									headItem={selectedElemCode?.includes("Ж") && ind == 0?{...item,x:ind+1}:item}
									uncTableData={uncTableData}
									headPosition={ind}
									headLength={headerData.length - 1}
									filteredBodyData={headerData.length - 1 == ind && isHardTable ? bodyData : filteredBodyData}
									isHardTable={isHardTable}
									setConfigs={setConfigs}
									
								/>
							)
						})}
					</div>
					<div className='configure_footer'>
						<div className='configure_footer_buttons'>
							<PrimaryBtn variant='contained' className='fullWidth' disabled={checkDissabled()} onClick={setConfig}>
								Готово
							</PrimaryBtn>
							<SecondaryBtn variant='contained' onClick={handleCloseConfigure} className='fullWidth'>
								Закрыть
							</SecondaryBtn>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ConfigureFilter
