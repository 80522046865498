import { store } from '@root-gipro/store'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { Color } from '@material-ui/lab/Alert'

type Error = {
	message: string
	requestId: string
	status: string
	errors?: {
		regionIds?: { unc?: string }
		auth?: { forbiddenAccess?: string }
	}
}

export const prepareValidationErrors = (errors: any) => {
	const prepared: any = {}

	if (!!errors) {
		Object.keys(errors).forEach((key: string) => {
			prepared[key] = Object.values(errors[key]).pop()
		})
	}

	return prepared
}

export const handlerErrors = (data: Error) => {
	const { message, type }: { message: string; type: Color } = getError(data)
	store.dispatch(
		showNotify({
			type,
			message,
		})
	)
}

const getError = (data: Error) => {
	if (data && data.status === 'failure' && data.errors && data.errors.regionIds && data.errors.regionIds.unc) {
		return {
			message: `Ошибка при сохранении: невозможно убрать регион проекта, который присутствует в позициях УНЦ`,
			type: 'warning' as Color,
		}
	} else if (data.message === 'Данные не найдены!' && data.status === 'failure') {
		return {
			message: data.message,
			type: 'warning' as Color,
		}
	} else {
		return {
			message: JSON.stringify(data.message),
			type: 'error' as Color,
		}
	}
}
