import { call, put, takeEvery } from 'redux-saga/effects'
import {
    getInvestmentProgram,
    createInvestmentProgram,
    deleteInvestmentProgram,
    updateInvestmentProgram, fetchMedia
} from '@root-gipro/store/api'
import {
    setInvestmentProgram, setInvestmentProgramMedia, setInvestmentProgramValidationErrors
} from '@root-gipro/modules/admin/investmentPrograms/store/actions'
import {
    AdminInvestmentProgramsTypes
} from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-programs.actions'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { push } from 'react-router-redux'
import { IInvestmentProgram } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-program'
import {
    clearDevelopmentSchemesAndPrograms
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/store/actions'

function* fetchInvestmentProgram(
    { id }: { id: string, type: AdminInvestmentProgramsTypes.GET_INVESTMENT_PROGRAM }
) {
    try {
        const program = yield call(getInvestmentProgram, id)
        if (program.mediaId) {
            const media = yield call(fetchMedia, program.mediaId)
            yield put(setInvestmentProgramMedia(media))
        }
        yield put(setInvestmentProgram(program))
    } catch (e) {
        console.error(e)
    }
}

function* delInvestmentProgram(
    { id }: { id: string, type: AdminInvestmentProgramsTypes.DELETE_INVESTMENT_PROGRAM }
) {
    try {
        yield call(deleteInvestmentProgram, id)
        yield put(clearDevelopmentSchemesAndPrograms())
        yield put(push('/admin/ipr'))
        yield put(showNotify({
            type: 'success',
            message: 'ИПР успешно удалена!',
            duration: 5000
        }))
    } catch (e) {
        console.error(e)
    }
}

function* updInvestmentProgram(
    { id, program }: { id: string, program: IInvestmentProgram, type: AdminInvestmentProgramsTypes.UPDATE_INVESTMENT_PROGRAM }
) {
    try {
        const response = yield call(updateInvestmentProgram, id, program)

        if (response.errors) {
            yield put(setInvestmentProgramValidationErrors(response.errors))
        } else {
            yield put(showNotify({
                type: 'success',
                message: 'ИПР успешно обновлена!',
                duration: 5000
            }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* createIpr(
    { program }: { program: any, type: AdminInvestmentProgramsTypes.CREATE_INVESTMENT_PROGRAM }
) {
    try {
        const response = yield call(createInvestmentProgram, program)
        if (response.errors) {
            yield put(setInvestmentProgramValidationErrors(response.errors))
        } else {
            yield put(push(`/admin/ipr/${response.id}`))
            yield put(showNotify({
                type: 'success',
                message: 'ИПР успешно создана!',
                duration: 5000
            }))
        }
    } catch (e) {
        console.error(e)
    }
}

export default function* watchAdminInvestmentPrograms() {
    yield takeEvery(AdminInvestmentProgramsTypes.DELETE_INVESTMENT_PROGRAM, delInvestmentProgram)
    yield takeEvery(AdminInvestmentProgramsTypes.GET_INVESTMENT_PROGRAM, fetchInvestmentProgram)
    yield takeEvery(AdminInvestmentProgramsTypes.CREATE_INVESTMENT_PROGRAM, createIpr)
    yield takeEvery(AdminInvestmentProgramsTypes.UPDATE_INVESTMENT_PROGRAM, updInvestmentProgram)
}