import React, { FC } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useInput } from '@root-gipro/shared/hooks/useInput'
import { Input } from '@root-gipro/shared/FormElements'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import { useDispatch } from 'react-redux'
import { reportRequest } from '@root-gipro/modules/commonProjects/store/actions'
import styles from '@root-gipro/modules/commonProjects/styles/Reports.module.scss'
import { IVisitsLog } from '@root-gipro/modules/commonProjects/models/interfaces/reports.models'

const schema = yup.object().shape({
	email: yup
		.string()
		.matches(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+/, 'Некорректный  Email')
		.required('Поле должно быть заполненно'),
})

export const FormReports: FC = () => {
	const { value, onChange } = useInput()
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()

	const onSubmit = (data: IVisitsLog) => {
		dispatch(reportRequest(data))
	}
	return (
		<form noValidate onSubmit={handleSubmit(onSubmit)}>
			<div>
				<Input
					labelName={'Email'}
					inputName={'email'}
					inputValue={value && value['email']}
					handleInput={onChange}
					classes='full-width'
					inputRef={register}
					errors={!!errors['email']}
					helperText={errors && errors['email'] && errors['email'].message}
				/>
			</div>
			<div className={styles.mt}>
				<Input
					labelName={'С'}
					inputName={'from'}
					inputValue={value && value['from']}
					handleInput={onChange}
					inputRef={register}
					errors={!!errors['from']}
					helperText={errors && errors['from'] && errors['from'].message}
					mask={'99-99-9999'}
					classes='full-width'
				/>
			</div>
			<div className={styles.mt}>
				<Input
					labelName={'По'}
					inputName={'to'}
					inputValue={value && value['to']}
					handleInput={onChange}
					inputRef={register}
					errors={!!errors['to']}
					helperText={errors && errors['to'] && errors['to'].message}
					mask={'99-99-9999'}
					classes='full-width'
				/>
			</div>

			<div className={styles.mt}>
				<PrimaryBtn type='submit'>Выгрузить</PrimaryBtn>
			</div>
		</form>
	)
}
