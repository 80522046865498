import React, { useState, useEffect, ReactText } from 'react'
import { intFormat } from '@root-gipro/utils/helpers/common'
import { IInputIntProps } from '@root-gipro/modules/userProjects/models/interfaces/project.props'

const InputInt: React.FC<IInputIntProps> = ({ value, extraClass, changeFunc, placeholder }) => {
	const [result, setResult] = useState<ReactText>(value!)
	useEffect(() => {
		if (value) {
			const val = intFormat(value)

			setResult(val)
		}
	}, [value])

	return (
		<input
			onFocus={e => {
				let int = e.currentTarget.value
				const val = intFormat(int)
				setResult(val)
			}}
			onChange={e => {
				let int = e.currentTarget.value
				const val = intFormat(int)
				setResult(val)
			}}
			onBlur={e => {
				let int = e.currentTarget.value
				const val = intFormat(int)
				setResult(val)
				changeFunc(int.replace(/\s/g, ''))
			}}
			placeholder={placeholder}
			value={result}
			type='text'
			className='universal-input'
		/>
	)
}

export { InputInt }
