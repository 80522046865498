import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import AdminInput from '@root-gipro/modules/admin/partials/AdminInput'
import { useDispatch } from 'react-redux'
import {
    clearInvestmentProgram,
    deleteInvestmentProgram,
    getInvestmentProgram,
    setInvestmentProgramCost,
    setInvestmentProgramFinishedAt, setInvestmentProgramIndustry,
    setInvestmentProgramPublishedAt,
    setInvestmentProgramQuantity, setInvestmentProgramResource,
    setInvestmentProgramShortTitle,
    setInvestmentProgramSince,
    setInvestmentProgramStartedAt,
    setInvestmentProgramTitle, updateInvestmentProgram
} from '@root-gipro/modules/admin/investmentPrograms/store/actions'
import { useParams } from 'react-router'
import {
    IAdminInvestmentProgramsState
} from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-programs.model'
import { IState } from '@root-gipro/store/interfaces'
import { IInvestmentProgram } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-program'
import { prepareValidationErrors } from '@root-gipro/utils/helpers/handlerErrors'
import { uploadIprPdf } from '@root-gipro/modules/media/store/actions'

const Edit: React.FC<IInvestmentProgram> =  ({
  title,
  shortTitle,
  cost,
  since,
  startedAt,
  finishedAt,
  quantity,
  publishedAt,
  resource,
  industry
}) => {
    const { id } = useParams()

    const emptyErrors = {
        title: undefined,
        shortTitle: undefined,
        cost: undefined,
        since: undefined,
        startedAt: undefined,
        finishedAt: undefined,
        quantity: undefined,
        publishedAt: undefined,
        resource: undefined,
        industry: undefined
    }

    const program = useSelector((state: IState) => state.adminInvestmentPrograms.program)
    const errors = useSelector((state: IState) => state.adminInvestmentPrograms.errors)
    const media = useSelector((state: IState) => state.adminInvestmentPrograms.media)
    const [preparedErrors, setPreparedErrors] = useState(emptyErrors)
    const dispatch = useDispatch()

    const onSubmit = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setPreparedErrors(emptyErrors)
        dispatch(updateInvestmentProgram(program, id))
    }

    const onDelete = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(deleteInvestmentProgram(id))
    }

    const getMediaPath = (path: string) => {
        return `${process.env.REACT_APP_TRANSFER_PROTOCOL}://${process.env.REACT_APP_ENV}${path}`
    }

    const onFileUpload = (e: React.FormEvent<HTMLInputElement>) => {
        const formData = new FormData()
        // @ts-ignore
        formData.append('media', e.currentTarget.files[0], e.currentTarget.files[0].name)
        formData.append('entity', 'ipr')
        dispatch(uploadIprPdf(formData))
    }

    const clearState = () => {
        dispatch(clearInvestmentProgram())
    }

    useEffect(() => {
        dispatch(getInvestmentProgram(id))
        return () => clearState()
    }, [])

    useEffect(() => {
        setPreparedErrors(prepareValidationErrors(errors))
    }, [errors])

    return (
        <div className="admin-container">
            <div className="admin-form__header">
                <h3>Редактирование ИПР «{ shortTitle }»</h3>

                <div className="admin-form__header-buttons">
                    <button type="button" className="admin-button primary" onClick={onSubmit}>Сохранить</button>
                    <button type="button" className="admin-button danger" onClick={onDelete}>Удалить</button>
                </div>
            </div>

            <form>
                <fieldset className="admin-fieldset admin-fieldset--cols-3">
                    <AdminInput
                        label="Полное наименование"
                        placeholder="Акционерное общество «Альфа Банк»"
                        error={preparedErrors?.title}
                        value={title}
                        onInput={setInvestmentProgramTitle}
                    />
                    <AdminInput
                        label="Краткое наименование"
                        placeholder="АО «Альфа Банк»"
                        error={preparedErrors?.shortTitle}
                        value={shortTitle}
                        onInput={setInvestmentProgramShortTitle}
                    />

                    <AdminInput
                        label="Оценка полной стоимости в прогн. ценах (с НДС)"
                        placeholder="1498235.35"
                        inputType="number"
                        error={preparedErrors?.cost}
                        postfix="млн.р"
                        value={cost}
                        onInput={setInvestmentProgramCost}
                    />
                </fieldset>

                <fieldset className="admin-fieldset admin-fieldset--cols-3">
                    <AdminInput
                        label="Год утверждения инвестиционной программы"
                        placeholder="2020"
                        inputType="number"
                        error={preparedErrors?.since}
                        value={since}
                        onInput={setInvestmentProgramSince}
                    />
                    <AdminInput
                        label="Год начала реализации программы"
                        placeholder="2021"
                        inputType="number"
                        error={preparedErrors?.startedAt}
                        value={startedAt}
                        onInput={setInvestmentProgramStartedAt}
                    />
                    <AdminInput
                        label="Год завершения реализации программы"
                        placeholder="2022"
                        inputType="number"
                        error={preparedErrors?.finishedAt}
                        value={finishedAt}
                        onInput={setInvestmentProgramFinishedAt}
                    />
                </fieldset>

                <fieldset className="admin-fieldset admin-fieldset--cols-2">
                    <AdminInput
                        label="Открытый источник информации"
                        placeholder="https://minenergo.gov.ru/node/4174"
                        error={preparedErrors?.resource}
                        value={resource}
                        onInput={setInvestmentProgramResource}
                    />
                    <AdminInput
                        label="Отрасль"
                        placeholder="Банк"
                        error={preparedErrors?.industry}
                        value={industry}
                        onInput={setInvestmentProgramIndustry}
                    />
                </fieldset>

                <fieldset className="admin-fieldset admin-fieldset--cols-2">
                    <AdminInput
                        label="Количество инвестиционных проектов"
                        placeholder="250"
                        inputType="number"
                        error={preparedErrors?.quantity}
                        value={quantity}
                        onInput={setInvestmentProgramQuantity}
                    />
                    <AdminInput
                        label="Публикация в открытых источниках"
                        inputType="date"
                        error={preparedErrors?.publishedAt}
                        value={publishedAt}
                        onInput={setInvestmentProgramPublishedAt}
                    />
                </fieldset>

                <fieldset className="admin-fieldset admin-fieldset--cols-3">
                    <div>
                        <label className="admin-label">
                            <p>Файл (zip, максимум 20 Мб)</p>

                            <input
                                className="admin-input"
                                type="file"
                                onChange={onFileUpload}
                            />
                        </label>

                        {media &&
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a href={getMediaPath(media.path)} target="_blank">Просмотр файла</a>
                        }
                    </div>
                </fieldset>
            </form>
        </div>
    )
}


const mapStateToProps = (state: { adminInvestmentPrograms: IAdminInvestmentProgramsState })  => ({
    title: state.adminInvestmentPrograms.program.title,
    shortTitle: state.adminInvestmentPrograms.program.shortTitle,
    cost: state.adminInvestmentPrograms.program.cost,
    since: state.adminInvestmentPrograms.program.since,
    startedAt: state.adminInvestmentPrograms.program.startedAt,
    finishedAt: state.adminInvestmentPrograms.program.finishedAt,
    quantity: state.adminInvestmentPrograms.program.quantity,
    publishedAt: state.adminInvestmentPrograms.program.publishedAt,
    resource: state.adminInvestmentPrograms.program.resource,
    industry: state.adminInvestmentPrograms.program.industry,
})

export default React.memo(connect(mapStateToProps)(Edit))
