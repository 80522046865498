import React, { FC, SVGProps } from 'react'

const DropdownSVG: FC<SVGProps<SVGSVGElement>> = props => {
	return (
		<svg viewBox='0 0 10 6' fill='currenColor' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M9 1L5 5L1 1' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
		</svg>
	)
}

export { DropdownSVG }
