import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setFilter } from '@root-gipro/modules/userProjects/store/actions'
import { UncSearchProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { IconButton } from '@material-ui/core'

export const UncSearch: React.FC<UncSearchProps> = ({ setActiveList }) => {
	const [searchMode, setMode] = useState<boolean>(false)
	const [search, setSearch] = useState('')
	const inputFocus = useRef<HTMLInputElement>(null)
	const dispatch = useDispatch()
	useEffect(() => {
		if (search.length > 0) setActiveList('tables')
	}, [search])

	useEffect(() => {
		if (searchMode && inputFocus.current) inputFocus.current.focus()
	}, [searchMode, inputFocus])

	return (
		<div className='unc_search'>
			<div className={`search ${searchMode ? ' search_show' : ' search_hide'}`}>
				<input
					ref={inputFocus}
					onChange={e => {
						let value = e.currentTarget.value.toLowerCase()
						dispatch(setFilter(value))
						setSearch(value)
					}}
					type='text'
					value={search}
					className='search-input'
					placeholder='Расценка...'
				/>
			</div>
			<IconButton
				onClick={() => {
					setMode(!searchMode)
					dispatch(setFilter(''))
					setSearch('')
				}}
			>
				<div className='search-icon '></div>
			</IconButton>
		</div>
	)
}
