// правильно сделать в компоненте интерфейсы и передачу пропсов/интерфейсов
import React, { useState, useCallback, memo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Tab, TabsGroup } from '@root-gipro/shared/TabsComponents/CustomTabs'
import { ITabsNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import cn from 'classnames'

export const TabsNavLink: React.FC<ITabsNavLink> = memo(({ types, path }) => {
	const { currentPath }: any = usePageLink()
	const [selectedTab, setSelectedTab] = useState<string>(currentPath)
	const history = useHistory()

	const handleChange = useCallback(
		(_: React.ChangeEvent<{}>, newRoute: string) => {
			history.push(newRoute)
			setSelectedTab(newRoute)
		},
		[types]
	)

	return (
		<TabsGroup value={selectedTab} onChange={handleChange}>
			{types &&
				types.map(
					(type: any) =>
						type.tags.includes(path) && (
							<Tab
								className={cn({
									[style.active]: selectedTab === type.path,
								})}
								disableRipple
								key={type.path}
								label={type.title}
								value={type.path}
							/>
						)
				)}
		</TabsGroup>
	)
})
