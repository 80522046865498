import { IInvestmentProgram } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-program'

export enum AdminInvestmentProgramsTypes {
	GET_INVESTMENT_PROGRAM = 'GET_INVESTMENT_PROGRAM',
	CLEAR_INVESTMENT_PROGRAM = 'CLEAR_INVESTMENT_PROGRAM',
	CREATE_INVESTMENT_PROGRAM = 'CREATE_INVESTMENT_PROGRAM',
	DELETE_INVESTMENT_PROGRAM = 'DELETE_INVESTMENT_PROGRAM',
	UPDATE_INVESTMENT_PROGRAM = 'UPDATE_INVESTMENT_PROGRAM',
	SET_INVESTMENT_PROGRAM = 'SET_INVESTMENT_PROGRAM',
	SET_INVESTMENT_PROGRAM_MEDIA = 'SET_INVESTMENT_PROGRAM_MEDIA',
	SET_INVESTMENT_PROGRAM_MEDIA_IS_LOADING = 'SET_INVESTMENT_PROGRAM_MEDIA_IS_LOADING',
	SET_INVESTMENT_PROGRAM_VALIDATION_ERRORS = 'SET_INVESTMENT_PROGRAM_VALIDATION_ERRORS',
	SET_INVESTMENT_PROGRAM_QUANTITY = 'SET_INVESTMENT_PROGRAM_QUANTITY',
	SET_INVESTMENT_PROGRAM_SINCE = 'SET_INVESTMENT_PROGRAM_SINCE',
	SET_INVESTMENT_PROGRAM_TITLE = 'SET_INVESTMENT_PROGRAM_TITLE',
	SET_INVESTMENT_PROGRAM_INDUSTRY = 'SET_INVESTMENT_PROGRAM_INDUSTRY',
	SET_INVESTMENT_PROGRAM_SHORT_TITLE = 'SET_INVESTMENT_PROGRAM_SHORT_TITLE',
	SET_INVESTMENT_PROGRAM_MEDIA_ID = 'SET_INVESTMENT_PROGRAM_MEDIA_ID',
	SET_INVESTMENT_PROGRAM_STARTED_AT = 'SET_INVESTMENT_PROGRAM_STARTED_AT',
	SET_INVESTMENT_PROGRAM_FINISHED_AT = 'SET_INVESTMENT_PROGRAM_FINISHED_AT',
	SET_INVESTMENT_PROGRAM_PUBLISHED_AT = 'SET_INVESTMENT_PROGRAM_PUBLISHED_AT',
	SET_INVESTMENT_PROGRAM_COST = 'SET_INVESTMENT_PROGRAM_COST',
	SET_INVESTMENT_PROGRAM_RESOURCE = 'SET_INVESTMENT_PROGRAM_RESOURCE',
}

interface GetInvestmentProgram {
	type: AdminInvestmentProgramsTypes.GET_INVESTMENT_PROGRAM
	id: string | number
}

interface DeleteInvestmentProgram {
	type: AdminInvestmentProgramsTypes.DELETE_INVESTMENT_PROGRAM
	id: string | number
}

interface SetInvestmentProgram {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM
	program: IInvestmentProgram
}

interface SetInvestmentProgramMedia {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA
	media: any
}

interface SetInvestmentProgramMediaIsLoading {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_IS_LOADING
	isLoading: boolean
}

interface SetInvestmentProgramTitle {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_TITLE
	title: string
}

interface SetInvestmentProgramIndustry {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_INDUSTRY
	industry: string
}

interface SetInvestmentProgramMediaId {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_ID
	id: string
}

interface SetInvestmentProgramShortTitle {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SHORT_TITLE
	shortTitle: string
}

interface SetInvestmentProgramCost {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_COST
	cost: string
}

interface SetInvestmentProgramSince {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SINCE
	since: string
}

interface SetInvestmentProgramStartedAt {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_STARTED_AT
	startedAt: string
}

interface SetInvestmentProgramFinishedAt {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_FINISHED_AT
	finishedAt: string
}

interface SetInvestmentProgramQuantity {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_QUANTITY
	quantity: string
}

interface SetInvestmentProgramPublishedAt {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_PUBLISHED_AT
	publishedAt: string
}

interface SetInvestmentProgramResource {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_RESOURCE
	resource: string
}

interface SetInvestmentProgramValidationErrors {
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_VALIDATION_ERRORS
	errors: []
}

interface ClearInvestmentProgram {
	type: AdminInvestmentProgramsTypes.CLEAR_INVESTMENT_PROGRAM
}

export type AdminInvestmentProgramTypes =
	| ClearInvestmentProgram
	| DeleteInvestmentProgram
	| GetInvestmentProgram
	| SetInvestmentProgram
	| SetInvestmentProgramValidationErrors
	| SetInvestmentProgramTitle
	| SetInvestmentProgramShortTitle
	| SetInvestmentProgramCost
	| SetInvestmentProgramSince
	| SetInvestmentProgramStartedAt
	| SetInvestmentProgramFinishedAt
	| SetInvestmentProgramQuantity
	| SetInvestmentProgramPublishedAt
	| SetInvestmentProgramResource
	| SetInvestmentProgramMediaId
	| SetInvestmentProgramMedia
	| SetInvestmentProgramMediaIsLoading
	| SetInvestmentProgramIndustry
