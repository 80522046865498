import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

export const filterWidthWindow = (step: string | undefined) => {
	switch (step) {
		case StepType.BUILD_WORK:
			return '360px'
		case StepType.DESIGN_WORK:
			return '360px'
		case StepType.RECEIVE_BUILD_WORK:
		case StepType.RECEIVE_DESIGN_WORK:
			return '240px'
		case StepType.TKP_BUILD_WORK:
		case StepType.TKP_DESIGN_WORK:
			return '240px'

		default:
			break
	}
}
