import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	makeStyles,
	Theme,
	createStyles,
} from '@material-ui/core'
import TableBodyRowCommonProject from '@root-gipro/modules/purchases/components/PurchaseTableList';
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer';
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler';
import { ICompany, IGroup, IRegion } from '@root-gipro/store/interfaces';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tableContainer: {
			marginTop: theme.spacing(4),
			borderRadius: theme.shape.borderRadius,
			boxShadow: theme.shadows[3],
		},
		table: {
			minWidth: 650,
			width: 'max-content',
		},
		headerCell: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			border: '0px',
			fontWeight: 'bold',
			textAlign: 'center',
		},
		bodyCell: {
			textAlign: 'center',
			maxWidth: '200px', // Limit cell width
			whiteSpace: 'nowrap', // Prevent text from wrapping
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		row: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
			'&:hover': {
				backgroundColor: '#d4d4d480',
			},
		},
	})
)

interface FavoritesTableContainerProps {
    favoritesList: any[]
    groups: IGroup[]
	company: ICompany[]
	regions: IRegion[]
}
const FavoritesTableContainer:React.FC<FavoritesTableContainerProps> = ({favoritesList,regions,groups,company}) => {
    const classes = useStyles()
    const getRegionProject = (regionId: string) => {
		let regionIdx = regions.findIndex((region: IRegion) => region.id === regionId)
		return regions[regionIdx]?.name
	}
    return (
		<LoaderContainer isLoading={false}>
			{/* <div>{tableDownloading && <TableDownloadLoader />}</div> */}
			<ScrollHandler />
			<div className='purchase__list' >
				<TableContainer component={Paper} className={classes.tableContainer}>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead>
							<TableRow className='table_rows'>
								<TableRow className='high_row'>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>

									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={classes.headerCell}></TableCell>
									<TableCell className={`${classes.headerCell} multi_cell`} colSpan={2}>
										Классы
									</TableCell>
									<TableCell className={`${classes.headerCell} multi_cell`} colSpan={5}>
										План закупки
									</TableCell>
									<TableCell className={`${classes.headerCell} multi_cell`} colSpan={10}>
										Торги
									</TableCell>
								</TableRow>

								<TableCell className={classes.headerCell}>№ п/п</TableCell>
								<TableCell className={classes.headerCell}>Идентификатор</TableCell>
								<TableCell className={classes.headerCell}>Компания</TableCell>
								<TableCell className={classes.headerCell}>Наименование</TableCell>
								<TableCell className={classes.headerCell}>Статус</TableCell>
								<TableCell className={classes.headerCell}>
									Начальная максимальная цена контракта, млн руб. с НДС
								</TableCell>
								<TableCell className={classes.headerCell}>Дата окончания приёма заявок</TableCell>
								<TableCell className={classes.headerCell}>Связанных инвестиционных проектов</TableCell>
							
								<TableCell className={classes.headerCell}>ОКПД2</TableCell>
								<TableCell className={classes.headerCell}>ОКВЭД2</TableCell>
							
								<TableCell className={classes.headerCell}>Дата публикации</TableCell>
								<TableCell className={classes.headerCell}>Окончание действия плана</TableCell>
								<TableCell className={classes.headerCell}>Регион поставки</TableCell>
								<TableCell className={classes.headerCell}>Закупка у СМП</TableCell>
								<TableCell className={classes.headerCell}>Способ закупки</TableCell>
							
								<TableCell className={classes.headerCell}>Дата начала подачи заявок</TableCell>
								<TableCell className={classes.headerCell}>Дата окончания подачи заявок</TableCell>
								<TableCell className={classes.headerCell}>Дата рассмотрения заявок</TableCell>
								<TableCell className={classes.headerCell}>Дата подведения результатов</TableCell>
								<TableCell className={classes.headerCell}>Цена победителя</TableCell>
								<TableCell className={classes.headerCell}>Победитель ИНН</TableCell>
								<TableCell className={classes.headerCell}>Победитель КПП</TableCell>
								<TableCell className={classes.headerCell}>Победитель ОГРН</TableCell>
								<TableCell className={classes.headerCell}>Победитель Телефон</TableCell>
								<TableCell className={classes.headerCell}>Победитель E-mail</TableCell>
							
							</TableRow>
						</TableHead>
						<TableBody>
							{
                                favoritesList.map(row => (
								<TableBodyRowCommonProject
									region={getRegionProject(row.region)}
									company={company.find((company: ICompany) => company.id === row.company_id)?.name || ''}
									row={row}
								/>
							))
                            }
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</LoaderContainer>
	)
};

export default FavoritesTableContainer;