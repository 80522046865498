import React, { FC, MouseEvent, ChangeEvent, useState } from 'react'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import styles from '../style/Card.module.scss'
import { useDispatch } from 'react-redux'
import { selectTarif } from '../store/actions'

interface CardProps {
	showModal(event: MouseEvent<HTMLElement>): void
	handleChange(event: ChangeEvent<HTMLInputElement>): void
	item: any // todo any
}

const Card: FC<CardProps> = ({ handleChange, item, showModal }) => {
	const [check] = useState(false)
	const dispatch = useDispatch()
	return (
		<div className={styles.card}>
			<div className={styles.imageWrapper}>
				<img src={`/assets/images/${item.img}`} alt={item.name} />
			</div>
			<div>
				<div className={styles.title}>{item.name}</div>
				{item.id !== 3 ? (
					<div className={styles.price}>
						{item.tarifMonth * 3} ₽ <div className={styles.shrtText}>за 3 месяца</div>
					</div>
				) : (
					<div className={styles.price}>{item.tariff} ₽</div>
				)}
				<div className={styles.description}>{item.description}</div>
			</div>
			<div className={styles.wrapButton}>
				<PrimaryBtn
					onClick={e => {
						dispatch(selectTarif(`${item.id}`, check))
						showModal(e)
					}}
					fullWidth
					style={{ backgroundColor: `${item.color}`, border: `1px solid ${item.color}` }}
				>
					Заказать
				</PrimaryBtn>
				<PrimaryBtn
					style={{ backgroundColor: 'transparent', border: `1px solid ${item.color}`, color: `${item.color}` }}
				>
					<a href={item.link} target='_blank' rel="noopener noreferrer" className={styles.cardLink}>
						Подробнее
					</a>
				</PrimaryBtn>
			</div>
		</div>
	)
}

export { Card }
