export enum NameLink {
	COMMON_PROJECTS = 'Общие проекты',
	USER_PROJECTS = 'Личные проекты',
	PURCHASES = 'Закупки',
}
export enum NameLinkShrt {
	COMMON_PROJECTS = 'Общие',
	USER_PROJECTS = 'Личные',
	PURCHASES = 'Закупки',
}
