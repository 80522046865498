import { MediaState } from '@root-gipro/modules/media/interfaces/media.model'
import { MediaTypes } from '@root-gipro/modules/media/interfaces/media.actions'
import produce, { Draft } from 'immer'
const initialState: MediaState = {
    media: {}
}

const media = (draft: Draft<MediaState>, action: MediaTypes) => {
    switch (action.type) {
        default:
            return draft
    }
}

export const mediaReducer = produce(media, initialState)