import { call, put, takeEvery } from 'redux-saga/effects'
import { getDevelopmentSchemesAndPrograms } from '@root-gipro/store/api'
import {
    DevelopmentSchemesAndProgramsType
} from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.actions'
import {
    setDevelopmentSchemesAndPrograms,
    setFilterShortTittles,
    setFilterPublicationDates,
    setFilterIndustries,
    setFilterSinceDates,
    setDevelopmentSchemesAndProgramsTotalPrograms
} from '@root-gipro/modules/developmentSchemesAndPrograms/store/actions'
import {
    IDevelopmentSchemesAndProgramsFilterState
} from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.model'
import { getDevelopmentSchemesAndPrograms as getDevelopmentSchemesAndProgramAction } from '@root-gipro/modules/developmentSchemesAndPrograms/store/actions'


function* fetchDevelopmentSchemesAndPrograms ({ query }: { query: IDevelopmentSchemesAndProgramsFilterState, type: DevelopmentSchemesAndProgramsType.GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS}) {
    try {
        const response = yield call(getDevelopmentSchemesAndPrograms, query)
        yield put(setDevelopmentSchemesAndPrograms(response.sipr))
        yield put(setDevelopmentSchemesAndProgramsTotalPrograms(response.totalPrograms))
        yield put(setFilterShortTittles(response.titles))
        yield put(setFilterPublicationDates(response.publicationDates))
        yield put(setFilterIndustries(response.industries))
        yield put(setFilterSinceDates(response.sinceDates))
    } catch (e) {
        console.error(e)
    }
}

function* filterDevelopmentSchemesAndPrograms () {
    yield put(getDevelopmentSchemesAndProgramAction())
}

export default function* watchDevelopmentSchemesAndPrograms() {
    yield takeEvery(DevelopmentSchemesAndProgramsType.GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS, fetchDevelopmentSchemesAndPrograms)
    yield takeEvery(DevelopmentSchemesAndProgramsType.RESET_FILTER, filterDevelopmentSchemesAndPrograms)
    yield takeEvery(DevelopmentSchemesAndProgramsType.SET_FILTER_VALUE, filterDevelopmentSchemesAndPrograms)
    yield takeEvery(DevelopmentSchemesAndProgramsType.SET_ORDER, filterDevelopmentSchemesAndPrograms)
}