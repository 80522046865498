import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'

export const PurchaseSortList: ISortItem[] = [
	{ sortName: 'company_id', name: 'Организация' },
	{ sortName: 'region', name: 'Регион поставки' },
	// { sortName: 'plannedDate', name: 'Планируемая дата размещения извещения' },
	// { sortName: 'endDate', name: 'Срок исполнения договора' },
	// { sortName: 'method', name: 'Способ закупки' },
	{ sortName: 'registration_number', name: 'Идентификатор' },
	// { sortName: 'publicationDate', name: 'Дата изменения' },
	{ sortName: 'request_receiving_end_date', name: 'Дата окончания подачи заявок' },
	// { sortName: 'summingUpDate', name: 'Дата подведения итогов' },
	// { sortName: 'planType', name: 'Тип договора' },
	{ sortName: 'price', name: 'Начальная цена договора' },
]
