import { call, put, takeEvery } from 'redux-saga/effects'
import {
    getDevelopmentSchemesAndProgram,
    createDevelopmentSchemesAndProgram,
    deleteDevelopmentSchemesAndProgram,
    updateDevelopmentSchemesAndProgram, fetchMedia
} from '@root-gipro/store/api'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { push } from 'react-router-redux'
import {
    AdminDevelopmentSchemesAndProgramsTypes
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr.actions'
import {
    clearDevelopmentSchemesAndPrograms,
    setDevelopmentSchemesAndProgram, setDevelopmentSchemesAndProgramMedia,
    setDevelopmentSchemesAndProgramValidationErrors
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/store/actions'
import {
    IDevelopmentSchemesAndProgram
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr'

function* fetchDevelopmentSchemesAndProgram(
    { id }: { id: string, type: AdminDevelopmentSchemesAndProgramsTypes.GET_DEVELOPMENT_SCHEMES_AND_PROGRAM }
) {
    try {
        const program = yield call(getDevelopmentSchemesAndProgram, id)
        if (program.mediaId) {
            const media = yield call(fetchMedia, program.mediaId)
            yield put(setDevelopmentSchemesAndProgramMedia(media))
        }
        yield put(setDevelopmentSchemesAndProgram(program))
    } catch (e) {
        console.error(e)
    }
}

function* delDevelopmentSchemesAndProgram(
    { id }: { id: string, type: AdminDevelopmentSchemesAndProgramsTypes.DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM }
) {
    try {
        yield call(deleteDevelopmentSchemesAndProgram, id)
        yield call(clearDevelopmentSchemesAndPrograms)
        yield put(push('/admin/sipr'))
        yield put(showNotify({
            type: 'success',
            message: 'СИПР успешно удалена!',
            duration: 5000
        }))
    } catch (e) {
        console.error(e)
    }
}

function* updDevelopmentSchemesAndProgram(
    { id, program }: { id: string, program: IDevelopmentSchemesAndProgram, type: AdminDevelopmentSchemesAndProgramsTypes.UPDATE_DEVELOPMENT_SCHEMES_AND_PROGRAM }
) {
    try {
        const response = yield call(updateDevelopmentSchemesAndProgram, id, program)

        if (response.errors) {
            yield put(setDevelopmentSchemesAndProgramValidationErrors(response.errors))
        } else {
            yield put(showNotify({
                type: 'success',
                message: 'СИПР успешно обновлена!',
                duration: 5000
            }))
        }
    } catch (e) {
        console.error(e)
    }
}

function* createSipr(
    { program }: { program: any, type: AdminDevelopmentSchemesAndProgramsTypes.CREATE_DEVELOPMENT_SCHEMES_AND_PROGRAM }
) {
    try {
        const response = yield call(createDevelopmentSchemesAndProgram, program)
        if (response.errors) {
            yield put(setDevelopmentSchemesAndProgramValidationErrors(response.errors))
        } else {
            yield put(push(`/admin/sipr/${response.id}`))
            yield put(showNotify({
                type: 'success',
                message: 'СИПР успешно создана!',
                duration: 5000
            }))
        }
    } catch (e) {
        console.error(e)
    }
}

export default function* watchAdminDevelopmentSchemesAndPrograms() {
    yield takeEvery(AdminDevelopmentSchemesAndProgramsTypes.DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM, delDevelopmentSchemesAndProgram)
    yield takeEvery(AdminDevelopmentSchemesAndProgramsTypes.GET_DEVELOPMENT_SCHEMES_AND_PROGRAM, fetchDevelopmentSchemesAndProgram)
    yield takeEvery(AdminDevelopmentSchemesAndProgramsTypes.CREATE_DEVELOPMENT_SCHEMES_AND_PROGRAM, createSipr)
    yield takeEvery(AdminDevelopmentSchemesAndProgramsTypes.UPDATE_DEVELOPMENT_SCHEMES_AND_PROGRAM, updDevelopmentSchemesAndProgram)
}