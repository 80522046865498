import { FormControlLabel, makeStyles, Radio } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		marginLeft: 0,
	},
	label: {
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesCheckboxStyle = makeStyles(theme => ({
	root: {
		padding: '0',
		marginRight: 8,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}))

function HtmlString(html: string) {
	return <span dangerouslySetInnerHTML={{ __html: html }} />
}

export interface FilterItemCheckboxProps {
	value: boolean | number
	name: string
	queryValue: string
	fieldName: string
	setValue?(fieldValue: string, value: boolean | string): void
	updateData(value: string): void
	isDisable?: boolean
	isChecked: string[]
}

const FilterItemRadioSearch: React.FC<FilterItemCheckboxProps> = ({
	value,
	setValue,
	name,
	queryValue,
	isDisable,
	isChecked,
	fieldName,
	updateData,
}) => {
	const classes = classesFormControlLabelStyle()
	const classesCheckbox = classesCheckboxStyle()

	const [checked, setChecked] = useState(false)

	// useEffect(() => {
	// 	if (isReset) setChecked(false)
	// }, [isReset])

	useEffect(() => {
		isChecked.includes(name) ? setChecked(true) : setChecked(false)
	}, [isChecked])

	const handleChange = () => {
		setChecked(prev => !prev)
		updateData(name)
		// setValue(fieldName, name)
	}

	return (
		<div className='aside-filter__checkbox'>
			<FormControlLabel
				control={
					<Radio
						disabled={isDisable}
						checked={checked}
						onChange={handleChange}
						color='primary'
						size='small'
						className={classesCheckbox.root}
					/>
				}
				label={
					<>
						<span>{HtmlString(name.replace(new RegExp(queryValue, 'gi'), match => `<b>${match}</b>`))}</span>
					</>
				}
				classes={classes}
			/>
		</div>
	)
}

export default FilterItemRadioSearch
