import { Collapse } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FilterItemGroupProps } from '@root-gipro/shared/Filter/models/filter-item-group.props'
import variables from '@root-gipro/styles/config/variables.module.scss'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

const FilterItemGroup: React.FC<FilterItemGroupProps> = ({ name, children, isNoEmpty, label }) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const [localStorageState, setLocalStorageState]: any = useState({})

	const routeMatch = useRouteMatch()
	const url = routeMatch.path.slice(1)

	useEffect(() => {
		if (localStorage.getItem('filtersExpanded') !== null) {
			setLocalStorageState(JSON.parse(localStorage.getItem('filtersExpanded') as string))
		}
	}, [])

	useEffect(() => {
		setOpen(localStorageState[url]?.[label])

		if (name === 'Проект') {
			setOpen(true)
		}
	}, [localStorageState])

	const handleOpen = () => {
		localStorage.setItem(
			'filtersExpanded',
			JSON.stringify({
				...localStorageState,
				[url]: { ...localStorageState[url], [label]: !isOpen },
			})
		)

		setOpen(!isOpen)
	}

	return (
		<div className='aside-filter__item'>
			<div className='aside-filter__item-head'>
				{name !== 'Проект' ? (
					<h4 className={`aside-filter__item-select ${isOpen ? 'open' : ''}`} onClick={handleOpen}>
						<span className='aside-filter__item-name__filter'>{name}</span>
						<span className='aside-filter__expandIcon'>
							{isOpen ? (
								<ExpandLessIcon
									style={{
										color: isNoEmpty ? 'white' : variables.gray,
										background: isNoEmpty ? '#1baaf0' : 'transparent',
									}}
								></ExpandLessIcon>
							) : (
								<ExpandMoreIcon
									style={{
										color: isNoEmpty ? 'white' : variables.grey,
										background: isNoEmpty ? '#1baaf0' : 'transparent',
									}}
								></ExpandMoreIcon>
							)}
						</span>
					</h4>
				) : null}
			</div>
			<Collapse in={isOpen} unmountOnExit className='aside-filter__item-dropdown'>
				{children}
			</Collapse>
		</div>
	)
}

export default FilterItemGroup
