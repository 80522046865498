import { ProjectType } from '../enums/projects.enum'

export const types =
[
	{
		title: 'Проекты',
		value: ProjectType.PROJECTS,
	},
	{
		title: 'Закупки',
		value: ProjectType.PURCHASES,
	},
]
