import React, { useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/ru';
import DataTable from 'react-data-table-component'
import PenIcon from '@root-gipro/components/Icons/PenIcon'
import { NavLink } from 'react-router-dom'
import { Routes } from '@root-gipro/router'
import { useDispatch, useSelector } from 'react-redux'
import { getDevelopmentSchemesAndPrograms } from '@root-gipro/modules/developmentSchemesAndPrograms/store/actions'
import { IState } from '@root-gipro/store/interfaces'

const Table = () => {
    const dispatch = useDispatch()
    const localLocale = moment()
    localLocale.locale('ru')

    useEffect(() => {
        dispatch(getDevelopmentSchemesAndPrograms())
    }, [])

    const programs = useSelector((state:IState) => state.developmentSchemesAndPrograms.programs)

    const columns = [
        {
            name: 'Заголовок',
            selector: (row: any) => row?.title,
        },
        {
            name: 'Год утверждения',
            selector: (row: any) => row?.since,
        },
        {
            name: 'Период реализации',
            selector: (row: any) => `с ${row?.startedAt} по ${row?.finishedAt}`,
        },
        {
            name: 'Ресурс',
            cell: (row: any) => <a target="_blank" href={row?.resource}>Открытый источник</a>,
        },
        {
            name: 'Редактирование',
            cell: (row: any) => (
                <NavLink to={`/admin/sipr/${row?.id}`} className="admin-table__icon" type="button">
                    <PenIcon />
                </NavLink>
            ),
        }
    ];

    return (
        <div className="admin-container">
            <div className="admin-form__header">
                <h3>Таблица схем и программ развития</h3>

                <div className="admin-form__header-buttons">
                    <NavLink to={Routes.ADMIN_CREATE_DEVELOPMENT_SCHEME_AND_PROGRAM} className="admin-button primary">Добавить</NavLink>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={programs}
                highlightOnHover
            />
        </div>
    )
}

export default React.memo(Table)
