import React, { useEffect, useMemo, useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import TableInvest from '@root-gipro/shared/Table/container/TableInvest'
import { selectPurchase } from '@root-gipro/modules/purchases/store/actions'
import DetailPurchase from '@root-gipro/modules/purchases/components/Detail/DetailPurchase/DetailPurchase'
import { IState } from '@root-gipro/store/interfaces/state'
import { theadData, gridTemplateColumns } from '@root-gipro/modules/purchases/models/consts/invest-table-head.const'
import { bodyParams } from '@root-gipro/modules/purchases/models/consts/invest-table-body.const'
import { IPurchase } from '../models/interfaces/purchase.model'

export type ToggleInvestDetailTypes = (event: React.MouseEvent<HTMLDivElement>) => void | null

const PurchaseDetail: FC = () => {
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const { type }: any = useParams()
	const { selectedPurchase, state } = useSelector((state: IState) => state.purchases)

	const [investProjectDetail, setInvestProjectDetail] = useState<null | HTMLElement>(null)
	const openInvestProjectDetail = (event: React.MouseEvent<HTMLDivElement>) =>
		setInvestProjectDetail(event.currentTarget)

	const handleClose = () => setInvestProjectDetail(null)

	useEffect(() => {
		const id = pathname.replace(/[^\d]/g, '')
		dispatch(selectPurchase(id, type))
	}, [])

	const detailPurchase = {
		purchase: selectedPurchase as IPurchase,
		toggleInvestDetail: (event: React.MouseEvent<HTMLDivElement>) => {
			return selectedPurchase?.linkedProjectIds?.length ? openInvestProjectDetail(event) : null
		},
	}
	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [selectedPurchase])

	return (
		<LoaderContainer isLoading={state.isLoadingSelectedPurchaseDetail}>
			<div className='container-full'>
				{selectedPurchase ? <DetailPurchase {...detailPurchase} onList={false} /> : null}
			</div>
			<TableInvest
				investProjectDetailsAnchorEl={investProjectDetail}
				onClose={handleClose}
				projects={selectedPurchase?.linkedProjectIds}
				theadParams={theadParams}
				bodyParams={bodyParams}
			/>
		</LoaderContainer>
	)
}

export default PurchaseDetail
