import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, makeStyles, Switch, TextField } from '@material-ui/core'
import { FilterItemCheckboxProps } from '@root-gipro/shared/Filter/models/filter-item-checkbox.props'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import {
	disabledSimilarProjectFilterState,
	isSimilarProjectFilterState,
} from '@root-gipro/modules/filter/store/actions'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		marginLeft: 0,
	},
	label: {
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesSwitcherStyle = makeStyles(theme => ({
	root: {
		// '&:hover': {
		// 	backgroundColor: 'transparent',
		// },

		'& .MuiButtonBase-root': {
			padding: 9,
			'& .MuiIconButton-label': {
				width: '100%',
				display: 'flex',
				alignItems: 'inherit',
				justifyContent: 'inherit',
			},
		},
	},
}))

const FilterItemSwithcer: React.FC<FilterItemCheckboxProps> = ({ value, setValue, name, isDisable, fieldName }) => {
	const { isSimilar } = useSelector((state: IState) => state.filter.commonProjects)
	const classes = classesSwitcherStyle()
	const classesLabel = classesFormControlLabelStyle()
	const dispatch = useDispatch()

	const handleChange = () => {
		if (isSimilar) {
			dispatch(disabledSimilarProjectFilterState())
		}
		// dispatch(isSimilarProjectFilterState(false))
	}

	return (
		<div className='aside-filter__checkbox'>
			<FormControlLabel
				label={name}
				labelPlacement='start'
				control={
					<Switch
						checked={isSimilar}
						onChange={() => handleChange()}
						disabled={!isSimilar}
						name='isSimilar'
						color='primary'
						classes={classes}
					/>
				}
				classes={classesLabel}
			/>
			<TextField style={{ display: 'none' }} />
		</div>
	)
}

export default FilterItemSwithcer
