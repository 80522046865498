import { ITariffsState } from '../interfaces/tariffs-state.model'

export const tariffsState: ITariffsState = {
	tariffPlans: null,
	selectedSettings: {
		tarif_price_id: null,
		months: null,
	},
	reportType: 'standard',
	calcUnlimit: false,
}
