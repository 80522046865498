export const formatMoney = (value: number|string) => {
    const numberValue = typeof value === 'string' ? parseInt(value) : value

    const parts = numberValue.toFixed(2).split('.')
    return [
        parts[0]
            .split('')
            .reverse()
            .map((item, index) => (index + 1) % 3 === 0 ? ` ${item}` : item)
            .reverse()
            .join(''),
        parts[1]
    ]
        .join(',')
        .trim()
}
