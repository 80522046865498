import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { CardItemProps } from '@root-gipro/shared/Card/CardItem/card-item.props'

const CardItem: React.FC<CardItemProps> = ({ title, value }) => {
	return (
		<div key={uniqueId('cardRightItem_')} className='card-container__right__item'>
			<div className='card-container__right__item-title'>{title}</div>
			{value && <div className='card-container__right__item-value'>{value}</div>}
		</div>
	)
}

export default CardItem
