import { Tooltip } from '@material-ui/core'
import React from 'react'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

interface ITooltipHelp {
	title: string
}

const TooltipHelp: React.FC<ITooltipHelp> = ({ title }) => {
	return (
		<Tooltip title={<p className='tooltip'>{title}</p>}>
			<HelpOutlineOutlinedIcon fontSize='small' color='disabled' />
		</Tooltip>
	)
}

export default TooltipHelp
