import produce, { Draft } from 'immer'
import { IAdminInvestmentProgramsState } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-programs.model'
import {
	AdminInvestmentProgramsTypes,
	AdminInvestmentProgramTypes,
} from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-programs.actions'

const initialState: IAdminInvestmentProgramsState = {
	programId: null,
	media: null,
	mediaIsLoading: false,
	program: {
		industry: '',
		mediaId: null,
		quantity: '',
		since: '',
		title: '',
		shortTitle: '',
		startedAt: '',
		finishedAt: '',
		publishedAt: '',
		cost: '',
		resource: '',
	},
	errors: [],
}

const investmentPrograms = (draft: Draft<IAdminInvestmentProgramsState>, action: AdminInvestmentProgramTypes) => {
	switch (action.type) {
		case AdminInvestmentProgramsTypes.CLEAR_INVESTMENT_PROGRAM:
			draft.programId = null
			draft.media = null
			draft.mediaIsLoading = false
			draft.program = {
				mediaId: null,
				industry: '',
				quantity: '',
				since: '',
				title: '',
				shortTitle: '',
				startedAt: '',
				finishedAt: '',
				publishedAt: '',
				cost: '',
				resource: '',
			}
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA:
			draft.media = action.media
			draft.program.mediaId = action.media.id
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_IS_LOADING:
			return { ...draft, mediaIsLoading: action.isLoading }
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM:
			delete action.program.id
			draft.program = action.program
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_VALIDATION_ERRORS:
			draft.errors = action.errors
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_TITLE:
			draft.program.title = action.title
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_INDUSTRY:
			draft.program.industry = action.industry
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SHORT_TITLE:
			draft.program.shortTitle = action.shortTitle
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_ID:
			draft.program.mediaId = action.id
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_COST:
			draft.program.cost = action.cost
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SINCE:
			draft.program.since = action.since
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_STARTED_AT:
			draft.program.startedAt = action.startedAt
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_FINISHED_AT:
			draft.program.finishedAt = action.finishedAt
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_QUANTITY:
			draft.program.quantity = action.quantity
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_PUBLISHED_AT:
			draft.program.publishedAt = action.publishedAt
			break
		case AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_RESOURCE:
			draft.program.resource = action.resource
			break
		default:
			return draft
	}
}
export const adminInvestmentProgramsReducer = produce(investmentPrograms, initialState)
