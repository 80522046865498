import { IDevelopmentSchemesAndProgram } from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr'

export enum AdminDevelopmentSchemesAndProgramsTypes {
	CLEAR_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'CLEAR_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	GET_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'GET_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	CREATE_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'CREATE_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	UPDATE_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'UPDATE_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_VALIDATION_ERRORS = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_VALIDATION_ERRORS',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_QUANTITY = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_QUANTITY',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SINCE = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SINCE',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_TITLE = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_TITLE',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_ID = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_ID',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_IS_LOADING = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_IS_LOADING',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SHORT_TITLE = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SHORT_TITLE',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_STARTED_AT = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_STARTED_AT',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_FINISHED_AT = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_FINISHED_AT',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_PUBLISHED_AT = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_PUBLISHED_AT',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_RESOURCE = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_RESOURCE',
	SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_INDUSTRY = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_INDUSTRY',
}

interface GetDevelopmentSchemesAndProgram {
	type: AdminDevelopmentSchemesAndProgramsTypes.GET_DEVELOPMENT_SCHEMES_AND_PROGRAM
	id: string | number
}

interface DeleteDevelopmentSchemesAndProgram {
	type: AdminDevelopmentSchemesAndProgramsTypes.DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM
	id: string | number
}

interface SetDevelopmentSchemesAndProgram {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM
	program: IDevelopmentSchemesAndProgram
}

interface SetDevelopmentSchemesAndProgramTitle {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_TITLE
	title: string
}

interface SetDevelopmentSchemesAndProgramShortTitle {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SHORT_TITLE
	shortTitle: string
}

interface SetDevelopmentSchemesAndProgramIndustry {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_INDUSTRY
	industry: string
}

interface SetDevelopmentSchemesAndProgramSince {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SINCE
	since: string
}

interface SetDevelopmentSchemesAndProgramStartedAt {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_STARTED_AT
	startedAt: string
}

interface SetDevelopmentSchemesAndProgramFinishedAt {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_FINISHED_AT
	finishedAt: string
}

interface SetDevelopmentSchemesAndProgramQuantity {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_QUANTITY
	quantity: string
}

interface SetDevelopmentSchemesAndProgramPublishedAt {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_PUBLISHED_AT
	publishedAt: string
}

interface SetDevelopmentSchemesAndProgramMediaId {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_ID
	mediaId: string
}

interface SetDevelopmentSchemesAndProgramMedia {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA
	media: any
}
interface SetDevelopmentSchemesAndProgramMediaIsLoading {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_IS_LOADING
	isLoading: any
}

interface SetDevelopmentSchemesAndProgramResource {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_RESOURCE
	resource: string
}

interface SetDevelopmentSchemesAndProgramValidationErrors {
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_VALIDATION_ERRORS
	errors: []
}

interface ClearDevelopmentSchemesAndProgramState {
	type: AdminDevelopmentSchemesAndProgramsTypes.CLEAR_DEVELOPMENT_SCHEMES_AND_PROGRAM
}

export type AdminDevelopmentSchemesAndProgramTypes =
	| DeleteDevelopmentSchemesAndProgram
	| GetDevelopmentSchemesAndProgram
	| SetDevelopmentSchemesAndProgram
	| SetDevelopmentSchemesAndProgramValidationErrors
	| SetDevelopmentSchemesAndProgramTitle
	| SetDevelopmentSchemesAndProgramShortTitle
	| SetDevelopmentSchemesAndProgramSince
	| SetDevelopmentSchemesAndProgramStartedAt
	| SetDevelopmentSchemesAndProgramFinishedAt
	| SetDevelopmentSchemesAndProgramQuantity
	| SetDevelopmentSchemesAndProgramPublishedAt
	| SetDevelopmentSchemesAndProgramResource
	| ClearDevelopmentSchemesAndProgramState
	| SetDevelopmentSchemesAndProgramMediaId
	| SetDevelopmentSchemesAndProgramMedia
	| SetDevelopmentSchemesAndProgramMediaIsLoading
	| SetDevelopmentSchemesAndProgramIndustry
