import { IInvestmentProgramsState } from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.model'
import { InvestmentProgramsType, InvestmentProgramsTypes } from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.actions'
import produce, { Draft } from 'immer'

const initialState: IInvestmentProgramsState = {
    programs: [],
    titles: [],
    publicationDates: [],
    industries: [],
    since: [],
    totalCost: 0,
    totalPrograms: 0,
    statistics: [],
    order: {
        by: 'title',
        direction: 'DESC'
    },
    filter: {
        shortTitle: '',
        publishedAt: '',
        since: '',
        industry: '',
        startedAt: '',
        finishedAt: '',
        minCost: '',
        maxCost: ''
    }
}

const investmentPrograms = (draft: Draft<IInvestmentProgramsState>, action: InvestmentProgramsTypes) => {
    switch (action.type) {
        case InvestmentProgramsType.SET_INVESTMENT_PROGRAMS:
            draft.programs = action.programs
            break
        case InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_COST:
            draft.totalCost = action.cost
            break
        case InvestmentProgramsType.SET_ORDER:
            draft.order = action.order
            break
        case InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_PROGRAMS:
            draft.totalPrograms = action.total
            break
        case InvestmentProgramsType.RESET_FILTER:
            draft.filter = {
                industry: '',
                shortTitle: '',
                publishedAt: '',
                since: '',
                startedAt: '',
                finishedAt: '',
                minCost: '',
                maxCost: ''
            }
            break
        case InvestmentProgramsType.SET_FILTER_PUBLICATION_DATES:
            draft.publicationDates = action.publicationDates
            break
        case InvestmentProgramsType.SET_FILTER_INDUSTRIES:
            draft.industries = action.industries
            break
        case InvestmentProgramsType.SET_FILTER_SHORT_TITLES:
            draft.titles = action.titles
            break
        case InvestmentProgramsType.SET_FILTER_SINCE_DATES:
            draft.since = action.sinceDates
            break
        case InvestmentProgramsType.SET_FILTER_VALUE:
            draft.filter[action.key] = action.value
            break
        case InvestmentProgramsType.SET_STATISTICS:
                draft.statistics = action.statData
                break
        default:
            return draft
    }
}
export const investmentProgramsReducer = produce(investmentPrograms, initialState)
