import React from 'react'
import { IUserInfoDiv } from '@root-gipro/store/interfaces'
import styles from '@root-gipro/modules/commonProjects/styles/Reports.module.scss'

const UserInfoDiv: React.FC<IUserInfoDiv> = ({ data }) => {
	const userData: any = data.user
	const userAccess: Array<any> = data.access
	// console.log(userAccess)
	//document.querySelectorAll('[role="tooltip"]:last-of-type')[0].style.display=none

	const mods = userAccess?.map((el: any) =>
											<tr><td>{el.mod}</td>
											    <td>{el.tarif}</td>
											    <td>{el.access}</td>
											    <td>{el.start}</td>
											    <td>{el.end}</td></tr>
											)
	return (

	    <div>
	      {(() => {
	        if (data && userData) {
	          return (
					<div className='card-container'>
						<div className={styles.infoBlock}>

					 		<span>Данные пользователя:</span>
					 		<ul className={styles.infoLi}>
					 		<li><b>ID:</b> {userData.id}</li>
					 		<li><b>Email:</b> {userData.email} {userData.verified}</li>
					 		<li><b>Имя:</b> {userData.fio}</li>
					 		<li><b>Тел:</b> {userData.data && userData.data.phone}</li>
					 		{userData.data && userData.data.company && <li><b>Компания:</b> {userData.data.company}</li>}
					 		{userData.created_at && <li><b>Добавлен:</b> {userData.created_at}</li>}
					 		</ul>

					 		{ (userAccess && userAccess.length>0) &&
							 		<table>
							 		<tr><th>Модуль</th><th>Тариф</th><th>Доступ</th><th>С</th><th>До</th></tr>
								      {mods}
							 		</table>
							}

							{   (!userAccess || userAccess.length===0) &&
							 	 	<span>Доступ не оплачен!</span>
					 		}
					 	</div>
					</div>
	          )
	        } else {
	          return (
	            <div style={{padding:'1em'}}>Пользователь не найден!</div>
	          )
	        }
	      })()}
	    </div>

	)
}

export default UserInfoDiv
