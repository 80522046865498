import { ReactText } from 'react'
import { formatDate } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'

interface ICardRightSidePrices {
	title: string
	value: (item: IPurchase) => ReactText | JSX.Element | null | undefined
}

export const CardRightSidePrices: ICardRightSidePrices[] = [
	{
		title: 'Начальная максимальная цена контракта, млн руб. с НДС',
		value: (item: IPurchase) => (item.price>0) ? Number.parseFloat((item.price / 10 ** 6).toString()).toFixed(3) : 'Не указано'
	},
	// {
	// 	title: 'Планируемая дата размещения извещения',
	// 	value: (item: IPurchase) => formatDateLong(item.plannedDate, true),
	// },
	{
		title: 'Дата окончания приёма заявок',
		value: (item: IPurchase) => {
			return (item.request_receiving_end_date) ? formatDate(item.request_receiving_end_date) : 'Не указано'
		},
	},
	// {
	// 	title: 'Срок исполнения договора',
	// 	value: (item: IPurchase) => {
	// 		return (item.endDate) ? formatDate(item.endDate) : 'Не указано'
	// 	},
	// },
	{
		title: 'Инвестиционных проектов',
		value: (item: IPurchase) => item?.linkedProjectIds?.length > 0 ? item.linkedProjectIds.length : item?.linkedProjects?.length > 0 ? item.linkedProjects.length : '0'
	},

]
