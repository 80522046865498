export const gridTemplateColumns = '41px minmax(240px, 438px) 346px 141px 141px 141px'

export const theadData = [
	{
		title: '№',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Наименование строительного ресурса',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Технические параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Кол-во',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Ед. измерения',
		textAlign: 'textAlignLeft',
	},
]
