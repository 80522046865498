import React, { ReactText, useContext, useCallback, MouseEvent, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import CardCommonProject from '@root-gipro/modules/commonProjects/components/CardProject/CardCommonProject'
import {
	actionFavoriteCommonProjects,
	appendCommonProjects,
	getSelectCommonProject,
	setTargetProjectId,
} from '@root-gipro/modules/commonProjects/store/actions'
import { ModalContext } from '@root-gipro/context/modal-context'
import Loader from '@root-gipro/shared/Loader/Loader'
import ReportLoader from '@root-gipro/shared/Loader/ReportLoader'
import { CostWorksModalContainer } from '@root-gipro/modules/commonProjects/components/CostWorks/CostWorksModalContainer'
import { ICommonProject, ICommonProjectsListProps, IRegion } from '@root-gipro/store/interfaces'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import { useHistory } from 'react-router-dom'
import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import { Button } from '@material-ui/core'
import ProjectsTableContainer from './CardProject/TableCommonProjects'
import TableDownloadLoader from '@root-gipro/shared/Loader/TableDowmloadLoader'

const CommonProjectsTable: React.FC<ICommonProjectsListProps> = ({ projects, toggleType }) => {
	const { anchorEl, setAnchorEl, setAccessory } = useContext(ModalContext)
let { isFavorites, loading, paginationCounter, reporting, tableDownloading, targetProjectId, groups, company, regions, loadPagination } = useSelector(
		(state: IState) => ({
			isFavorites: state.commonProjects.isFavorites,
			loading: state.commonProjects.loading,
			paginationCounter: state.commonProjects.paginationCounter,
			reporting: state.commonProjects.reporting,
			tableDownloading: state.commonProjects.tableDownloading,
			targetProjectId: state.commonProjects.targetProjectId,
			groups: state.public.groups,
			company: state.public.company,
			regions: state.public.regions,
			loadPagination: state.commonProjects.loadPagination,
		}),
		shallowEqual
	)

	const dispatch = useDispatch()
	const history = useHistory()

	const popupContent = {
		shrtTxt: 'Рассчитать стоимость:',
		items: [
			{
				step: StepType.DESIGN_WORK,
				itemTxt: 'Проектных работ',
			},
			{
				step: StepType.BUILD_WORK,
				itemTxt: 'Строительных работ',
			},
		],
	}
	const handleMenuItemClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			setAnchorEl!(event.currentTarget)
			setAccessory!(event.currentTarget.dataset.step!)
		},
		[setAnchorEl]
	)
	const getRegionProject = (firstRegionId: string) => {
		let regionIdx = regions.findIndex((region: IRegion) => region.id === firstRegionId)
		return regions[regionIdx]?.name
	}

	const getProjectById = useCallback(
		(id: ReactText) => {
			dispatch(getSelectCommonProject(id))
			history.push({
				pathname: `/common-projects#${id}`,
			})
		},
		[history]
	)
	const handlerFavoritesActions = useCallback((projectId: string, action: 'DELETE' | 'POST', project: any) => {
		dispatch(actionFavoriteCommonProjects(projectId, action, project))
	}, [])

	const [showUpBtn, setShowUpBtn] = useState(false)
	const [allowLoadMore, setAllowLoadMore] = useState(true);

	const onScroll = useCallback(() => {
		const scrollTop = window.document.documentElement.scrollTop;
		const scrollHeight = window.document.documentElement.scrollHeight;
		const clientHeight = window.innerHeight;
		const nearBottom = scrollHeight - scrollTop <= clientHeight + 10;
	
		if (nearBottom && allowLoadMore && !loadPagination && !isFavorites) {
		  setAllowLoadMore(false);
		  dispatch(appendCommonProjects(paginationCounter + 1));
		}
	  }, [allowLoadMore, loadPagination, isFavorites, paginationCounter]);
	
	  useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	  }, [onScroll]);
	
	  useEffect(() => {
		if (!loadPagination) {
		  setAllowLoadMore(true);
		}
	  }, [loadPagination]);
  

	const scrollTopPage = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	useEffect(() => {
		const element = document.getElementById(`#${targetProjectId}`)
		let revertStylesTimeout: any
		if(!loading){
		const scrollTimeout = setTimeout(() => {
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
				element.style.transition = 'transform 0.3s, box-shadow 0.3s'
				element.style.transform = 'scale(1.1)'
				element.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.3)'

				// Возврат стилей к исходным значениям через 1 секунду
				revertStylesTimeout = setTimeout(() => {
					element.style.transform = ''
					element.style.boxShadow = ''
					dispatch(setTargetProjectId(''))
				}, 1000)
			}
		}, 800)

		return () => {
			// Очистка всех таймеров
			clearTimeout(scrollTimeout)
			clearTimeout(revertStylesTimeout)
		}
	}
	}, [loading])


	return (
		<>
			<div>{reporting && <ReportLoader />}</div>
			<div>{tableDownloading && <TableDownloadLoader />}</div>
			<ScrollHandler />

			<div className='project__list' onScroll={onScroll}>
				{loading ? (
					<Loader />
				) : (
					projects &&
					(
                        <ProjectsTableContainer projects={projects}  
							toggleType={toggleType}
							groups={groups}
							company={company}
							getRegionProject={getRegionProject}
							getProjectById={getProjectById}
							handlerFavoritesActions={handlerFavoritesActions}

                        />
                    )
				)}
			</div>
			{(anchorEl?.dataset.step === StepType.DESIGN_WORK || anchorEl?.dataset.step === StepType.BUILD_WORK) && (
				<CostWorksModalContainer />
			)}
			{anchorEl?.dataset.popup === 'costWorks' && (
				<DropDown
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					classes='cost-works'
					style={{ width: 180 }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<>
						<p className='drop-down-shrt-txt'>{popupContent.shrtTxt}</p>
						{popupContent.items.map(item => (
							<div key={item.step} className='drop-down-item' data-step={item.step} onClick={handleMenuItemClick}>
								{item.itemTxt}
							</div>
						))}
					</>
				</DropDown>
			)}

			{showUpBtn ? (
				<Button
					style={{
						position: 'fixed',
						bottom: '110px',
						right: '33px',
						borderRadius: '40px',
						minWidth: '24px',
						padding: '6px',
					}}
					variant='contained'
					onClick={scrollTopPage}
					color='primary'
				>
					<ArrowUpwardIcon style={{ color: 'white' }} />
				</Button>
			) : null}
		</>
	)
}

export default React.memo(CommonProjectsTable)
