import { call, put, takeEvery } from 'redux-saga/effects'
import { getInvestmentPrograms, getStatistics } from '@root-gipro/store/api'
import {
    getInvestmentPrograms as getInvestmentProgramsAction, setInvestmentProgramsIndustries,
    setInvestmentProgramsTotalCost, setInvestmentProgramsTotalPrograms, setStatistics
} from '@root-gipro/modules/investmentPrograms/store/actions'
import {
    setFilterPublicationDates,
    setFilterShortTittles, setFilterSinceDates,
    setInvestmentPrograms
} from '@root-gipro/modules/investmentPrograms/store/actions'
import {
    InvestmentProgramsType
} from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.actions'
import {
    IInvestmentProgramsFilterState
} from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.model'

function* fetchInvestmentPrograms({ query }: { query: IInvestmentProgramsFilterState, type: InvestmentProgramsType.GET_INVESTMENT_PROGRAMS }) {
    try {
        const response = yield call(getInvestmentPrograms, query)
        yield put(setInvestmentPrograms(response.ipr))
        yield put(setFilterShortTittles(response.titles))
        yield put(setInvestmentProgramsTotalCost(response.totalCost))
        yield put(setInvestmentProgramsIndustries(response.industries))
        yield put(setInvestmentProgramsTotalPrograms(response.totalPrograms))
        yield put(setFilterPublicationDates(response.publicationDates))
        yield put(setFilterSinceDates(response.sinceDates))
    } catch (e) {
        console.error(e)
    }
}

function* filterInvestmentPrograms () {
    yield put(getInvestmentProgramsAction())
}

function* getStatisticsData  (){
    const response = yield call(getStatistics);
    yield put(setStatistics(response.data.statistics))

}

export default function* watchInvestmentPrograms() {
    yield takeEvery(InvestmentProgramsType.GET_INVESTMENT_PROGRAMS, fetchInvestmentPrograms)
    yield takeEvery(InvestmentProgramsType.SET_FILTER_VALUE, filterInvestmentPrograms)
    yield takeEvery(InvestmentProgramsType.RESET_FILTER, filterInvestmentPrograms)
    yield takeEvery(InvestmentProgramsType.SET_ORDER, filterInvestmentPrograms)
    yield takeEvery(InvestmentProgramsType.GET_STATISTICS, getStatisticsData)

}