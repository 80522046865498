import React, { MouseEvent, ReactText, useCallback, useContext, useEffect } from 'react'
import FilterHeader from '@root-gipro/shared/FilterHeader/FilterHeader'
import { useDispatch, useSelector } from 'react-redux'
import { IRegion, IState } from '@root-gipro/store/interfaces'
import {
	deleteFavoritesProjects,
	editToggleType,
	setFavoritesProjects,
	setLoadingFavorites,
	sortFavoritesProjects,
	sortFavoritesPurchases,
} from '@root-gipro/modules/favorites/store/actions'
import Loader from '@root-gipro/shared/Loader/Loader'
import { types } from '@root-gipro/modules/favorites/models/consts/tabs-params.const'
import sortList from '@root-gipro/modules/commonProjects/models/const/sort-list.const'
import { setFilterOpen } from '@root-gipro/store/actions/maket'
import { toggleFilter } from '@root-gipro/modules/filter/utils/toggle-filter'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import { Item } from '@root-gipro/modules/favorites/components/Item'
import { getFavoritesProjects } from '../store/actions'
import { ProjectType } from '../models/enums/projects.enum'
import { CostWorksModalContainer } from '@root-gipro/modules/commonProjects/components/CostWorks/CostWorksModalContainer'
import { ModalContext } from '@root-gipro/context/modal-context'
import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { popupContent } from '../models/consts/popup-сontent.const'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { actionFavoriteCommonProjects, getCommonProjects } from '@root-gipro/modules/commonProjects/store/actions'
import { Alert } from '@material-ui/lab'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { PurchaseSortList } from '@root-gipro/modules/purchases/models/consts/purchase-sort-list.const'
import { filtersRequest, setCallbacks, setFilterSortParams } from '@root-gipro/modules/filter/store/actions'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { favoritesApi } from '@root-gipro/modules/favorites/api/favorites'
import { getPurchasesListByParams } from '@root-gipro/modules/purchases/api/purchases'
import ym from 'react-yandex-metrika'
import PurchaseTable from '@root-gipro/modules/purchases/components/PurchaseTable'
import FavoritesTableContainer from './FavoritesPurchasesTableContainer'
import FavoritesProjectsTableContainer from './FavoritesProjectsTableContainer'

const FavoritesContainer = () => {
	const sortListFavorites = [
		...sortList,
		{
			name: 'Новые закупки',
			sortName: 'newPurchases',
		},
	]

	const { groups, company, regions } = useSelector((state: IState) => state.public)
	const { anchorEl, setAnchorEl, setAccessory } = useContext(ModalContext)
	const { projectsCount, valuationPlanForecastSum } = useSelector((state: IState) => state.favorites.favoritesProject)
	const { projectsCount: projectsCountPurchases, maximumPriceSum } = useSelector(
		(state: IState) => state.favorites.favoritesPurchases
	)

	const { favoritesList, loading, toggleType } = useSelector((state: IState) => state.favorites)
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const loadingFavorites = useSelector((state: IState) => state.favorites.loading)
	const role = Number(window.localStorage.getItem('roleId'))

	const dispatch = useDispatch()
	const history = useHistory()

	const isShowFilter = useCallback(() => {
		dispatch(setFilterOpen(!isFilterOpen))
		toggleFilter(!isFilterOpen)
	}, [isFilterOpen])

	const sortProjects = (name: string, sortName: string, order?: boolean | undefined): void => {
		dispatch(sortFavoritesProjects(name, sortName, order))
	}
	const sortPurchase = (name: string, sortName: string, order?: boolean | undefined): void => {
		dispatch(sortFavoritesPurchases(name, sortName, order))
	}

	const handlerFavoritesActions = useCallback((projectId: string, action: 'DELETE' | 'POST', project: any) => {
		dispatch(actionFavoriteCommonProjects(projectId, action, project))
	}, [])

	const handleMenuItemClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			setAnchorEl!(event.currentTarget)
			setAccessory!(event.currentTarget.dataset.step!)
		},
		[setAnchorEl]
	)

	const handlerLinkClick = useCallback(
		(id: ReactText) => {
			window.open(`https://lk.zakupki.gov.ru/223/purchase/private/purchase/notice-info/details.html?noticeInfoId=${id}`)
		},
		[toggleType]
	)

	const getRegionProject = (firstRegionId: string) => {
		let regionIdx = regions.findIndex((region: IRegion) => region.id === firstRegionId)
		return regions[regionIdx]?.name
	}

	const filterFavoriteId = useCallback((favoritesList: any) => favoritesList.map((item: any) => item.id), [
		toggleType,
		favoritesList,
	])

	const handlerDeleteFavoritesProjects = useCallback(() => {
		const projectId = filterFavoriteId(favoritesList)

		dispatch(deleteFavoritesProjects(projectId, toggleType))
	}, [toggleType, favoritesList])

	const buttons = {
		PrimaryBtn: {
			handler: () => handlerDeleteFavoritesProjects(),
			value: 'Удалить',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl!(null!)
			},
			value: 'Отмена',
		},
	}

	const handleFavorites = useCallback(
		event => {
			if (favoritesList) {
				setAnchorEl && setAnchorEl(event.currentTarget)
			} else {
				dispatch(
					showNotify({
						type: 'warning',
						message: 'В избранном нет проектов',
					})
				)
			}
		},
		[anchorEl, favoritesList]
	)

	const generateTextForAlert = useCallback(
		(favoritesList: any) => {
			if (!favoritesList) return
			if (favoritesList.length >= 100) {
				return (
					<>
						Из избранного будет удалено<strong> не более 100 проектов</strong>
					</>
				)
			}
			return <>Из избранного будет удалено : {favoritesList.length} шт.</>
		},
		[anchorEl, favoritesList]
	)

	useEffect(() => {
		setAnchorEl && !loadingFavorites && setAnchorEl(null)
	}, [loadingFavorites])

	useEffect(() => {
		if (toggleType === ProjectType.PROJECTS) {
			history.push('/favorites-common-projects')
		}

		dispatch(getFavoritesProjects(toggleType))
	}, [toggleType])

	const routeMatch = useRouteMatch()
	const url: string = routeMatch.path.slice(1)

	useEffect(() => {
		if (
			history.location.pathname === '/favorites-common-projects' ||
			history.location.pathname === '/favorites-common-projects/table'
		) {
			const params = {
				favoritesOnly: 1,
				with: [
					'resultsCnt',
					'valuationOfferForecastSum',
					'valuationPlanForecastSum',
					'projects',
					'uncInfo',
					'uncTechnicalSpecifications',
					'isFavorite',
				],
			}

			//@ts-ignore
			dispatch(setCallbacks(getCommonProjects, favoritesApi, setFavoritesProjects, setLoadingFavorites))
			dispatch(setFilterSortParams(params, url))
			dispatch(filtersRequest(url))
			ym('hit', '/favorites-common-projects')
		} else if (
			history.location.pathname === '/favorites-purchases' ||
			history.location.pathname === '/favorites-purchases/table'
		) {
			const params = {
				favoritesOnly: 1,
				with: [
					'resultsCnt',
					'isFavorite',
					'purchases',
					'maximumPriceSum',
					'valuationOfferForecastSum',
					'valuationPlanForecastSum',
					'projects',
					'uncInfo',
					'uncTechnicalSpecifications',
				],
			}
			//@ts-ignore
			dispatch(setCallbacks(getCommonProjects, getPurchasesListByParams, setFavoritesProjects, setLoadingFavorites))
			dispatch(setFilterSortParams(params, url))
			dispatch(filtersRequest(url))
		}
	}, [url])

	return (
		<div className=' projects-container'>
			<FilterContainer />
			<div data-wrapper-animate className='projects-wrapper'>
				<FilterHeader
					editToggleType={(toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES) =>
						dispatch(editToggleType(toggleType))
					}
					toggleType={toggleType}
					sort={
						history.location.pathname === '/favorites-common-projects' || '/favorites-common-projects/table'
							? sortProjects
							: sortPurchase
					}
					sortList={
						history.location.pathname === '/favorites-common-projects' || '/favorites-common-projects/table'
							? sortListFavorites
							: PurchaseSortList
					}
					projectsCount={toggleType === ProjectType.PURCHASES ? projectsCountPurchases : projectsCount}
					planSum={
						history.location.pathname === '/favorites-common-projects' || '/favorites-common-projects/table'
							? valuationPlanForecastSum
							: maximumPriceSum
					}
					types={types}
					isShowFilter={isShowFilter}
					handleFavorites={handleFavorites}
					isFavorite={true}
				/>
				<div className='project__list'>
					{
					favoritesList && favoritesList.length > 0 ? (
						history.location.pathname === '/favorites-common-projects/table' ? (
							<FavoritesProjectsTableContainer projects={favoritesList} groups={groups}
								company={company}
								getRegionProject={getRegionProject}
								/>
						) : (
							history.location.pathname === '/favorites-purchases/table' ? (
								
								<FavoritesTableContainer favoritesList={favoritesList} groups={groups}
								company={company}
								regions={regions}

								/>
							) : (
										favoritesList.map((item: any) => {
							return (
								<Item
									key={item.id}
									groups={groups}
									company={company}
									regions={regions}
									project={item}
									toggleType={toggleType}
									region={getRegionProject(item.firstRegionId)}
									classIcon={
										history.location.pathname === '/favorites-common-projects' || '/favorites-common-projects/table'
											? 'common-project-icon'
											: 'purchase-icon'
									}
									handlerFavoritesActions={handlerFavoritesActions}
									handlerLinkClick={handlerLinkClick}
								/>
							)
						})
					
							)
						)
					) : 
					
					loading ? (
						<Loader />
					) : role && role === 999 ? (
						<div className={'flex justify-content-center mt-16'}>
							Здесь будут отображаться избранные проекты и закупки после приобретения тарифа
						</div>
					) : (
						<div className={'flex justify-content-center mt-16'}>
							{`Нет ${
								history.location.pathname === '/favorites-common-projects' || '/favorites-common-projects/table'
									? 'проектов'
									: 'закупок'
							} в избранном`}
						</div>
					)}
					{(anchorEl?.dataset.step === StepType.DESIGN_WORK || anchorEl?.dataset.step === StepType.BUILD_WORK) && (
						<CostWorksModalContainer />
					)}
				</div>

				{anchorEl?.dataset.popup === 'costWorks' && (
					<DropDown
						anchorEl={anchorEl!}
						setAnchorEl={setAnchorEl!}
						classes='cost-works'
						style={{ width: 180 }}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						transformOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<>
							<p className='drop-down-shrt-txt'>{popupContent.shrtTxt}</p>
							{popupContent.items.map(item => (
								<div key={item.step} className='drop-down-item' data-step={item.step} onClick={handleMenuItemClick}>
									{item.itemTxt}
								</div>
							))}
						</>
					</DropDown>
				)}
			</div>
			{anchorEl?.dataset.favorites && (
				<Modal
					widthWindow={'400px'}
					buttonOptions={buttons}
					setAnchorEl={setAnchorEl!}
					anchorEl={anchorEl}
					loader={loadingFavorites}
				>
					<Alert severity={favoritesList.length >= 100 ? 'warning' : 'info'}>
						{generateTextForAlert(favoritesList)}
					</Alert>
				</Modal>
			)}
		</div>
	)
}

export { FavoritesContainer }
