import React, { FC, useEffect } from 'react'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { ISelectTableTbodyProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { BodyTableData, IState } from '@root-gipro/store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterConfigures } from '@root-gipro/modules/filter/store/actions'

type UncTableRow = {
	x: number;
	y: number;
	name: string;
	uncCellId?: string;
  };
  
  type UncTableBody = UncTableRow[][];
  

export const SelectTableTbody: FC<ISelectTableTbodyProps> = ({
	uncTableData,
	handlerAddUnc,
	filterSide,
	selectedCells,
	setSelectedCells,
	setDataUncForFilter,
}) => {
	const dispatch = useDispatch()
	useEffect(() => {
		setSelectedCells && setSelectedCells([])
	}, [uncTableData])
	

	return (
		<tbody>
			{uncTableData &&
				uncTableData.body &&
				uncTableData.body?.map((elem: BodyTableData[], ind: number) => {

					return (
						<tr key={ind}>
							{elem.map((td, index) => {
								if (td?.class === 'invis') return null

								const isSelected = selectedCells?.some(
									cell => cell.x === index && cell.y === ind && cell.id === td.uncCellId
								)
								return (
									<td
										className={`${
											td.uncCellId && !uncTableData.interpolation && uncTableData.active ? 'active_unc_td' : ''
										} ${isSelected ? 'selected-cell' : ''}`}
										key={`${td.x}_${td.y}`}
										onClick={() => {
											if (filterSide && td.uncCellId) {
												const cellsWithSameY = uncTableData.body?.flatMap((row, rowIndex) =>
													row.map((cell, cellIndex) => ({ ...cell, x: cellIndex, y: rowIndex }))
												)

												const selectedCellsForY = [
													...(cellsWithSameY?.filter((cell: { x: number; y: number; uncCellId?: string }) => {
														if (cell.uncCellId) {
															return cell.y === ind && cell.uncCellId === td.uncCellId
														} else {
															return cell.y === ind && !cell.uncCellId
														}
													}) || []),
													{ x: index, y: ind, id: td.uncCellId },
												]
												if (setSelectedCells) {
													if (isSelected) {
														setSelectedCells([])
													} else {
														setSelectedCells(selectedCellsForY)
													}
												}

												if (setDataUncForFilter) {
													setDataUncForFilter({ id: td?.uncCellId, x: td.x })
												}
											} else {
												td.uncCellId && handlerAddUnc(td.uncCellId, uncTableData.interpolation, uncTableData.active)
											}
											dispatch(setFilterConfigures({ id: '', x: '' }))
										}}
									>
										{td.uncCellId && !uncTableData.interpolation && uncTableData.active
											? prettyCost(parseFloat(td.name))
											: td.name}
									</td>
								)
							})}
						</tr>
					)
				})}
		</tbody>
	)
}
