import React, { ChangeEvent, useEffect, useState } from 'react'
import { fade, IconButton, makeStyles } from '@material-ui/core'
import { FilterItemInputProps } from '@root-gipro/shared/Filter/models/filter-item-input.props'
import { Input } from '@root-gipro/shared/FormElements'

// import TextField from '@material-ui/core/TextField'
import TextField from '@material-ui/core/TextField'

const FilterItemInput: React.FC<FilterItemInputProps> = ({
	value,
	setValue,
	inputName,
	labelName,
	iconName,
	disabled,
	handleBlur,
	clearState,
}) => {
	const [inputValue, setInputValue] = useState(value || '')

	useEffect(() => {
		if (value !== inputValue) {
			setInputValue(value || '')
		}
	}, [value])

	const resetInput = () => {
		if (clearState) clearState()
		setValue(inputName, '')
	}

	const onBlur = () => {
		if (handleBlur) {
			setValue(inputName, inputValue.trim())
			handleBlur()
		}
	}

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)

	// inputs styles
	const useStylesReddit = makeStyles(theme => ({
		root: {
			border: '1px solid #e2e2e1',
			overflow: 'hidden',
			borderRadius: 4,
			paddingRight: 0,
			height: 'auto',
			backgroundColor: '#fff',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			'&:hover': {
				backgroundColor: '#fff',
			},
			'&$focused': {
				backgroundColor: '#fff',
				borderColor: theme.palette.primary.main,
			},
			'& .MuiFilledInput-inputMarginDense': {
				paddingTop: '23px !important',
				paddingBottom: '6px !important',
			},
			'& .MuiIconButton-root': {
				padding: '6px',
			},
		},
		focused: {},
	}))
	const classes = useStylesReddit()

	return (
		<div className='aside-filter__input'>
			<TextField
				value={inputValue}
				onChange={handleInput}
				name={inputName}
				// placeholder={labelName}
				label={labelName}
				margin='dense'
				size='small'
				variant='filled'
				// hiddenLabel
				InputProps={{
					classes,
					disableUnderline: true,
					endAdornment: inputValue && (
						<IconButton type='button' onClick={resetInput} className='reset-icon'>
							<span className='close-icon' />
						</IconButton>
					),
				}}
				color='primary'
				fullWidth
				disabled={disabled}
				onBlur={onBlur}
			/>
			{/* {iconName && !value && (
				<IconButton type='button' className='props-icon' disabled>
					<span className={`${iconName}-icon`} />
				</IconButton>
			)} */}

			{/* <Input
				labelName={labelName}
				inputName={inputName}
				handleInput={handleInput}
				handleBlur={onBlur}
				inputValue={inputValue}
				type='text'
				disabled={disabled}
				classes='full-width'
			>
				{value && (
					<IconButton type='button' onClick={resetInput} className='reset-icon'>
						<span className='close-icon' />
					</IconButton>
				)}
				{iconName && !value && (
					<IconButton type='button' className='props-icon' disabled>
						<span className={`${iconName}-icon`} />
					</IconButton>
				)}
			</Input> */}
		</div>
	)
}

export default FilterItemInput
