import React, { FC, ReactText, useEffect, useState } from 'react'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import cn from 'classnames'
import { nanoid } from 'nanoid'
import { useDispatch, useSelector } from 'react-redux'
import { setCalcCountMember, setReportType } from '@root-gipro/modules/tariffs/store/actions'
import { IState } from '@root-gipro/store/interfaces'

interface SubscriptionProps {
	currenDiscount: ReactText | null
	discountMap: any // todo any
	handleClickSubscribe(month: string): void
	cardId: string
}

const Subscription: FC<SubscriptionProps> = ({ handleClickSubscribe, discountMap, currenDiscount, cardId }) => {
	const dispatch = useDispatch()
	const calcUnlimit = useSelector((state: IState) => state.tariffs.calcUnlimit)
	const reportType = useSelector((state: IState) => state.tariffs.reportType)

	const handleClickReportsType = (type: string) => {
		dispatch(setReportType(type))
	}

	const handleClickCalcCountMember = (type: string) => {
		type == 'limit' ? dispatch(setCalcCountMember(false)) : dispatch(setCalcCountMember(true))
	}

	return (
		<div className={styles.subscription}>
			<div>
				{cardId == '2' ? (
					<React.Fragment>
						<div>Количество проектов</div>
						<select className={styles.select} onChange={e => handleClickCalcCountMember(e.target.value)}>
							<option selected={!calcUnlimit} value='limit'>
								от 1 до 5
							</option>
							<option selected={calcUnlimit} value='unlimit'>
								не ограничено
							</option>
						</select>
					</React.Fragment>
				) : null}
				{cardId != '3' ? (
					<div className={styles.timing}>
						{discountMap.map((item: string[]) => (
							<div
								key={nanoid()}
								onClick={() => handleClickSubscribe(item[0])}
								className={cn(styles.time, {
									[styles.timeActive]: item[0] === currenDiscount,
								})}
							>
								{item[0]}
							</div>
						))}
					</div>
				) : (
					<div>
						<div>Варианты отчета</div>
						<select className={styles.select} value={reportType} onChange={e => handleClickReportsType(e.target.value)}>
							<option value='standard'>Стандартный</option>
							<option value='full'>Расширенный</option>
						</select>
						<div>
							<div>
								<span className={styles.descriptionsInfoItemTitle}>Стандартный отчет включает в себя:</span> Аналитика
								по 90 заказчикам и 80 000+ проектов
							</div>
							<div>
								<span className={styles.descriptionsInfoItemTitle}>Расширенный отчет включает в себя:</span> Аналитика
								по 450 заказчикам
							</div>
						</div>
					</div>
				)}
				{!currenDiscount && <div className={styles.discount}>Скидка {currenDiscount}%</div>}
			</div>
		</div>
	)
}

export { Subscription }
