import React from 'react';

import styled from 'styled-components';

type Props = {
  name?: string | undefined;
  src?: string;
  color?: string;
  hoverColor?: string;
  width?: string;
  height?: string;
  cursor?: string;
  onClick?: (e: React.MouseEvent) => void;
  style?: object;
  alt?: string;
  title?: string;
};

const IconStyled = styled.img`
  display: inline-block;
  border-radius: inherit;
  cursor: 'default';
  font-size: 0;
  color: '#ccc';
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  background-image: url(src);
  svg {
    fill: currentColor;
  }
`;

const Icon: React.FC<Props> = ({
  name,
  src = '',
  color,
  title,
  hoverColor,
  width,
  height,
  cursor,
  style,
  alt,
  onClick,
}) => {
  let path = '';
  if (src) {
    path = src;
  } else if (name && !src) {
    // eslint-disable-next-line
    path = require(`./imgs/${name}.svg`);
  } else {
    path = require(`./imgs/burger.svg`);
  }

  return (
    <IconStyled
      style={style}
      src={path}
      alt={alt}
      title={title}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

export default Icon;
