export enum DevelopmentSchemesAndProgramsType {
    SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS',
    GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS = 'GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS',

    SET_FILTER_SHORT_TITLES = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER_SHORT_TITLES',
    SET_FILTER_INDUSTRIES = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER_INDUSTRIES',
    SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_TOTAL_PROGRAMS = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_TOTAL_PROGRAMS',
    SET_FILTER_SINCE_DATES = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER_SINCE_DATES',
    SET_FILTER_PUBLICATION_DATES = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER_PUBLICATION_DATES',
    SET_FILTER_VALUE = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER_VALUE',
    RESET_FILTER = 'RESET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_FILTER',
    SET_ORDER = 'SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_ORDER',
}

interface GetDevelopmentSchemesAndPrograms {
    type: DevelopmentSchemesAndProgramsType.GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS,
    query: string
}

interface SetDevelopmentSchemesAndPrograms {
    type: DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS,
    programs: []
}

interface SetDevelopmentSchemesAndProgramsOrder {
    type: DevelopmentSchemesAndProgramsType.SET_ORDER,
    order: {
        by: 'since' | 'title',
        direction: 'ASC' | 'DESC'
    }
}

interface SetDevelopmentSchemesAndProgramsTotalPrograms {
    type: DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_TOTAL_PROGRAMS,
    total: number
}

interface ResetFilter {
    type: DevelopmentSchemesAndProgramsType.RESET_FILTER
}

interface SetFilterShortTitles {
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_SHORT_TITLES,
    titles: string[]
}

interface SetFilterIndustries {
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_INDUSTRIES,
    industries: string[]
}

interface SetFilterSinceDates {
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_SINCE_DATES,
    sinceDates: string[]
}

interface SetFilterPublicationDates {
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_PUBLICATION_DATES,
    publicationDates: string[]
}

interface SetFilterValue {
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_VALUE,
    key: 'shortTitle' | 'publishedAt' | 'since' | 'startedAt' | 'finishedAt' | 'industry',
    value: string
}

export type DevelopmentSchemesAndProgramsTypes =
    | SetDevelopmentSchemesAndPrograms
    | GetDevelopmentSchemesAndPrograms
    | SetFilterShortTitles
    | SetFilterSinceDates
    | SetFilterPublicationDates
    | SetFilterValue
    | ResetFilter
    | SetDevelopmentSchemesAndProgramsTotalPrograms
    | SetDevelopmentSchemesAndProgramsOrder
    | SetFilterIndustries
