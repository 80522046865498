import React, { useEffect, useState } from 'react'
import { Avatar, MenuItem } from '@material-ui/core'
import { IHeaderAvatarProps } from '@root-gipro/store/interfaces/header'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import styles from '@root-gipro/modules/header/styles/HeaderAvatar.module.scss'
import { roleTitleById } from '@root-gipro/modules/admin/models/roles'

const HeaderAvatar: React.FC<IHeaderAvatarProps> = ({ logout }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const [userInfo, setUserInfo] = useState<string>('')

	useEffect(() => {
		const roleId = localStorage.getItem('roleId')
		const roleString = roleId && roleTitleById(Number(roleId)) ? '(' + roleTitleById(Number(roleId)) + ')' : ''
		const firstname = localStorage.getItem('firstname')
		const lastname = localStorage.getItem('lastname')
		setUserInfo(`${lastname} ${firstname} ${roleString}`)
	}, [localStorage.getItem('firstname'), localStorage.getItem('lastname'), localStorage.getItem('roleId')])

	return (
		<div className={styles.user}>
			{localStorage.getItem('firstname') && localStorage.getItem('lastname') ? (
				<div className={styles.username}>{userInfo}</div>
			) : null}
			<div className={styles.avatar}>
				<Avatar src={'/assets/images/svg/default-avatar.svg'} onClick={handleClick} />
				<DropDown
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					anchorOrigin={{
						vertical: 43,
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<MenuItem onClick={() => logout()}>Выйти</MenuItem>
				</DropDown>
			</div>
		</div>
	)
}

export default HeaderAvatar
