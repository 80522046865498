import React, { useCallback, useEffect, useState } from 'react'
import { FormControl, MenuItem, Modal, Select, makeStyles } from '@material-ui/core/'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { Backdrop } from '@material-ui/core/'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import {
	editPresetFilters,
	savePresetFilters,
	setOpenPresetFilterModal,
	setSelectedPresetFilter,
} from '@root-gipro/modules/filter/store/actions'
import './PresetFiltersModal.scss'
import { showNotify } from '@root-gipro/modules/notify/store/actions'

const useStyles = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		backgroundColor: '#fff',

		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
	focused: {},
}))
const PresetFiltersModal: React.FC<{ showFilterState: any }> = ({ showFilterState }) => {
	const classes = useStyles()

	const { isOpenPresetFilterModal, dataPresetFilter, modePresetFilterModal, isSelectIdPresetFilter } = useSelector(
		(state: IState) => state.filter
	)

	const dispatch = useDispatch()
	const [name, setName] = useState<string>('')
	const [selectedId, setSelectedId] = useState<string | null>('')

	const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedId(event.target.value as string)
		const selectedItem = await dataPresetFilter.find((item: any) => item.id == event.target.value)

		if (selectedItem) {
			setName(selectedItem.name)
		}
	}

	const cancelHandler = useCallback(() => {
		dispatch(setOpenPresetFilterModal(false, null))
		setName('')
		setSelectedId('')
		dispatch(setSelectedPresetFilter(null))
	}, [dispatch])

	const editHandler = () => {
		if (selectedId) {
			dispatch(editPresetFilters(selectedId, name, showFilterState))
			cancelHandler()
		}
	}

	const saveHandler = () => {
		let isValueFound = false
		let isFiltersExist = Object.keys(showFilterState).length === 0

		if (dataPresetFilter.length > 9) {
			dispatch(
				showNotify({
					type: 'warning',
					message: 'Превышен лимит добавления предустановленных фильтров',
				})
			)
			return
		}

		if (isFiltersExist) {
			dispatch(
				showNotify({
					type: 'warning',
					message: 'Вы не можете сохранить пустой фильтр. Выберете хотя бы один фильтр',
				})
			)
			return
		}

		Object.entries(dataPresetFilter).forEach(([key, item]) => {
			if (item.name === name) {
				isValueFound = true
				dispatch(
					showNotify({
						type: 'warning',
						message: `фильтр с наименованием ${name} уже существует, укажите другое имя.`,
					})
				)
			}
		})

		if (!isValueFound) {
			dispatch(savePresetFilters(name, showFilterState))
			cancelHandler()
		}
	}

	const changeHandler = (e: string) => {
		setName(e)
	}

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				cancelHandler()
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [cancelHandler])

	useEffect(() => {
		if (isSelectIdPresetFilter) {
			setSelectedId(isSelectIdPresetFilter?.id)
			setName(isSelectIdPresetFilter?.name)
		}
	}, [isSelectIdPresetFilter?.id])

	return (
		<Modal
			className='modal_preset_filter'
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={isOpenPresetFilterModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<div className='modal_preset_filter__content'>
				<h3 className='modal_preset_filter__content__title'>
					{modePresetFilterModal == 'edit' ? 'Переименовать фильтр' : 'Создание фильтра'}
				</h3>
				{modePresetFilterModal === 'edit' ? (
					<div className='modal_preset_filter__content__block'>
						<label>Выберите фильтр</label>
						<FormControl variant='outlined' classes={classes}>
							<Select value={selectedId} onChange={handleChange} label='name' placeholder='Текущее наименование'>
								{dataPresetFilter?.map((item: any) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>
				) : null}
				<div className='modal_preset_filter__content__block'>
					<label>Наименование фильтра</label>
					<input
						placeholder='Новое наименование'
						className='modal_preset_filter__content__block__input'
						type='text'
						name=''
						id=''
						value={name}
						onChange={e => {
							changeHandler(e.target.value)
						}}
					/>
				</div>
				<div className='modal_preset_filter__content__btns'>
					<SecondaryBtn variant='contained' onClick={cancelHandler} className='fullWidth'>
						Отмена
					</SecondaryBtn>
					<PrimaryBtn
						variant='contained'
						onClick={modePresetFilterModal === 'edit' ? editHandler : saveHandler}
						className='fullWidth'
						disabled={modePresetFilterModal === 'save' ? !name : !selectedId || !name}
					>
						Применить
					</PrimaryBtn>
				</div>
			</div>
		</Modal>
	)
}

export default PresetFiltersModal
