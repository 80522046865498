import { Chip, Tooltip } from '@material-ui/core'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	filtersRequest,
	refreshFilterState,
	setCallbacks,
	setFilterSortParams,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { getPurchasesListByParams } from '@root-gipro/modules/purchases/api/purchases'
import { getPurchasesList, refreshList, toggleLoadingPurchaseList } from '@root-gipro/modules/purchases/store/actions'
import { formatedDates } from '@root-gipro/modules/userProjects/utils/helpers'

import { IState } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const FilterCloudPurchases = () => {
	const location = useLocation()
	const searchUrl: string = location.search

	const url: string = location.pathname.slice(1)
	const dispatch = useDispatch()

	let { rgStatuses } = useSelector((state: IState) => state.public)

	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	const convertPlug = (fieldName: string, value: any) => (
		fieldName: string,
		value: any
	): { fieldName: string; value: any } => ({ fieldName, value })

	const { filter } = useSelector((state: IState) => state)
	const publicInfo = useSelector((state: IState) => state.public)
	const regions = useSelector((state: IState) => state.public.regions)


	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'purchases/table'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'common-projects/table'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState } = useSelector((state: IState) => currentFilter)
	const [localResultFilterState, setLocalResultFilterState] = useState<{ [key: string]: string | string[] | number }>(
		{}
	)

	const params = {
		limit: 10,
		with: ['resultsCnt', 'initialSumSum', 'maximumPriceSum'],
	}
	const [refreshCloud, setRefreshCloud] = useState<boolean>(false)

	useEffect(() => {
		if (refreshCloud) {
			setLocalResultFilterState(resultFilterState)
		}
		setRefreshCloud(false)
	}, [resultFilterState])

	useEffect(()=>{
		if(resultFilterState?.region_code&& +resultFilterState?.region_code){
			const region = regions?.find((item:any)=>item.code ==resultFilterState?.region_code)
			const filterRes = Object.fromEntries(Object.entries(resultFilterState).map((it:any)=>{
				if(it[1]==region?.code){
					it[1] =[region?.name]
					return it
				}
				 return it
				
				}))
			setLocalResultFilterState(filterRes)
		}
		else if(resultFilterState?.region_code&&Array.isArray(resultFilterState?.region_code)){
		 	// @ts-ignore
			const region = regions?.filter((item:any)=>resultFilterState?.region_code?.find((code:any)=>item.code == code))
			if(region?.length){
				const filterRes = {...resultFilterState,region_code:region.map((reg:any)=>reg.name)}
				setLocalResultFilterState(()=>filterRes)
			}else{
				setLocalResultFilterState(resultFilterState)
			}
		
		}

	},[resultFilterState,searchUrl])

	const deleteChip = (fieldName: string, value: any) => {
		setRefreshCloud(true)
		dispatch(refreshFilterState(fieldName, value, url, convertPlug(fieldName, value)))
		dispatch(setCallbacks(getPurchasesList, getPurchasesListByParams, refreshList, toggleLoadingPurchaseList))
		dispatch(setFilterSortParams(params, url))

		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))

		setLocalResultFilterState(prev => {
			const updatedPrev = { ...prev }
			delete updatedPrev[fieldName]
			return updatedPrev
		})
	}

	const deleteChipWithArr = (fieldName: string, currValue: string) => {
		setRefreshCloud(true)
		// @ts-ignore
		const newVal = resultFilterState[fieldName]?.filter(el => {
			return el !== currValue
		})
		dispatch(
			refreshFilterState(
				fieldName,
				newVal,
				url,
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)

		dispatch(setCallbacks(getPurchasesList, getPurchasesListByParams, refreshList, toggleLoadingPurchaseList))
		dispatch(setFilterSortParams(params, url))

		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))

		setLocalResultFilterState(resultFilterState)
	}

	const deleteChipRange = (fieldNameMin: any, fieldNameMax: any) => {
		if (fieldNameMin === null || fieldNameMax === null) {
			console.error('Field names cannot be null')
			return
		}

		setRefreshCloud(true)
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMin, null, url, convertPlug(fieldNameMin, null)))
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMax, null, url, convertPlug(fieldNameMax, null)))

		if (dispatch === null) {
			console.error('Dispatch cannot be null')
			return
		}

		dispatch(setCallbacks(getPurchasesList, getPurchasesListByParams, refreshList, toggleLoadingPurchaseList))
		dispatch(setFilterSortParams(params, url))

		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))
	}
	return (
		<>
			{localResultFilterState.registration_number && (
				<Tooltip title='Идентификатор'>
					<Chip
						onDelete={() => deleteChip('registration_number', '')}
						label={localResultFilterState.registration_number}
					/>
				</Tooltip>
			)}
			{localResultFilterState.subject ? (
				<Tooltip title='Наименование'>
					<Chip onDelete={() => deleteChip('subject', '')} label={localResultFilterState.subject} />
				</Tooltip>
			) : null}
			{localResultFilterState.existsPIR ? (
				<Chip onDelete={() => deleteChip('existsPIR', false)} label={'Проектно-изыскательские работы'} />
			) : null}

			{localResultFilterState.type
				? //@ts-ignore
				  localResultFilterState.type.map((item: string) => (
						<Tooltip title='Способ определения поставщика (подрядчика, исполнителя)' key={item}>
							<Chip onDelete={() => deleteChipWithArr('type', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.kind
				? //@ts-ignore
				  localResultFilterState.kind.map((item: string) => (
						<Tooltip title='Тип закупки' key={item}>
							<Chip onDelete={() => deleteChipWithArr('kind', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.status
				? //@ts-ignore
				  localResultFilterState.status.map((item: string) => (
						<Tooltip title='Статус закупки' key={item}>
							<Chip onDelete={() => deleteChipWithArr('status', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.okpd2
				? //@ts-ignore
				  localResultFilterState.okpd2.map((item: string) => (
						<Tooltip title='ОКПД' key={item}>
							<Chip onDelete={() => deleteChipWithArr('okpd2', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.okved2
				? //@ts-ignore
				  localResultFilterState.okved2.map((item: string) => (
						<Tooltip title='ОКВЭД' key={item}>
							<Chip onDelete={() => deleteChipWithArr('okved2', item)} label={item} />
						</Tooltip>
				  ))
				: null}

			{localResultFilterState.company_id
				? //@ts-ignore
				  localResultFilterState.company_id.map((item: string) => (
						<Tooltip title='Организация' key={item}>
							<Chip onDelete={() => deleteChipWithArr('company_id', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.region_code
				? //@ts-ignore
				  localResultFilterState.region_code.map((item: string) => (
						<Tooltip title='Регион' key={item}>
							<Chip onDelete={() => deleteChipWithArr('region_code', item)} label={item} />
						</Tooltip>
				  ))
				: null}

			{localResultFilterState.publish_date
				? //@ts-ignore
				  localResultFilterState.publish_date.map((item: string) => (
						<Tooltip title='Дата публикации закупки на площадке' key={item}>
							<Chip onDelete={() => deleteChipWithArr('publish_date', item)} label={item} />
						</Tooltip>
				  ))
				: null}

			{
				//@ts-ignore
				localResultFilterState['request_receiving_end_date.min'] &&
				localResultFilterState['request_receiving_end_date.max'] ? (
					//@ts-ignore
					<Tooltip title='Дата окончания подачи заявок'>
						{
							//@ts-ignore
							localResultFilterState['request_receiving_end_date.min'] !== `${2017}-01-01` ||
							localResultFilterState['request_receiving_end_date.max'] !== `${2055}-12-31` ? (
								<Chip
									onDelete={() => deleteChipRange('request_receiving_end_date.min', 'request_receiving_end_date.max')}
									label={
										formatedDates(localResultFilterState['request_receiving_end_date.min'] as string) +
										' - ' +
										formatedDates(localResultFilterState['request_receiving_end_date.max'] as string)
									}
								/>
							) : (
								<></>
							)
						}
					</Tooltip>
				) : null
			}

			{
				//@ts-ignore
				(localResultFilterState['price.min'] || localResultFilterState['price.min'] === 0) &&
				localResultFilterState['price.max'] ? (
					//@ts-ignore
					<Tooltip title='Начальная максимальная цена, млн руб. с НДС'>
						<Chip
							onDelete={() => deleteChipRange('price.min', 'price.max')}
							label={
								prettyCost(Number(localResultFilterState['price.min']) / 10 ** 6) +
								' млн.₽' +
								' - ' +
								prettyCost(Number(localResultFilterState['price.max']) / 10 ** 6) +
								' млн.₽'
							}
						/>
					</Tooltip>
				) : null
			}
		</>
	)
}

export default FilterCloudPurchases
