import React, { useEffect, useCallback, ReactText, useState, FC, useContext } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { PurchaseList } from '@root-gipro/modules/purchases/containers'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import {
	actionFavoritePurchases,
	getAllFavoritePurchasesAction,
	getPurchasesList,
	refreshList,
	toggleLoadingPurchaseList,
} from '@root-gipro/modules/purchases/store/actions'
import { setFilterOpen } from '@root-gipro/store/actions/maket'
import {
	setCallbacks,
	setFilterSortParams,
	filtersRequest,
	refreshFilterState,
} from '@root-gipro/modules/filter/store/actions'
import { getPurchasesListByParams } from '@root-gipro/modules/purchases/api/purchases'
import FilterHeader from '@root-gipro/shared/FilterHeader/FilterHeader'
import { changePurchasesType, sortPurchaseList } from '@root-gipro/modules/purchases/store/actions'
import { PurchaseSortList } from '@root-gipro/modules/purchases/models/consts/purchase-sort-list.const'

import store from '@root-gipro/store'
import { setInitFilterState } from '@root-gipro/modules/filter/store/actions'
import { encode } from '@root-gipro/utils/helpers/queryString'
import { toggleFilter } from '@root-gipro/modules/filter/utils/toggle-filter'
import '@root-gipro/modules/commonProjects/styles/Projects.scss'
import FilterContainer from '../filter/FilterContainer'
import { getPurchasesMethods } from '@root-gipro/store/actions'
import { getPurchasesStages } from '@root-gipro/store/actions'
import { getPurchasesOkpd } from '@root-gipro/store/actions'
import { getPurchasesOkved } from '@root-gipro/store/actions'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { Alert } from '@material-ui/lab'
import { ModalContext } from '@root-gipro/context/modal-context'
import { PurchasesTypeEnum } from './models/enums/purchases-type.enum'
import ym from 'react-yandex-metrika'
import { revertFilterValue } from '../filter/helpers/filter-state-convert'
import PurchaseTable from './components/PurchaseTable'

const PurchasesTableContainer: FC = () => {
	const list = useSelector((state: IState) => state.purchases.list)
	const { state, sortParams, purchasesType, count, mSum, iSum, IDs } = useSelector((state: IState) => state.purchases)
	const { isFavorite } = useSelector((state: IState) => state.purchases)
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const publicInfo = useSelector((state: IState) => state.public)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const loadingFavorites = useSelector((state: IState) => state.favorites.loading)
	const [details, setDetails] = useState<ReactText>('')
	const routeMatch = useRouteMatch()
	const dispatch = useDispatch()
	const url: string = routeMatch.path.slice(1)
	const history = useHistory()
	const location = useLocation()

	useEffect(() => {
		const params = {
			limit: 10,
			with: ['resultsCnt,initialSumSum,maximumPriceSum'],
		}
		dispatch(setFilterSortParams(params, url))
		dispatch(setCallbacks(getPurchasesList, getPurchasesListByParams, refreshList, toggleLoadingPurchaseList))
		dispatch(getPurchasesMethods())
		dispatch(getPurchasesStages())
		dispatch(getPurchasesOkpd())
		dispatch(getPurchasesOkved())
		dispatch(filtersRequest(url))
		ym('hit', '/purchases')
	}, [])

	useEffect(() => {}, [sortParams])

	const sort = (name: string, sortName: string, order?: boolean) => dispatch(sortPurchaseList(name, sortName, order))
	const changePurchaseType = (type: PurchasesTypeEnum) => {
		const state = store.getState()
		const filterStatePrevious = state.filter.purchases.showFilterState
		let filterStateCurrent = {}

		Object.keys(filterStatePrevious).forEach(key => {
			if ([PurchasesTypeEnum.Plan, PurchasesTypeEnum.Notice].some(pType => key.includes(pType))) {
				filterStateCurrent = {
					...filterStateCurrent,
					[`${key.replace(`${state.purchases.purchasesType}`, type)}`]: filterStatePrevious[key],
				}
			}
		})
		dispatch(changePurchasesType(type))
		history.push({
			pathname: location.pathname,
			search: encode(filterStateCurrent),
		})
	}
	const types = [
		{
			title: 'План',
			value: 'plan',
		},
		{
			title: 'Извещения',
			value: 'notice',
		},
	]
	const isShowFilter = useCallback(() => {
		dispatch(setFilterOpen(!isFilterOpen))
		toggleFilter(!isFilterOpen)
	}, [isFilterOpen])

	const getProjectById = useCallback(
		(id: ReactText) => {
			history.push({
				pathname: `/purchases#${id}`,
			})
		},
		[history]
	)

	const handlerLinkClick = useCallback(
		(id: ReactText) => {
			window.open(`https://lk.zakupki.gov.ru/223/purchase/private/purchase/notice-info/details.html?noticeInfoId=${id}`)
		},
		[history]
	)

	const handlerFavoritesActions = useCallback((projectId: string, action: 'DELETE' | 'POST') => {
		dispatch(actionFavoritePurchases(projectId, action))
	}, [])

	const handleFavorites = useCallback(
		event => {
			// dispatch(refreshFilterState('favoritesOnly', '1', 'purchases'))
			dispatch(getAllFavoritePurchasesAction())
		},
		[anchorEl, IDs]
	)

	const buttons = {
		PrimaryBtn: {
			handler: () => dispatch(actionFavoritePurchases('multiple', 'POST')),
			value: 'Добавить',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl!(null!)
			},
			value: 'Отмена',
		},
	}

	const generateTextForAlert = useCallback(
		(IDs: string[] | null) => {
			if (!IDs) return
			if (IDs.length >= 100) {
				return (
					<>
						В избранные будет добавлено<strong> не более 100 проектов</strong>
					</>
				)
			}
			return <>В избранное будет добавлено проектов : {IDs.length} шт.</>
		},
		[anchorEl, IDs]
	)

	useEffect(() => {
		setAnchorEl && !loadingFavorites && setAnchorEl(null)
	}, [loadingFavorites])

	return (
		<div className='projects-container'>
			<FilterContainer />
			<div data-wrapper-animate className='projects-wrapper'>
				<FilterHeader
					sort={sort}
					sortList={PurchaseSortList}
					toggleType={purchasesType}
					editToggleType={changePurchaseType}
					projectsCount={count}
					offerSum={iSum}
					planSum={mSum}
					types={types}
					isShowFilter={isShowFilter}
					handleFavorites={handleFavorites}
					isFavorite={isFavorite}
				/>
				<PurchaseTable
					list={list}
					isLoadingInitList={state.isLoadingInitList || false}
					{...publicInfo}
				
				/>
			</div>
			{anchorEl?.dataset.favorites && IDs && (
				<Modal
					widthWindow={'400px'}
					buttonOptions={buttons}
					setAnchorEl={setAnchorEl!}
					anchorEl={anchorEl}
					loader={loadingFavorites}
				>
					<Alert severity={IDs.length >= 100 ? 'warning' : 'info'}>{generateTextForAlert(IDs)}</Alert>
				</Modal>
			)}
		</div>
	)
}

export default PurchasesTableContainer
