import { IDevelopmentSchemesAndProgramsProps } from "@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs"
import { DevelopmentSchemesAndProgramsType } from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.actions'
import store from '@root-gipro/store'

const getDevelopmentSchemesAndPrograms = () => ({
    type: DevelopmentSchemesAndProgramsType.GET_DEVELOPMENT_SCHEMES_AND_PROGRAMS,
    query: {
        ...store.getState().developmentSchemesAndPrograms.filter,
        ...store.getState().developmentSchemesAndPrograms.order
    }
})

const setDevelopmentSchemesAndPrograms = (programs: IDevelopmentSchemesAndProgramsProps[]) => ({
    type: DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS,
    programs,
})

const setDevelopmentSchemesAndProgramsOrder = (order: { by: 'title' | 'since' | 'startedAt' | 'finishedAt' | 'quantity', direction: 'ASC' | 'DESC' }) => ({
    type: DevelopmentSchemesAndProgramsType.SET_ORDER,
    order,
})

const setDevelopmentSchemesAndProgramsTotalPrograms = (total: number) => ({
    type: DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_TOTAL_PROGRAMS,
    total,
})

const setFilterShortTittles = (titles: string[]) => ({
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_SHORT_TITLES,
    titles,
})

const setFilterIndustries = (industries: string[]) => ({
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_INDUSTRIES,
    industries,
})

const setFilterSinceDates = (sinceDates: string[]) => ({
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_SINCE_DATES,
    sinceDates,
})

const setFilterPublicationDates = (publicationDates: string[]) => ({
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_PUBLICATION_DATES,
    publicationDates,
})

const setFilterValue = (
    value: string,
    key: 'shortTitle' | 'publishedAt' | 'since' | 'startedAt' | 'finishedAt' | 'industry'
) => ({
    type: DevelopmentSchemesAndProgramsType.SET_FILTER_VALUE,
    value,
    key
})

const resetFilter = () => ({
    type: DevelopmentSchemesAndProgramsType.RESET_FILTER
})

export {
    setDevelopmentSchemesAndPrograms,
    getDevelopmentSchemesAndPrograms,
    setFilterShortTittles,
    setFilterSinceDates,
    setFilterPublicationDates,
    setFilterValue,
    resetFilter,
    setDevelopmentSchemesAndProgramsTotalPrograms,
    setDevelopmentSchemesAndProgramsOrder,
    setFilterIndustries
}
