import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ruLocale from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'

import App from './components/App'
import { appTheme } from '@root-gipro/styles/themes'
import store from './store'

ReactDOM.render(
	<ThemeProvider theme={appTheme}>
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
			<Provider store={store}>
				<App />
			</Provider>
		</MuiPickersUtilsProvider>
	</ThemeProvider>,
	document.getElementById('root')
)
