import React, { FC } from 'react'
import { Tooltip } from '@material-ui/core'
import cn from 'classnames'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'

export type Item = {
	title: string
	textAlign: string
}

export interface TheadProps {
	theadData: Item[]
	gridTemplateColumns: string
}

const Thead: FC<TheadProps> = ({ gridTemplateColumns, theadData }) => {
	return (
		<div style={{ gridTemplateColumns: gridTemplateColumns }} className={cn(styles.thead, styles.grid)}>
			{theadData.map(item => (
				<Tooltip key={item.title} title={item.title} enterDelay={500} enterNextDelay={500} placement='top'>
					<div className={cn(styles.theadCell, styles[item.textAlign])}>{item.title}</div>
				</Tooltip>
			))}
		</div>
	)
}

export { Thead }
