import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ICommonProject } from '../../models/interfaces/common-projects.model';
import { ICompany, IGroup } from '@root-gipro/store/interfaces';
import TableBodyRowCommonProject from './TableBodyRowCommonProject';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
    },
    table: {
      minWidth: 650,
      width: "max-content",
    },
    headerCell: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    bodyCell: {
      textAlign: 'center',
      maxWidth: '200px', // Limit cell width
      whiteSpace: 'nowrap', // Prevent text from wrapping
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:hover':{
        backgroundColor: "#d4d4d480"
      }
    },
  })
);


interface ProjectsTableContainerProps {
  projects: ICommonProject[];
	toggleType: 'offer' | 'plan'
	getProjectById(id: string | number): void
	groups: IGroup[]
	company: ICompany[]
	handlerFavoritesActions(projectId: string, action?: string, project?: any): void
  getRegionProject:(id:string)=>string
}

const ProjectsTableContainer: React.FC<ProjectsTableContainerProps> = ({ projects, groups, company,getProjectById,getRegionProject }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>ID</TableCell>
            <TableCell className={classes.headerCell}>Идентификатор</TableCell>
            <TableCell className={classes.headerCell}>Компания</TableCell>
            <TableCell className={classes.headerCell}>Наименование</TableCell>
            <TableCell className={classes.headerCell}>Период реализации</TableCell>
            <TableCell className={classes.headerCell}>Регион поставки</TableCell>
            <TableCell className={classes.headerCell}>Оценка полной стоимости в прогн. ценах (с НДС) млн.р</TableCell>
            <TableCell className={classes.headerCell}>Публикация на сайте Минэнерго</TableCell>
            <TableCell className={classes.headerCell}>Полная сметная стоимость согласно ПСД (с НДС) млн.р</TableCell>
            <TableCell className={classes.headerCell}>Оценка полной стоимости по УНЦ в прогнозных ценах (с НДС) млн.р</TableCell>
            <TableCell className={classes.headerCell}>Остаток финансирования в прогнозных ценах (с НДС) млн.р</TableCell>
            <TableCell className={classes.headerCell}>Дата добавления карточки проекта</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((row) => (
            <TableBodyRowCommonProject region = {getRegionProject(row.firstRegionId)}company = {company.find((company: ICompany) => company.id === row.groupId)?.name || ''} row={row}  getProjectById={getProjectById}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsTableContainer;
