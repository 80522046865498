import { ICommonProject } from '@root-gipro/store/interfaces'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { formatDate, prettyCost } from '@root-gipro/utils/helpers/common'

export const cardRightItems = (project: ICommonProject) => [
	{
		title: 'Оценка полной стоимости в прогн. ценах (с НДС)',
		value: project.valuationPlanForecast ? addHTML(prettyCost(project.valuationPlanForecast / 10 ** 6)) : '0.00',
	},
	{
		title: 'Публикация на сайте Минэнерго',
		value: `${formatDate(project.publicationDate)}`,
	},
	{
		title: 'Полная сметная стоимость согласно ПСД (с НДС)',
		value: project.estimatedPlanCurrent ? addHTML(prettyCost(project.estimatedPlanCurrent / 10 ** 6)) : '0.00',
	},
]
