import { call, takeEvery, put } from 'redux-saga/effects'
import { TariffsType } from '@root-gipro/modules/tariffs/models/interfaces/tariffs.actions'
import { modeURL, authorizeApi} from '@root-gipro/store/api'
import { setAuth } from '@root-gipro/store/actions'
import { setTariffPlans } from '@root-gipro/modules/tariffs/store/actions'
import { tariffsFetchData } from '@root-gipro/modules/tariffs/api/tariffs'

function* getTariffPlans() {
	try {
		const authorize = yield call(authorizeApi, 'auth')
		if (authorize && authorize.status === 'success') {
			yield put(setAuth(authorize.access_token, authorize.expires_in))
			yield localStorage.setItem('service_auth_token', authorize.access_token)
			const result = yield call(tariffsFetchData, `/tarifPlans`, (res: any) => res)
			if (result.status === 'success') yield put(setTariffPlans(result.tarifPlans))
		} else {
			yield localStorage.removeItem('get_auth_token')
			yield (window.location.href = modeURL)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('get_auth_token')
		yield (window.location.href = modeURL)
	}
}

function* paymentCreate({payment}:any){
		try {
		const result = yield call(tariffsFetchData, `/payment/create`, (res: any) => res,'POST',{...payment})
		// console.log(result);

		if (result.status === 'success') {
			document.location.replace(result.confirmationUrl)
		}
		} catch (error) {
		yield localStorage.removeItem('get_auth_token')
		yield (window.location.href = modeURL)
	}

}

export function* watchTariffs() {
	yield takeEvery(TariffsType.GET_TARIFFS_PLANS, getTariffPlans)
}

export function* watchPaymentCreate() {
	yield takeEvery(TariffsType.PAYMENT_CREATE, paymentCreate)
}
