import React, { FC, useEffect, useState } from 'react'
import { MenuItem, Select, IconButton, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { IUncTableData } from '@root-gipro/store/interfaces'

interface Props {
	headItem: any
	headPosition: number
	uncTableData: IUncTableData | null
	setConfigValues: (mode: any) => void
	bodyData: any
	filteredBodyData: any
	headLength: number
	isHardTable: boolean
	setConfigs: (mode: any) => void
}

const ConfigureSelect: FC<Props> = ({
	headItem,
	uncTableData,
	headPosition,
	setConfigValues,
	bodyData,
	filteredBodyData,
	headLength,
	isHardTable,
	setConfigs,
}) => {
	const [configValue, setConfigValue] = useState<any>(null)
	const [isLastHead, setIsLastHead] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>, isNewSelect: boolean) => {
		const value = event.target.value as any
		if (isHardTable && isLastHead) {
			const lastHeadValue = bodyData.find((obj: any) => {
				return !!obj.find((child: any) => child.name === value && child.z !== undefined)
			})
			setConfigValues(lastHeadValue)
		} else {
			setConfigs((prev: any) => [...prev, { [headPosition]: value }])
		}
		setConfigValue(() => value)
	}
	useEffect(() => {
		if (headPosition == headLength) {
			setIsLastHead(true)
		}
	}, [headPosition, configValue])
	const handleClear = (e: any) => {
		e.stopPropagation()
		setConfigValue(null)
		setConfigValues(null)
		setConfigs((prev: any) => prev.filter((item: any) => !item[headPosition]))
	}

	const removeDuplicatesByName = (arrayOfArrays: any) => {
		const uniqueNames = new Set()
		
		const result = []
		for (const array of arrayOfArrays) {
			const filteredArray = array.filter((obj: any) => {
				if(obj.name !== "-"){

					if (!uniqueNames.has(obj.name)&& !uniqueNames.has(`${obj.name}X${obj.x}`) ) {
						if (obj?.z) {
						   uniqueNames.add(`${obj.name}Z`)
					   return true
	
					   }
					   uniqueNames.add(`${obj.name}X${obj.x}`)
					   return true
				   }
				}


				return false
			})

			result.push(filteredArray)
		}

		return result
	}

	const returnCorrectItems = () => {
		return removeDuplicatesByName(filteredBodyData)?.map((bodyItem: any, index: number) => {
			//достаю нужный элемент по позиции заголовка
			let con = bodyItem.find((it: any,ind:number) => headItem.x === it.x)
			if (con?.name) {
				//если таблица имеет заголовки со сложными вложенностями
				if (isHardTable === true) {
					//создаю элементы для последнего селекта
					if (headPosition === headLength && con?.z !== undefined) {
						return (
							<MenuItem key={index} value={con.name}>
								{con.name}
							</MenuItem>
						)
					}
					//обычные элементы
					if (headPosition !== headLength) {
						return (
							<MenuItem key={index} value={con.name}>
								{con.name}
							</MenuItem>
						)
					}
					//элементы для обычной таблицы
				} else {
					return (
						<MenuItem key={index} value={con.name}>
							{con.name}
						</MenuItem>
					)
				}
			}
		})
	}

	return (
		<div className='configure_block_item'>
			<div className='configure_block_item_title'>{headItem.name}</div>
			<Select
				labelId='select-label'
				label='Выберите значение'
				defaultValue=''
				className='select-input'
				value={configValue || ''}
				onChange={e => handleChange(e, false)}
				endAdornment={
					<InputAdornment position='end'>
						{configValue ? (
							<IconButton aria-label='Clear' onClick={e => handleClear(e)}>
								<ClearIcon fontSize='small' />
							</IconButton>
						) : (
							<IconButton
								aria-label='Toggle menu'
								onClick={() => setIsOpen(!isOpen)}
								className={`select_arrow_icon ${isOpen && 'active'}`}
							>
								{/* <span className='select_arrow_icon'> */}
								<svg
									xmlns='http://www.w3.org/2000/svg'
									version='1.0'
									width='24.000000pt'
									height='24.000000pt'
									viewBox='0 0 512.000000 512.000000'
									preserveAspectRatio='xMidYMid meet'
								>
									<g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
										<path d='M895 3506 c-67 -29 -105 -105 -90 -183 6 -34 100 -131 843 -875 744 -743 841 -837 875 -843 94 -18 39 -66 949 843 909 909 861 855 843 949 -9 49 -69 109 -118 118 -94 18 -46 59 -875 -768 l-762 -762 -758 757 c-424 424 -769 762 -785 768 -38 14 -85 12 -122 -4z' />
									</g>
								</svg>
								{/* </span> */}
							</IconButton>
						)}
					</InputAdornment>
				}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
					open: isOpen,
				}}
				onClick={() => setIsOpen(!isOpen)}
			>
				{returnCorrectItems()}
			</Select>
		</div>
	)
}

export default ConfigureSelect
