import { Checkbox, fade, ListItemText, makeStyles, Slider } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FilterItemDateRangeProps } from '@root-gipro/shared/Filter/models/filter-item-date-range.props'
import React, { useEffect, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'

import '@root-gipro/modules/commonProjects/styles/DurationDate.scss'
import TooltipHelp from '@root-gipro/shared/Tooltips/TooltipHelp'

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		padding: 0,
		height: 'auto',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'& .MuiFilledInput-inputHiddenLabel': {
			paddingTop: 4,
			paddingBottom: 4,
		},
		'& .MuiInputAdornment-root': {
			width: 38,
		},

		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
			borderColor: theme.palette.primary.main,
		},
	},
	focused: {},
}))

const FilterItemDateRange: React.FC<FilterItemDateRangeProps> = ({
	name,
	startName = 'с',
	endName = 'по',
	withCheckbox,
	minMax = { min: 2017, max: 2055 },
	setEnd,
	setStart,
	end,
	start,
	fieldNameStart,
	fieldNameEnd,
	disabledAll,
	format = 'MM.yyyy',
	openTo = 'date',
	views = ['year', 'date', 'month'],
}) => {
	const classes = useStylesReddit()

	const templateEndDate = 2055
	const [minValueRange, setMinValueRange] = useState(minMax.min)
	const [maxValueRange, setMaxValueRange] = useState(templateEndDate)

	const [startDate, setStartDate] = useState(start)
	const [endDate, setEndDate] = useState(new Date(`${templateEndDate}-12-31`))
	const [value, setValue] = useState<number[]>([minValueRange, maxValueRange])
	const [checkChangeDate, setCheckChangeDate] = useState<boolean>(false)

	useEffect(() => {
		if (fieldNameStart === 'yearBegin.min' && fieldNameEnd === 'yearEndOffer.max') {
			setMinValueRange(Number(minMax[fieldNameStart.replace('.min', '')]?.min))
			setMaxValueRange(Number(templateEndDate))
		} else if (fieldNameStart === 'estimatedOfferYM.min' && fieldNameEnd === 'estimatedOfferYM.max') {
			setMinValueRange(Number(new Date(minMax[fieldNameStart.replace('.min', '')]?.min).getFullYear()))
			setMaxValueRange(Number(new Date(templateEndDate).getFullYear()))
		}
	}, [minMax, minValueRange, maxValueRange])

	useEffect(() => {
		if (!start && !end && minValueRange && maxValueRange) {
			let dateStart = new Date(`${minValueRange}-01-01`)
			let dateEnd = new Date(`${templateEndDate}-12-31`)
			setStartDate(new Date(dateStart))
			setEndDate(new Date(dateEnd))
			setValue([dateStart.getFullYear(), dateEnd.getFullYear()])
		}

		if (start && !end) {
			const startYear = new Date(start).getFullYear()
			let endMaxYear = new Date(`${templateEndDate}-12-31`).getFullYear()
			setStartDate(new Date(start))
			setValue([2040, endMaxYear])
		}
		if (end && !start) {
			const startMinYear = new Date(`${minValueRange}-12-31`).getFullYear()
			const endYear = new Date(end).getFullYear()
			setEndDate(new Date(end))
			setValue([startMinYear, endYear])
		}

		if (start && end) {
			const startYear = new Date(start).getFullYear()
			const endYear = new Date(end).getFullYear()
			setStartDate(new Date(start))
			setEndDate(new Date(end))

			setValue([startYear, endYear])
		}
	}, [start, end, minValueRange, maxValueRange])

	const resetFields = () => {
		setStart(null)
		setEnd(null)
	}

	const handleStartDateChange = (date: Date | null) => {
		let dateStart = new Date(`${minValueRange}-01-01`)

		if (date === null || date!.getTime() < dateStart.getTime()) {
			setStart(dateStart)
		} else if (isNaN(date!.getTime())) {
			console.log('Invalid start date')
		} else {
			setStart(date)
		}
	}
	const handleEndDateChange = (date: Date | null) => {
		let dateEnd = new Date(`${maxValueRange}-12-31`)

		if (date === null || date!.getTime() > dateEnd!.getTime()) {
			setEnd(dateEnd)
		} else if (isNaN(date!.getTime())) {
			console.error('Invalid end date')
		} else {
			setEnd(date)
		}
	}

	const handleChange = (event: any, newValue: any) => {
		setStartDate(new Date(`${newValue[0]}-01-01`))
		setEndDate(new Date(`${newValue[1]}-12-31`))
		setValue(newValue as number[])
	}
	const handleChangeCommitted = (event: any, newValue: any) => {
		let dateStart = new Date(`${newValue[0]}-01-01`)
		let dateEnd = new Date(`${newValue[1]}-12-31`)
		setStart(dateStart)
		setEnd(dateEnd)
	}

	useEffect(() => {
		Math.round(value[0]) === minValueRange && Math.round(value[1]) === maxValueRange
			? setCheckChangeDate(false)
			: setCheckChangeDate(true)
	}, [value[0], value[1]])

	return (
		<div className='duration-date'>
			<div className='duration-date__head'>
				<h5 className='duration-date__head-title'>{name}</h5>
				{fieldNameStart === 'estimatedOfferYM.min' ? (
					<div style={{ marginLeft: '6px', marginBottom: '-2px' }}>
						<TooltipHelp title='Год выполнения расчета. Расчет стоимости проекта в текущем уровне цен зависит от индекс-дефляторов при оценке полной стоимости проекта по УНЦ, от квартальных коэффициентов при расчете стоимости по ПСД'></TooltipHelp>
					</div>
				) : null}
				{checkChangeDate ? (
					<span className='link link-reset' style={{ marginLeft: 'auto' }} onClick={resetFields}>
						Сбросить
					</span>
				) : (
					''
				)}
			</div>

			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<div className='duration-date__pickers'>
					<KeyboardDatePicker
						value={startDate}
						onChange={handleStartDateChange}
						disableToolbar
						variant='inline'
						format={format}
						margin='normal'
						inputVariant='filled'
						hiddenLabel
						autoOk
						InputProps={{
							classes,
							disableUnderline: true,
						}}
						helperText={''}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
					<div>—</div>
					<KeyboardDatePicker
						value={endDate}
						onChange={handleEndDateChange}
						disableToolbar
						variant='inline'
						format={format}
						margin='normal'
						inputVariant='filled'
						hiddenLabel
						emptyLabel=''
						autoOk
						helperText={''}
						InputProps={{
							classes,
							disableUnderline: true,
						}}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</div>
			</MuiPickersUtilsProvider>
			<Slider
				value={value}
				onChange={handleChange}
				onChangeCommitted={handleChangeCommitted}
				min={minValueRange}
				max={maxValueRange}
			/>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox
						disabled={withCheckbox.disabled}
						checked={withCheckbox.checked}
						onChange={() => {
							if (!withCheckbox) {
								return
							}
							if (withCheckbox.checkboxChange) withCheckbox.checkboxChange()
							withCheckbox.setChecked(!withCheckbox.checked)
							// if (!withCheckbox.checked) resetFields() // приводит к сбрасыванию дынных
						}}
					/>
					<ListItemText primary={withCheckbox.label} />
				</div>
			)}
		</div>
	)
}

export default FilterItemDateRange
