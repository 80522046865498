import React, { FC, useContext, useMemo } from 'react'
import { IconButton } from '@material-ui/core'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import { RatesOptions } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { ModalContext } from '@root-gipro/context/modal-context'
import '@root-gipro/modules/commonProjects/styles/uncTechnicalSpecifications.scss'
import '@root-gipro/modules/commonProjects/styles/Rates.scss'
import { ICommonProject, IRgStatuses } from '@root-gipro/store/interfaces'
import {
	gridTemplateColumns,
	theadData,
} from '@root-gipro/modules/commonProjects/models/const/construction-resourcest-table-thead.const'
import { cellData } from '@root-gipro/modules/commonProjects/models/const/construction-resourcest-table-body.const'
import { Table } from '@root-gipro/shared/Table/container/Table'
import { TableType } from '@root-gipro/shared/Table/components/Tbody'
import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'

interface IConstructionResourcesProps {
	uncTechnicalSpecifications: UncTechnicalSpecifications[] | null
	policesRGCO: boolean
	policesGipro: boolean
	rgStatuses: IRgStatuses[]
	selectedProject: ICommonProject | null
	addToUserProject(projectId: string | number): void
	requestRgStatuses(projectId: string | number): void
	downloadUncTechnicalSpecifications(id: string | number, code: string): void
	downloadCommonProject(id: string | number, code: string): void
}

const ConstructionResources: FC<IConstructionResourcesProps> = ({
	uncTechnicalSpecifications,
	policesRGCO,
	policesGipro,
	selectedProject,
	rgStatuses,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
	downloadUncTechnicalSpecifications,
}) => {
	const { setAnchorEl, anchorEl } = useContext(ModalContext)

	const getRgStatusId = () => {
		if (selectedProject) {
			requestRgStatuses(selectedProject.id)
		}
	}

	const tableObj = useMemo(() => {
		let obj: TableType = {
			1: [],
		}

		if (uncTechnicalSpecifications && uncTechnicalSpecifications.length > 0) {
			uncTechnicalSpecifications.forEach((item: UncTechnicalSpecifications): void => {
				obj[1].push(item)
			})
		}
		return obj
	}, [uncTechnicalSpecifications])

	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [uncTechnicalSpecifications])

	const bodyParams = useMemo(() => {
		return { gridTemplateColumns, tableObj, cellData }
	}, [uncTechnicalSpecifications])

	return (
		<>
			<div className='common-projects__rates container'>
				<div className='common-projects__rates-head'>
					<div className='title'>Строительные ресурсы</div>
					<div className='common-projects__rates-actions'>
						<IconButton type='button' data-popup='options-popup' onClick={e => setAnchorEl!(e.currentTarget)}>
							<span className='dots-icon' />
						</IconButton>
						{anchorEl?.dataset.popup === 'options-popup' && (
							<RatesOptions
								anchorEl={anchorEl!}
								setAnchorEl={setAnchorEl!}
								policesGipro={policesGipro}
								policesRGCO={policesRGCO}
								copyHrefToClipboard={copyHrefToClipboard}
								downloadCommonProject={downloadCommonProject}
								addToUserProject={addToUserProject}
								getRgStatusId={getRgStatusId}
								rgStatuses={rgStatuses}
								project={selectedProject}
								rgStatusId={selectedProject?.rgStatusId}
								downloadUncTechnicalSpecifications={downloadUncTechnicalSpecifications}
							/>
						)}
					</div>
				</div>
			</div>
			{uncTechnicalSpecifications && <Table theadParams={theadParams} bodyParams={bodyParams} />}
		</>
	)
}

export default ConstructionResources
