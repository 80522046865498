import React from 'react'
import './ReportLoader.scss'

const TableDownloadLoader: React.FC = () => {
	return <div className="report-loader-wrap">Скачивание таблицы....
				<div className="lds-ellipsis">
					<div></div><div></div><div></div><div></div>
				</div>
		   </div>
}

export default TableDownloadLoader
