import { Collapse } from '@material-ui/core'
import styled from 'styled-components'

export const WrapperPurchase = styled(Collapse)<{ in: boolean; unmountOnExit?: boolean; showPurchases: boolean }>`
	opacity: ${({ showPurchases }) => (showPurchases ? 1 : 0)};
	margin: 16px;
	overflow: visible;
`
export const WrapperStage = styled(Collapse)<{ in: boolean; unmountOnExit?: boolean; showStages: boolean }>`
	opacity: ${({ showStages }) => (showStages ? 1 : 0)};
	margin: 16px;
	overflow: visible;
`
export const WrapperCardStyle = styled.div`
	padding: 16px 0;
	border-top: 1px solid #e0e0e0;
`

export const WrapperInfoBlock = styled.div`
	display: flex;
	justify-content: space-between;
`

export const CardLeftStyle = styled.div`
	width: calc(100% - 320px);
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.56);
	max-height: 80px;
	overflow: hidden;
	display: -webkit-box;
`

export const CardRightStyle = styled.div`
	width: 320px;
	& > div {
		margin-left: 8px;
	}
`

export const CardButtonPurchase = styled.div`
	display: flex;
	align-items: center;
	font-family: Roboto;
	font-style: normal;
	margin-top: 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	bottom: 16px;
	color: #3f4f5e;
`

export const TopBlockPurchase = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	svg {
		width: 16px;
		height: 16px;
		color: #3f4f5e;
		&:hover {
			cursor: pointer;
			color: #1baaf0;
		}
	}
	span {
		cursor: pointer;
		margin: 0 6px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #1baaf0;
	}
`

export const IconArrowStyle = styled.span<{ showPurchases: boolean }>`
	cursor: pointer;
	width: 12px;
	height: 10px;
	margin-left: 6px;
	display: block;
	background-image: ${({ showPurchases }) =>
		showPurchases ? 'url(/assets/images/svg/arrow-up.svg)' : 'url(/assets/images/svg/arrow-down.svg)'};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
`

export const Icon = styled.div`
	width: 20px;
	height: 20px;
	display: block;
	background-image: url(/assets/images/svg/purchase.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
`

export const BlockPlanWithOfferDiv = styled.div`
	/* box-sizing:border-box; */
	/* border: 1px solid #e0e0e0; */
	height: 32px;
	margin-top: 5px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	/* justify-content: space-between; */
	span {
		color: #aeaeae;
		margin-right: 6px;
	}
`
export const OfferDiv = styled.div`
	border: 1px solid #3f4f5e;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: 8px;
	span {
		padding-left: 8px;
	}
`
export const PlanDiv = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: 8px;
	border: 1px solid #e0e0e0;
	span {
		padding-left: 8px;
	}
`
