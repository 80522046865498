import { ReactText } from 'react'
import { IUnc, IUncTableElem, IUserProject } from '@root-gipro/store/interfaces'
import { UserProjectsType } from '@root-gipro/modules/userProjects/models/interfaces/user-project.actions'

export const fetchRequest = () => ({
	type: UserProjectsType.FETCH_REQUEST,
})
export const createProjectTemplate = () => ({
	type: UserProjectsType.CREATE_PROJECT_TEMPLATE,
})

export const fetchUserProject = (id: string) => ({
	type: UserProjectsType.FETCH_USER_PROJECT,
	id,
})

export const saveUserProject = (body: IUserProject, isNew: boolean, id?: ReactText) => ({
	type: UserProjectsType.SAVE_USER_PROJECT,
	body,
	isNew,
	id,
})

export const deleteUserProject = (id: string, name: string) => ({
	type: UserProjectsType.DELETE_USER_PROJECT,
	id,
	name,
})

export const chooseProject = (project: IUserProject) => ({
	type: UserProjectsType.CHOOSE_PROJECT,
	project,
})

export const setOriginal = (project: IUserProject) => ({
	type: UserProjectsType.SET_ORIGINAL_OBJ,
	project,
})

export const setToggleTabs = (show: boolean) => ({
	type: UserProjectsType.SET_TOGGLE_TABS,
	show,
})

export const setUncEditMode = (mode: boolean) => ({
	type: UserProjectsType.SET_UNC_EDIT_MODE,
	mode,
})

export const setActiveUncs = (uncs: IUnc[]) => ({
	type: UserProjectsType.SET_ACTIVE_UNCS,
	uncs,
})

export const getProjects = () => ({
	type: UserProjectsType.GET_USER_PROJECTS,
})

// used SelectedContainer in common-project
export const fetchUncs = (id: string) => ({
	type: UserProjectsType.FETCH_UNCS,
	id,
})
export const editPositionUnc = (params: any) => {
	return {
		type: UserProjectsType.UNC_POSITION_EDIT,
		params,
	}
}
export const setProjectUncs = (uncs: IUnc[]) => ({
	type: UserProjectsType.SET_PROJECT_UNCS,
	uncs,
})

export const setIsNewProject = (isNew: boolean) => ({
	type: UserProjectsType.SET_IS_NEW_PROJECT,
	isNew,
})

export const setProjectCode = (code: string) => ({
	type: UserProjectsType.SET_PROJECT_СODE,
	code,
})

export const setProjectName = (name: string) => ({
	type: UserProjectsType.SET_PROJECT_NAME,
	name,
})

export const setProjectYears = (begin: ReactText, end: ReactText) => ({
	type: UserProjectsType.SET_YEARS,
	begin,
	end,
})

export const setProjectRegions = (regionIds: string[]) => ({
	type: UserProjectsType.SET_REGION_IDS,
	regionIds,
})

export const setProjectValuation = (valuation: number) => ({
	type: UserProjectsType.SET_VALUATION_OFFER_FORECAST,
	valuation,
})

export const setProjectAdditional = (offer: number) => ({
	type: UserProjectsType.SET_ADDITIONAL_FUNDING_OFFER,
	offer,
})

export const setProjectRationale = (offerRationale: string) => ({
	type: UserProjectsType.SET_OFFER_RATIONALE,
	offerRationale,
})

export const setProjectActual = (actualFunding: number) => ({
	type: UserProjectsType.SET_ACTUAL_FUNDING,
	actualFunding,
})

export const setProjectNds = (nds: number) => ({
	type: UserProjectsType.SET_NDS,
	nds,
})

export const fetchUncTableData = (id: number) => ({
	type: UserProjectsType.FETCH_UNC_TABLE_DATA,
	id,
})

export const selectTableElem = (selectedElem: IUncTableElem | null) => ({
	type: UserProjectsType.SELECT_TABLE_ELEM,
	selectedElem,
})

export const changeComment = (id: ReactText, comment: string) => ({
	type: UserProjectsType.CHANGE_UNC_COMMENT,
	id,
	comment,
})

export const changeCounter = (id: ReactText, count: string, inBuffer?: boolean | undefined) => ({
	type: UserProjectsType.CHANGE_UNC_COUNTER,
	id,
	count,
	inBuffer,
})

export const changeRegion = (id: ReactText, regionId: ReactText) => ({
	type: UserProjectsType.CHANGE_UNC_REGION,
	id,
	regionId,
})

export const deleteUnc = (id: ReactText, inBuffer?: boolean) => ({
	type: UserProjectsType.DELETE_UNC,
	id,
	inBuffer,
})

export const clearBuffer = () => ({
	type: UserProjectsType.SET_UNC_ADD_BUFFER,
	buffer: [],
})

export const changeVoltage = (id: string, voltage: number, inBuffer?: boolean) => ({
	type: UserProjectsType.CHANGE_UNC_VOLTAGE,
	id,
	voltage,
	inBuffer,
})
/// используется в разных компонентах
export const setFilter = (filter: string) => ({
	type: UserProjectsType.SET_UNC_FILTER,
	filter,
})

export const updateDeflators = (start: ReactText, end: ReactText) => ({
	type: UserProjectsType.UPDATE_DEFLATORS,
	start,
	end,
})

export const setYears = (years: {
	[key: string]: {
		offer: number | null
		deflator: number | null
	}
}) => ({
	type: UserProjectsType.SET_PROJECT_YEARS,
	years,
})

export const setInterpolation = (obj: { [key: string]: ReactText }) => ({
	type: UserProjectsType.SET_INTERPOLATION,
	obj,
})

export const createSpecialUnc = (
	uncMainId: ReactText,
	regionId: ReactText,
	interpolated: { [key: string]: ReactText },
	tableNum: ReactText
) => ({
	type: UserProjectsType.CREATE_SPECIAL_UNC,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
})
export const addUnc = (tableNum: ReactText, uncCellId: ReactText, count: number, addBuffer: boolean) => ({
	type: UserProjectsType.ADD_UNC,
	tableNum,
	uncCellId,
	count,
	addBuffer,
})
export const setProjectFilter = (filter: string) => ({
	type: UserProjectsType.SET_PROJECT_FILTER,
	filter,
})

export const getCheckedErrors = (id: ReactText | undefined, sectionIdsArr: number[]) => ({
	type: UserProjectsType.GET_CHECKED_ERRORS,
	id,
	sectionIdsArr,
})

export const setProjectErrors = (errors: { [key: string]: string[] } | null) => ({
	type: UserProjectsType.SET_PROJECT_ERRORS,
	errors,
})

export const showProjectErrors = (show: boolean) => ({
	type: UserProjectsType.SHOW_PROJECT_ERRORS,
	show,
})

export const selectVoltage = (uncCellId: ReactText, uncId: ReactText) => ({
	type: UserProjectsType.SELECT_VOLTAGE,
	uncCellId,
	uncId,
})
export const setUncCellsFullInfo = (uncCellId: ReactText) => ({
	type: UserProjectsType.SET_UNC_CELL_INFO,
	uncCellId,
})

export const selectVoltageId = (targetVoltageId: ReactText) => ({
	type: UserProjectsType.SELECT_VOLTAGE_ID,
	targetVoltageId,
})

export const setUserProjects = (
	projects: IUserProject[],
	totalInvestmentUncOfferSum: number,
	userProjectsCnt: number
) => ({
	type: UserProjectsType.SET_USER_PROJECTS,
	projects,
	totalInvestmentUncOfferSum,
	userProjectsCnt,
})

export const setVoltage = (targetVoltageValues: ReactText[]) => ({
	type: UserProjectsType.SET_VOLTAGE,
	targetVoltageValues,
})

export const downloadRulesCheck = () => ({
	type: UserProjectsType.DOWNLOAD_RULES_CHECK,
})

export const uploaderRulesCheck = (file: any) => ({
	type: UserProjectsType.UPLOADER_RULES_CHECK,
	file,
})

export const downloadProject = (id: ReactText | null, name: string | null, version: string) => ({
	type: UserProjectsType.DOWNLOAD_PROJECT,
	id,
	name,
	version,
})

export const copyProject = (userProjectId: ReactText, code: ReactText) => ({
	type: UserProjectsType.COPY_PROJECT,
	userProjectId,
	code,
})

export const addPrivateProject = (projectId: ReactText) => ({
	type: UserProjectsType.ADD_PRIVATE_PROJECT,
	projectId,
})

export const getTplUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.GET_TEMPLATE_UNNORMOLIZED_COSTS,
	id,
})

export const getUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.GET_UNNORMOLIZED_COSTS,
	id,
})

export const uploaderUnnormalizedСosts = (file: any, id: ReactText) => ({
	type: UserProjectsType.UPLOAD_UNNORMOLIZED_COSTS,
	file,
	id,
})

export const deleteUnnormalizedСosts = (id: ReactText) => ({
	type: UserProjectsType.DELETE_UNNORMOLIZED_COSTS,
	id,
})

export const sortUserProjects = (name: string, sortName: string, order?: boolean) => ({
	type: UserProjectsType.SORT_USER_PROJECTS,
	name,
	sortName,
	order,
})

export const createUncB1 = (
	uncMainId: number,
	regionId: ReactText,
	interpolated: number,
	tableNum: ReactText,
	count: number
) => ({
	type: UserProjectsType.CREATE_UNC_B1,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
	count,
})
