import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'

const YMetrika = () => (
	<div>
		<YMInitializer
			accounts={[67514524]}
			options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
		/>
	</div>
)

export default YMetrika
