import { IUnc } from '@root-gipro/store/interfaces'
import { createUncArr } from '@root-gipro/modules/userProjects/utils/helpers'

export type TbodyArrType = {
	tableNum: number
	unc: (tableNum: number, uncs: IUnc[]) => IUnc[]
	title: string
	sitePreparation: boolean
}

export const tbodyArr = [
	{
		tableNum: 1,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Таблица 1. Строительство ПС',
		sitePreparation: true,
	},
	{
		tableNum: 2,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Таблица 2. Реконструкция ПС (элементов ПС), строительство элементов ПС 35-750 кВ',
		sitePreparation: true,
	},
	{
		tableNum: 3,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Таблица 3. Строительство (реконструкция) КТП, ТП, РТП, СП 6 - 20 кВ',
		sitePreparation: true,
	},
	{
		tableNum: 4,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Таблица 4. Строительство (реконструкция) ВЛ 0,4 - 750 кВ',
		sitePreparation: true,
	},
	{
		tableNum: 5,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Таблица 5. Строительство (реконструкция) КЛ 0,4 - 500 кВ',
		sitePreparation: true,
	},
	{
		tableNum: 8,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Расчет площади С1',
		sitePreparation: false,
	},
]
