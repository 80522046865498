import qs from 'qs'
import get from 'lodash/get'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

export const encode = paramsObj => qs.stringify(paramsObj, { encode: false })

export const decode = paramsStr => qs.parse(paramsStr, { ignoreQueryPrefix: true, parseBooleans: true })

export const constructNewState = (initialState, search) => Object.entries(initialState)
  .reduce((acc, [key, value]) => {
    // TODO: refactor
    const mapper = {
      'true': { value: true },
      'false': { value: false }
    }
    const newValue = get(decode(search), key, value)
    const {value: newStateValue} = mapper[newValue] || { value: newValue }
    const newState = merge(acc, {[key]: newStateValue})
    return newState
  }, {})

export const updateQueryParams = (initialState, search) => {
  const queryParametersState = decode(search)
  const stateKeys = Object.keys(initialState)
  return encode(omit(queryParametersState, stateKeys))
}