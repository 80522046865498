import { giproApp } from '@root-gipro/store/api'
import { handleAccessError } from '@root-gipro/utils/helpers/handleAccessError'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'

const actionFavoritesProjectsApi = (url: string, data: any = {}, method: any = 'GET') => {
	return giproApp({
		method,
		url,
		data,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const getFavoritesApi = (url: string, params?: any, method: any = 'GET') => {
	return giproApp({
		method,
		url,
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

// для работы фильтра на странице "Избранное"

const favoritesApi = (params?: any) => {
	return giproApp({
		method: 'GET',
		url: '/project-general-search',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const favoritesPurchasesApi = (params?: any) => {
	return giproApp({
		method: 'GET',
		url: '/purchase-search',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}
export { getFavoritesApi, actionFavoritesProjectsApi, favoritesApi, favoritesPurchasesApi }
