import {
	CommonProjectsType,
	CommonProjectsTypes,
} from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.actions'
import { ICommonProjectsState } from '../models/interfaces/common-projects.model'
import produce, { Draft } from 'immer'
import { forEach } from 'lodash'

const initialState: ICommonProjectsState = {
	projects: [],
	projectsCount: 0,
	paginationCounter: 0,
	valuationOfferForecastSum: 0,
	valuationPlanForecastSum: 0,
	loading: false,
	toggleType: 'offer',
	sort: {
		name: 'Наименование ДЗО',
		sortName: 'group.name',
		order: true,
	},
	selectedProject: null,
	selectedProjectUncs: null,
	selectedProjectUncTechnicalSpecifications: null,
	uncRatesFiltred: false,
	uncInfo: null,
	allowMoreProjects: true,
	isFavorites: false,
	costWorks: {
		loading: false,
		error: null,
	},
	IDs: null,
	userInfo: '',
	reporting: false,
	tableDownloading: false,
	targetProjectId: '',
	loadPagination: false,
	loadUncs: false
}

const commonProjects = (draft: Draft<ICommonProjectsState>, action: CommonProjectsTypes) => {
	switch (action.type) {
		case CommonProjectsType.SET_COMMON_PROJECTS:
			draft.projects = action.projects
			break

		case CommonProjectsType.SWITCH_RATES_FILTRED:
			draft.uncRatesFiltred = action.uncRatesFiltred
			break

		case CommonProjectsType.ADD_COMMON_PROJECTS:
			action.projects.forEach(project => {
				draft.projects.push(project)
			})
			break
		case CommonProjectsType.SET_COMMON_PROJECTS_COUNT:
			draft.projectsCount = action.projectsCount
			break
		case CommonProjectsType.SET_COMMON_PROJECTS_PAGINATION_COUNT:
			draft.paginationCounter = action.paginationCounter
			break
		case CommonProjectsType.LOADING_COMMON_PROJECTS:
			draft.loading = action.loading
			break
		case CommonProjectsType.EDIT_TOGGLE_TYPE:
			draft.toggleType = action.toggleType
			break
		case CommonProjectsType.SET_COMMON_PROJECTS_SUM:
			draft.valuationOfferForecastSum = action.valuationOfferForecastSum
			draft.valuationPlanForecastSum = action.valuationPlanForecastSum
			break
		case CommonProjectsType.SORT_COMMON_PROJECTS:
			draft.sort.name = action.name
			draft.sort.sortName = action.sortName
			draft.sort.order = action.order
			break
		case CommonProjectsType.COMMON_PROJECTS_SELECTED_PROJECT:
			draft.selectedProject = action.selectedProject
			break
		case CommonProjectsType.SET_UNCS_COMMON_PROJECT:
			draft.selectedProjectUncs = action.uncs
			break
		case CommonProjectsType.SET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT:
			draft.selectedProjectUncTechnicalSpecifications = action.uncTechnicalSpecifications
			break
		case CommonProjectsType.SET_UNC_INFO:
			draft.uncInfo = action.uncInfo
			break
		case CommonProjectsType.SET_ALLOW_MORE_PROJECTS:
			draft.allowMoreProjects = action.allowMoreProjects
			break
		case CommonProjectsType.SUBSCRIBE_REQUEST:
			draft.loading = true
			break
		case CommonProjectsType.SUBSCRIBE_FAILURE:
			draft.costWorks.loading = false
			draft.costWorks.error = action.message
			break
		case CommonProjectsType.USERINFO_REQUEST:
			draft.userInfo = action.payload
			break
		case CommonProjectsType.REPORT_LOADING:
			draft.reporting = action.payload
			break
		case CommonProjectsType.TABLE_DOWNLOAD_LOADING:
			draft.tableDownloading = action.payload
			break
		case CommonProjectsType.SET_FILTER_IDS_COMMON_PROJECTS:
			draft.IDs = action.IDs
			break
		case CommonProjectsType.SET_TARGET_ID_PROJECT:
			draft.targetProjectId = action.targetProjectId
			break
		case CommonProjectsType.LOADING_COMMON_PAGINATION:
			draft.loadPagination = action.loadPagination
			break
		case CommonProjectsType.LOADING_COMMON_UNCS:
			draft.loadUncs = action.loadUncs
			break

		default:
			return draft
	}
}
export const commonProjectsReducer = produce(commonProjects, initialState)
