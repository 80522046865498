import React from 'react'
import './ReportLoader.scss'

const ReportLoader: React.FC = () => {
	// return <div className='report-loader-wrap'>Отчет формируется....<div className='report-loader'></div></div>
	return <div className="report-loader-wrap">Отчет формируется....
				<div className="lds-ellipsis">
					<div></div><div></div><div></div><div></div>
				</div>
		   </div>
}

export default ReportLoader
