import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

export const popupContent = {
	shrtTxt: 'Рассчитать стоимость:',
	items: [
		{
			step: StepType.DESIGN_WORK,
			itemTxt: 'Проектных работ',
		},
		{
			step: StepType.BUILD_WORK,
			itemTxt: 'Строительных работ',
		},
	],
}
