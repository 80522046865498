import { IGroup, ICompany, IRegion, IRgStatuses, IUncMain, IUncThDescriptions, IUncThs } from '../interfaces'

enum PublicType {
	FETCH_PUBLIC_INFO = 'FETCH_PUBLIC_INFO',
	SET_PUBLIC_INFO = 'SET_PUBLIC_INFO',
	SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS = 'SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS',
	GET_PURCHASES_METHODS = 'GET_PURCHASES_METHODS',
	SET_PURCHASES_METHODS = 'SET_PURCHASES_METHODS',

	GET_PURCHASES_STAGES = 'GET_PURCHASES_STAGES',
	SET_PURCHASES_STAGES = 'SET_PURCHASES_STAGES',
	GET_PURCHASES_OKPD = 'GET_PURCHASES_OKPD',
	SET_PURCHASES_OKPD = 'SET_PURCHASES_OKPD',
	GET_PURCHASES_OKVED = 'GET_PURCHASES_OKVED',
	SET_PURCHASES_OKVED = 'SET_PURCHASES_OKVED',
	COUNT_PUBLIC_INFO_FETCHES = 'COUNT_PUBLIC_INFO_FETCHES',
}

interface FetchPublicInfo {
	type: PublicType.FETCH_PUBLIC_INFO
}
interface GetPurchasesMethods {
	type: PublicType.GET_PURCHASES_METHODS
}
interface SetPurchasesMethods {
	type: PublicType.SET_PURCHASES_METHODS
	purchasesMethods: string[]
}
interface GetPurchasesStages {
	type: PublicType.GET_PURCHASES_STAGES
}
interface SetPurchasesStages {
	type: PublicType.SET_PURCHASES_STAGES
	purchasesStages: string[]
}
interface GetPurchasesOkpd {
	type: PublicType.GET_PURCHASES_OKPD
}
interface SetPurchasesOkpd {
	type: PublicType.SET_PURCHASES_OKPD
	purchasesOkpd: string[]
}
interface GetPurchasesOkved {
	type: PublicType.GET_PURCHASES_OKVED
}
interface SetPurchasesOkved {
	type: PublicType.SET_PURCHASES_OKVED
	purchasesOkved: string[]
}

interface CountPublicInfoFetches {
	type: PublicType.COUNT_PUBLIC_INFO_FETCHES
	countPublicInfoFetches: boolean
}
interface SetPublicInfo {
	type: PublicType.SET_PUBLIC_INFO
	data: {
		infoName: string
		infoData:
			| boolean
			| IGroup[]
			| ICompany[]
			| IRegion[]
			| IUncMain[]
			| string[]
			| string
			| any[]
			| IUncThs[]
			| IUncThDescriptions[]
			| IRgStatuses[]
	}
}

interface SetPublicUncTechnicalSpecifications {
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS
	uncTechnicalSpecifications: {
		name: {}
		paramName: {}
		param: {}
	}
}

const fetchPublicInfo = () => ({
	type: PublicType.FETCH_PUBLIC_INFO,
})

const setPublicInfo = (data: { [key: string]: SetPublicInfo }) => ({
	type: PublicType.SET_PUBLIC_INFO,
	data,
})

const setPublicUncTechnicalSpecifications = (uncTechnicalSpecifications: { name: {}; paramName: {}; param: {} }) => ({
	type: PublicType.SET_PUBLIC_UNC_TECHNICAL_SPECIFICATIONS,
	uncTechnicalSpecifications,
})

const getPurchasesMethods = () => ({
	type: PublicType.GET_PURCHASES_METHODS,
})
const setPurchasesMethods = (purchasesMethods: string[]) => ({
	type: PublicType.SET_PURCHASES_METHODS,
	purchasesMethods,
})

const getPurchasesStages = () => ({
	type: PublicType.GET_PURCHASES_STAGES,
})
const setPurchasesStages = (purchasesStages: string[]) => ({
	type: PublicType.SET_PURCHASES_STAGES,
	purchasesStages,
})

const getPurchasesOkpd = () => ({
	type: PublicType.GET_PURCHASES_OKPD,
})
const setPurchasesOkpd = (purchasesOkpd: string[]) => ({
	type: PublicType.SET_PURCHASES_OKPD,
	purchasesOkpd,
})

const getPurchasesOkved = () => ({
	type: PublicType.GET_PURCHASES_OKVED,
})
const setPurchasesOkved = (purchasesOkved: string[]) => ({
	type: PublicType.SET_PURCHASES_OKVED,
	purchasesOkved,
})

const countPublicInfoFetches = (countPublicInfoFetches: boolean) => ({
	type: PublicType.COUNT_PUBLIC_INFO_FETCHES,
	countPublicInfoFetches,
})

export type PublicTypes =
	| FetchPublicInfo
	| SetPublicInfo
	| SetPublicUncTechnicalSpecifications
	| SetPurchasesMethods
	| GetPurchasesMethods
	| SetPurchasesStages
	| GetPurchasesStages
	| SetPurchasesOkpd
	| GetPurchasesOkpd
	| SetPurchasesOkved
	| GetPurchasesOkved
	| CountPublicInfoFetches

export {
	PublicType,
	fetchPublicInfo,
	setPublicInfo,
	setPublicUncTechnicalSpecifications,
	getPurchasesMethods,
	setPurchasesMethods,
	getPurchasesStages,
	setPurchasesStages,
	getPurchasesOkpd,
	setPurchasesOkpd,
	getPurchasesOkved,
	setPurchasesOkved,
	countPublicInfoFetches,
}
