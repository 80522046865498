import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { publicReducer } from './publicReducer'
import { userProjectsReducer } from '@root-gipro/modules/userProjects/store/reducer'
import { commonProjectsReducer } from '@root-gipro/modules/commonProjects/store/reducer'
import { maketreducer } from './maketreducer'
import { IState } from '@root-gipro/store/interfaces/state'
import { purchaseReducer } from '@root-gipro/modules/purchases/store/reducer'
import { filterReducer } from '@root-gipro/modules/filter/store/reducer'
import { notifyReducer } from '@root-gipro/modules/notify/store/reducer'
import { tariffsReducer } from '@root-gipro/modules/tariffs/store/reducer'
import { favoritesReducer } from '@root-gipro/modules/favorites/store/reducer'
import { investmentProgramsReducer } from '@root-gipro/modules/investmentPrograms/store/reducer'
import { developmentSchemesAndProgramsReducer } from '@root-gipro/modules/developmentSchemesAndPrograms/store/reducer'
import { adminInvestmentProgramsReducer } from '@root-gipro/modules/admin/investmentPrograms/store/reducer'
import {
	adminDevelopmentSchemesAndProgramsReducer
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/store/reducer'
import { mediaReducer } from '@root-gipro/modules/media/store/reducer'

const rootReducer = combineReducers<IState>({
	auth: authReducer,
	public: publicReducer,
	userProjects: userProjectsReducer,
	commonProjects: commonProjectsReducer,
	maket: maketreducer,
	purchases: purchaseReducer,
	filter: filterReducer,
	notify: notifyReducer,
	tariffs: tariffsReducer,
	favorites: favoritesReducer,
	investmentPrograms: investmentProgramsReducer,
	developmentSchemesAndPrograms: developmentSchemesAndProgramsReducer,
	adminInvestmentPrograms: adminInvestmentProgramsReducer,
	adminDevelopmentSchemesAndPrograms: adminDevelopmentSchemesAndProgramsReducer,
	media: mediaReducer
})

export default rootReducer
