import React from 'react'

type PassportSVG = {
	disabled?: boolean
}

export const PassportSVG: React.FC<PassportSVG> = ({ disabled }) => {
	const fillColor = disabled ? '#AFAFAF' : '#1BAAF0';
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M6.22211 3H16.8929C18.2817 3.00589 19.5414 3.18859 20.3934 4.05211C21.2468 4.91721 21.4367 6.20655 21.4443 7.64839L21.4444 7.65393L21.4443 19.3437C21.4501 20.803 21.2682 22.109 20.407 22.978C19.5479 23.8448 18.2749 24.011 16.8847 23.9995H6.22211C5.519 23.9993 4.69548 23.9209 4.11279 23.3316C3.52743 22.7396 3.44418 21.8961 3.44434 21.1617V5.82392C3.45422 5.07882 3.55536 4.24637 4.13838 3.66333C4.7198 3.08188 5.52962 3.0014 6.22014 3L6.22211 3ZM6.22313 5.04319C5.92511 5.04384 5.7399 5.06647 5.62516 5.09589C5.57884 5.10776 5.55228 5.11861 5.53888 5.12518C5.53188 5.13916 5.5201 5.16729 5.50695 5.21691C5.47531 5.33627 5.44883 5.5311 5.44434 5.84495V21.1617C5.44426 21.4807 5.46767 21.6774 5.4974 21.7969C5.50742 21.8372 5.51659 21.8625 5.52284 21.877C5.53738 21.8835 5.56286 21.893 5.60369 21.9034C5.7218 21.9333 5.91329 21.9562 6.22211 21.9563H16.8972C18.2609 21.968 18.7697 21.7583 19.0015 21.5244C19.2316 21.2922 19.4501 20.7711 19.4443 19.3498L19.4443 19.3455V7.65684C19.4366 6.26118 19.2197 5.74067 18.9847 5.50252C18.7482 5.26277 18.238 5.04913 16.8867 5.04319H6.22313ZM7.11089 15.5998C7.11089 15.0356 7.55861 14.5782 8.11089 14.5782H16.7776C17.3298 14.5782 17.7776 15.0356 17.7776 15.5998C17.7776 16.164 17.3298 16.6214 16.7776 16.6214H8.11089C7.55861 16.6214 7.11089 16.164 7.11089 15.5998ZM7.11089 18.7656C7.11089 18.2014 7.55861 17.744 8.11089 17.744H16.7776C17.3298 17.744 17.7776 18.2014 17.7776 18.7656C17.7776 19.3298 17.3298 19.7872 16.7776 19.7872H8.11089C7.55861 19.7872 7.11089 19.3298 7.11089 18.7656Z'
				fill={fillColor}
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M15.7043 2.04093L6.21088 4.32032C5.81915 4.41438 5.61208 4.46583 5.47065 4.5183C5.46865 4.51904 5.4667 4.51977 5.4648 4.52049C5.46454 4.52251 5.46429 4.52458 5.46403 4.52669C5.44603 4.67647 5.44435 4.88982 5.44435 5.29269V5.48668C5.44435 6.03897 4.99663 6.48668 4.44435 6.48668C3.89206 6.48668 3.44435 6.03897 3.44435 5.48668L3.44435 5.29269C3.44435 5.27745 3.44435 5.2622 3.44434 5.24696C3.44427 4.90843 3.4442 4.57187 3.47833 4.28798C3.51722 3.96448 3.61039 3.60022 3.8737 3.2662C4.13701 2.93218 4.46946 2.75655 4.77494 2.64321C5.04301 2.54374 5.37029 2.46523 5.69948 2.38626C5.7143 2.38271 5.72912 2.37915 5.74394 2.3756L15.2725 0.087749C15.2947 0.0824195 15.3171 0.0778515 15.3396 0.0740533C16.295 -0.0871641 17.1112 0.0183733 17.7827 0.356282C18.4509 0.692557 18.8806 1.211 19.1509 1.71024C19.4173 2.20251 19.5423 2.69862 19.6029 3.05539C19.6339 3.23725 19.6496 3.39216 19.6577 3.50513C19.6618 3.56184 19.664 3.6086 19.6651 3.64363C19.6657 3.66116 19.6661 3.67581 19.6662 3.68735L19.6664 3.70232L19.6665 3.70803L19.6665 3.71042L19.6665 3.71151C19.6665 3.71202 19.6665 3.71251 18.6665 3.71248L19.6665 3.71251C19.6664 4.2648 19.2187 4.7125 18.6664 4.71248C18.1163 4.71246 17.6699 4.26822 17.6665 3.71892L17.6662 3.71004C17.6659 3.69866 17.665 3.6775 17.6629 3.64814C17.6586 3.58898 17.6497 3.49917 17.6312 3.39071C17.5932 3.16682 17.5206 2.89985 17.392 2.66236C17.2672 2.43185 17.1043 2.25386 16.8837 2.14282C16.67 2.03528 16.3099 1.94391 15.7043 2.04093Z'
				fill={fillColor}
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M12.3103 6.87248C13.0607 6.18362 14.2402 6.92655 13.9431 7.90087L13.5541 9.17632L13.8073 9.50187C14.1423 9.93234 14.0705 10.5518 13.646 10.8942L11.2677 12.8129C10.4163 13.4998 9.23842 12.517 9.76165 11.5563L10.3406 10.4933L10.263 10.4423C9.71799 10.085 9.65498 9.31015 10.1351 8.86943L12.3103 6.87248ZM12.9866 7.60914L10.8113 9.60608L10.889 9.65701C11.3244 9.94251 11.4679 10.5143 11.2188 10.9716L10.6398 12.0346L13.0181 10.116L12.7648 9.7904C12.565 9.53365 12.5026 9.19576 12.5976 8.88459L12.9866 7.60914Z'
				fill={fillColor}
			/>
			<path
				d='M10.8113 9.60608L12.9866 7.60914L12.5976 8.88459C12.5026 9.19576 12.565 9.53365 12.7648 9.7904L13.0181 10.116L10.6398 12.0346L11.2188 10.9716C11.4679 10.5143 11.3244 9.94251 10.889 9.65701L10.8113 9.60608Z'
				fill={fillColor}
			/>
		</svg>
	)
}
