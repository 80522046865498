import React, { MouseEvent } from 'react'
import { IUnc } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
export const titleData = [
	'Строительство ПС',
	'Реконструкция ПС',
	'Строительство (реконструкция) КТП, ТП, РТП, СП',
	'Строительство (реконструкция) ВЛ',
	'Строительство (реконструкция) КЛ 0,4 - 500 кВ',
]

export const cellData = (
	getNumberUnc: (id: string) => number | string,
	handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
) => [
	{
		value: (value: IUnc) => getNumberUnc(value.id),
		textAlign: 'textAlignRight',
	},
	{
		value: (value: IUnc) => value.uncCell.code,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: IUnc) => value.uncParameter.thName,
		textAlign: 'textAlignLeft',
	},
	{
		tooltip: 'Комментарий',
		textAlign: 'textAlignCenter',
		value: (value: IUnc) => {
			return (
				value.comment && (
					<div
						onClick={(event: MouseEvent<HTMLDivElement>) =>
							handleShowComment && handleShowComment(event, value.comment)
						}
						data-popup='comment-popup'
						className='comment-icon'
					/>
				)
			)
		},
	},
	{
		value: (value: IUnc) => value.count,
		textAlign: 'textAlignRight',
	},
	{
		value: (value: IUnc) => value.uncMain.unit,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: IUnc) => value.uncCell.parameter,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: IUnc) => value.uncCell.voltageValue,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: IUnc) => prettyCost(value.cost / 1000),
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: IUnc) => prettyCost(value.totalCost / 1000),
		textAlign: 'textAlignLeft',
	},
]
