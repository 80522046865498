import { ICommonProject } from '@root-gipro/store/interfaces'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { prettyCost } from '@root-gipro/utils/helpers/common'

export const collapseItemsRightColumn = (project: ICommonProject) => [
	{
		type: 'text',
		title: 'Оценка полной стоимости по УНЦ в прогнозных ценах (с НДС)',
		value: addHTML(prettyCost(project.valuationUncPlanForecast / 10 ** 6)),
	},
	{
		type: 'text',
		title: 'Остаток финансирования в прогнозных ценах (с НДС)',
		value: addHTML(prettyCost(project.totalInvestmentPlan / 10 ** 6)),
	},
]
