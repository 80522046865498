import React, { ReactText } from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { ListItemText, ListItem, List, Collapse } from '@material-ui/core/'
import { ICommonProject } from '@root-gipro/store/interfaces'
import { IRgStatuses } from '@root-gipro/store/interfaces'

export interface OptionsProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	policesGipro: boolean
	policesRGCO: boolean
	rgStatusId: string | undefined
	copyHrefToClipboard: () => void
	downloadCommonProject(id: ReactText, code: string): void
	project: ICommonProject | null
	addToUserProject(projectId: string | number): void
	getRgStatusId: () => void
	rgStatuses: IRgStatuses[]
	toggler?: boolean
	togglerSubmenu?(): void
	downloadUncTechnicalSpecifications?(id: ReactText, code: string): void
}

const ParamsOptions: React.FC<OptionsProps> = ({
	anchorEl,
	setAnchorEl,
	policesGipro,
	policesRGCO,
	rgStatusId,
	copyHrefToClipboard,
	downloadCommonProject,
	project,
	addToUserProject,
	getRgStatusId,
	rgStatuses,
	toggler,
	togglerSubmenu,
}) => {
	return (
		project && (
			<DropDown
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				style={{ borderRadius: 4 }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<>
					<List className='drop-down__list' component='nav' aria-label='main mailbox folders'>
						{/* <ListItem button className={`drop-down-item ${toggler && 'open'}`} onClick={togglerSubmenu}>
						<span className='dropdown-icon-bold icon-size' />
						<ListItemText primary='Выгрузки' />
					</ListItem>
					<Collapse in={toggler} unmountOnExit>
						<div className='pl-24'>
							<ListItem
								button
								className='drop-down-item '
								onClick={() => window.open(`http://dev.gi-pro.ru/files/unc_oa.xlsx`)}
							>
								<span className='download-icon icon-size' />
								<ListItemText primary='УНЦ-ОА' />
							</ListItem>
							<ListItem
								button
								className='drop-down-item '
								onClick={() => window.open(`http://dev.gi-pro.ru/files/sd.xlsx`)}
							>
								<span className='download-icon icon-size' />
								<ListItemText primary='СД' />
							</ListItem>
							<ListItem
								button
								className='drop-down-item '
								onClick={() => window.open(`http://dev.gi-pro.ru/files/equipment.xlsx`)}
							>
								<span className='download-icon icon-size' />
								<ListItemText primary='Оборудование' />
							</ListItem>
						</div>
					</Collapse> */}
						<ListItem
							button
							className='drop-down-item '
							onClick={() => downloadCommonProject(project.id, project.code)}
						>
							<span className='download-icon icon-size ' /> <ListItemText primary='Скачать' />
						</ListItem>
						<ListItem button className='drop-down-item ' onClick={() => addToUserProject(project.id)}>
							<span className='copy-icon icon-size ' />
							<ListItemText primary='Скопировать в расчеты' />
						</ListItem>
						<ListItem button className='drop-down-item ' onClick={copyHrefToClipboard}>
							<span className='repost-icon icon-size ' />
							<ListItemText primary='Поделиться' />
						</ListItem>
						{policesRGCO && (
							<ListItem
								button
								className={`drop-down-item ${rgStatusId === '4' ? 'link-disabled' : ''}`}
								onClick={() => {
									if (rgStatusId && rgStatusId !== '4') {
										return window.open(`https://rg.unc-esk.ru/ispoldocums?find=${project.code}`)
									}
									if (!rgStatusId) {
										return getRgStatusId()
									}
								}}
							>
								<span className='query-icon icon-size ' />
								<ListItemText primary={rgStatusId ? rgStatuses[parseInt(rgStatusId) - 1].title : `Запросить ИД`} />
							</ListItem>
						)}
					</List>
				</>
			</DropDown>
		)
	)
}

export default ParamsOptions
