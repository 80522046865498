import React, { ReactText, FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '@root-gipro/shared/Loader/Loader'
import { Rates } from '@root-gipro/modules/commonProjects/components'
import {
	downloadCommonProject,
	getUncsCommonProject,
	requestRgStatuses,
	addToUserProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import ym from 'react-yandex-metrika'

const RatesContainer: FC = () => {
	const { selectedProject, selectedProjectUncs } = useSelector((state: IState) => state.commonProjects)
	const { policesRGCO, policesGipro, rgStatuses } = useSelector((state: IState) => state.public)
	const dispatch = useDispatch()

    useEffect(() => {
        ym('hit', '/rates', selectedProject?.id)
    }, [])

	return selectedProject ? (
		<Rates
			rgStatuses={rgStatuses}
			uncs={selectedProjectUncs}
			selectedProject={selectedProject}
			getUncsCommonProject={(id: ReactText) => dispatch(getUncsCommonProject(id))}
			downloadCommonProject={(id: ReactText, code: string) => dispatch(downloadCommonProject(id, code))}
			addToUserProject={(projectId: ReactText) => dispatch(addToUserProject(projectId))}
			requestRgStatuses={(projectId: ReactText) => dispatch(requestRgStatuses(projectId))}
			policesRGCO={policesRGCO}
			policesGipro={policesGipro}
		/>
	) : (
		<Loader />
	)
}

export default RatesContainer
