import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { FilterItemCheckboxSearchUncProps } from '../models/filter-item-checkbox-search-unc.props'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		marginLeft: 0,
	},
	label: {
		position: 'relative',
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesCheckboxStyle = makeStyles(theme => ({
	root: {
		padding: '0',
		marginRight: 8,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

		backgroundColor: '#fff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.0)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '##fff',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(255,255,255,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#1baaf0',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#1baaf0',
		},
	},
}))

function HtmlString(html: string) {
	return <span dangerouslySetInnerHTML={{ __html: html }} />
}

const FilterItemCheckboxSearchUnc: React.FC<FilterItemCheckboxSearchUncProps> = ({
	value,
	setValue,
	queryValue,
	name,
	isDisable,
	isChecked,
	fieldName,
	updateData,
}) => {
	const classes = classesFormControlLabelStyle()
	const classesCheckbox = classesCheckboxStyle()

	const [checked, setChecked] = useState(false)

	useEffect(() => {
		isChecked.includes(name) ? setChecked(true) : setChecked(false)
	}, [isChecked])

	const handleChange = () => {
		setChecked(prev => !prev)
		updateData(name)
		// setValue(fieldName, name)
	}

	return (
		<div className='aside-filter__checkbox'>
			<FormControlLabel
				control={
					<>
						<Checkbox
							disabled={isDisable}
							checked={checked}
							onChange={handleChange}
							color='primary'
							size='small'
							className={classesCheckbox.root}
							checkedIcon={<span className={clsx(classesCheckbox.icon, classesCheckbox.checkedIcon)} />}
							icon={<span className={classesCheckbox.icon} />}
						/>
					</>
				}
				label={
					<>
						<span>{HtmlString(name.replace(new RegExp(queryValue, 'gi'), match => `<b>${match}</b>`))}</span>
					</>
				}
				classes={classes}
			/>
		</div>
	)
}

export default FilterItemCheckboxSearchUnc
