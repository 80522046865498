import React from 'react'
import uniqueId from 'lodash/uniqueId'
import { CardListProps } from '@root-gipro/shared/Card/CardList/card-list.props'

const CardList: React.FC<CardListProps> = ({ title, values, limit }) => {

	const dots = (limit && values && (values.length > limit)) ? '...' : ''
	values = (limit && values) ? values.slice(0, limit) : []

	return (
		<div key={uniqueId('cardRightItem_')} className='card-container__right__item'>
			<div className='card-container__right__item-title'>{title}</div>

			{values && values.map((value) => (
					<div key={uniqueId('cardRightListItem_')} className='card-container__right__item-value'>{value}</div>
				))
			}
			{dots}
		</div>

	)
}

export default CardList
