import { Box, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import { FilterProps } from '@root-gipro/modules/filter/models/props/filter.model'
import { UncList } from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncList'
import { Filter as FilterWrapper, FilterItemGroup } from '@root-gipro/shared/Filter'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'
import TooltipHelp from '@root-gipro/shared/Tooltips/TooltipHelp'
import { ICompany, IGroup, IRegion, IState } from '@root-gipro/store/interfaces'
import { capitalizeFirstLetter, formatDate } from '@root-gipro/utils/helpers/common'
import { isEqual, isNil } from 'lodash'
import '@root-gipro/modules/userProjects/styles/UncAdd.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FilterSwitcher from './FilterSwitcher'
import { Modal } from '@material-ui/core/'
import { Backdrop } from '@material-ui/core/'
import { SelectTable } from '@root-gipro/modules/userProjects/components/Project/UncAdd/SelectTable'
import { setActualFilter } from '../store/actions'
import Directions from '@root-gipro/modules/userProjects/components/Project/UncAdd/Directions'
import PresetFilters from './PresetFilters/PresetFilters'
import PresetFiltersModal from './Modal/PresetFiltersModal/PresetFiltersModal'

const useStyleRadio = makeStyles(theme => ({
	root: {
		margin: '0',
		gap: '4px',
		'& .MuiTypography-body1': {
			fontSize: '14px',
			lineHeight: 1.5,
		},
	},
}))

const priceRadioInfo: { [key: string]: { [key: string]: string } } = {
	priceUnc: {
		name: 'По УНЦ',
		fieldNameStartPurchases: 'project.valuationUncOfferForecast.min',
		fieldNameStartCommonProjects: 'valuationUncOfferForecast.min',
		fieldNameEndPurchases: 'project.valuationUncOfferForecast.max',
		fieldNameEndCommonProjects: 'valuationUncOfferForecast.max',
		isDisablePurchases: 'project.valuationUncOfferForecast',
		isDisableCommonProjects: 'valuationUncOfferForecast',
	},
	priceForecast: {
		name: 'Остаток финансирования в прогнозных ценах',
		fieldNameStartCommonProjects: 'valuationOfferForecast.min',
		fieldNameEndPurchases: 'project.valuationOfferForecast.max',
		fieldNameEndCommonProjects: 'valuationOfferForecast.max',
		isDisablePurchases: 'project.valuationUncOfferForecast',
		isDisableCommonProjects: 'valuationUncOfferForecast',
	},
	pricePsd: {
		name: 'Полная сметная стоимость согласно ПСД (с НДС)',
		fieldNameStartPurchases: 'project.estimatedOfferCurrent.min',
		fieldNameStartCommonProjects: 'estimatedOfferCurrent.min',
		fieldNameEndPurchases: 'project.estimatedOfferCurrent.max',
		fieldNameEndCommonProjects: 'estimatedOfferCurrent.max',
		isDisablePurchases: 'project.estimatedOfferYM',
		isDisableCommonProjects: 'estimatedOfferYM',
	},
}

const Filter: React.FC<FilterProps> = ({
	resetFilter,
	refreshFilterState,
	showFilterResult,
	filterCountResult,
	filterState,
	purchasesType,
	uncInfo,
	purchasesMethods,
	purchasesStages,
	purchasesOkpd,
	purchasesOkved,
	regions,
	groups,
	company,
	rgStatuses,
	publicationDates,
	purchasePublishDates,
	uncThs,
	uncCells,
	uncThDescriptions,
	uncMains,
	minMaxValues,
	policesRGCO,
	uncTechnicalSpecifications,
}) => {
	const classes = useStyleRadio()

	const [currentFilter, setCurrentFilter] = useState<boolean>(false)
	const [priceValue, setPriceValue] = useState<string>('priceWithout')
	const [is_actualFilter, setIsActualFilter] = useState<number>(1)
	const [filterValueEmpty, setFilterValueEmpty] = useState<{ [key: string]: any }>({})

	let location = useLocation()
	const url: string = location.pathname.slice(1)
	const { filter } = useSelector((state: IState) => state)
	const { isSimilar } = useSelector((state: IState) => state.filter.commonProjects)
	const { uncTableData } = useSelector((state: IState) => state.userProjects)

	const currentFilterState =
		url === 'purchases'
			? filter.purchases
			: url === 'purchases/table'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'common-projects/table'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects
	const { resultFilterState, showFilterState } = useSelector((state: IState) => currentFilterState)

	useEffect(() => {
		if (Object.keys(resultFilterState).length === 0) {
			setPriceValue('priceWithout')
		} else if (Object.keys(resultFilterState).includes('valuationUncOfferForecast.max')) {
			setPriceValue('priceUnc')
		} else if (Object.keys(resultFilterState).includes('estimatedOfferCurrent.max')) {
			setPriceValue('pricePsd')
		} else if (Object.keys(resultFilterState).includes('valuationOfferForecast.max')) {
			setPriceValue('priceForecast')
		}
		showChevronsFilter()
	}, [resultFilterState])

	useEffect(() => {
		setCurrentFilter(url === 'purchases' || url === 'favorites-purchases' || url === 'purchases/table' ? true : false)
	}, [url])

	useEffect(() => {
		if (typeof filterState['actual'] === 'number' || filterState['actual']) {
			setIsActualFilter(Number(filterState['actual']))
		} else {
			setIsActualFilter(1)
		}
	}, [filterState])

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if ((value !== undefined || value !== '') && !isEqual(value, (filterState as Record<string, string>)[fieldName])) {
			if (fieldName === 'uncTechnicalSpecification.name') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.paramName') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.param') {
			}
			if (filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name']?.length > 1) {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'priceWithout') {
				setPriceValue('priceWithout')
			}
			refreshFilterState!(fieldName, value, url)
		}
	}

	const handleChangePrice = (value: string) => {
		if (value === 'priceWithout') {
			refreshFilterValue('priceWithout', '0')
		}
		setPriceValue(value)
	}

	const handleChangeSetActual = (value: number) => {
		//@ts-ignore
		refreshFilterValue('actual', value)

		// setIsActualFilter(value)
	}

	const setChevronsCommonProjects = () => {
		if (resultFilterState.groupId) {
			setFilterValueEmpty(prev => ({ ...prev, groupId: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, groupId: false }))
		}
		if (resultFilterState.regionId) {
			setFilterValueEmpty(prev => ({ ...prev, regionId: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, regionId: false }))
		}

		// период min
		if (
			resultFilterState['yearBegin.min'] &&
			typeof resultFilterState['yearBegin.min'] === 'string' &&
			Number(resultFilterState['yearBegin.min']?.split('-')[0]) !== Number(minMaxValues?.yearBegin.min)
		) {
			setFilterValueEmpty(prev => ({ ...prev, periodMin: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, periodMin: false }))
		}
		// период max
		if (
			resultFilterState['yearEndOffer.max'] &&
			typeof resultFilterState['yearEndOffer.max'] === 'string' &&
			Number(resultFilterState['yearEndOffer.max']?.split('-')[0]) !== Number(minMaxValues?.yearEndOffer.max)
		) {
			setFilterValueEmpty(prev => ({ ...prev, periodMax: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, periodMax: false }))
		}

		// стоимость проекта
		if (!resultFilterState['priceWithout'] && priceValue !== 'priceWithout') {
			setFilterValueEmpty(prev => ({ ...prev, price: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, price: false }))
		}

		//остаток финансирования min
		if (
			resultFilterState['balanceOffer.min'] &&
			typeof resultFilterState['balanceOffer.min'] === 'number' &&
			Math.round(Number(resultFilterState['balanceOffer.min'])) !== Math.round(Number(minMaxValues?.balanceOffer.min))
		) {
			setFilterValueEmpty(prev => ({ ...prev, balanceOfferMin: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, balanceOfferMin: false }))
		}
		//остаток финансирования max
		if (
			resultFilterState['balanceOffer.max'] &&
			typeof resultFilterState['balanceOffer.max'] === 'number' &&
			Number(resultFilterState['balanceOffer.max']) !== Number(minMaxValues?.balanceOffer.max)
		) {
			setFilterValueEmpty(prev => ({ ...prev, balanceOfferMax: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, balanceOfferMax: false }))
		}

		// строительные ресурсы
		if (
			resultFilterState['uncTechnicalSpecification.name'] ||
			resultFilterState['uncTechnicalSpecification.paramName'] ||
			resultFilterState['uncTechnicalSpecification.param']
		) {
			setFilterValueEmpty(prev => ({ ...prev, uncTechnicalSpecification: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, uncTechnicalSpecification: false }))
		}

		// технические решения
		if (
			resultFilterState['uncCellId'] ||
			resultFilterState['uncCell.uncThId'] ||
			resultFilterState['uncCell.uncTextParameterId'] ||
			resultFilterState['uncCell.voltageValue'] ||
			resultFilterState['uncCell.uncMainId'] ||
			resultFilterState['uncCell.number'] ||
			resultFilterState['uncCell.subNumber'] ||
			resultFilterState['unc.count.min'] ||
			resultFilterState['unc.count.max'] ||
			resultFilterState['rgStatusId']
		) {
			setFilterValueEmpty(prev => ({ ...prev, uncCell: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, uncCell: false }))
		}
	}
	const setChevronsPurchases = () => {
		if (resultFilterState.company_id) {
			setFilterValueEmpty(prev => ({ ...prev, groupId: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, groupId: false }))
		}
		if (resultFilterState.region_code) {
			setFilterValueEmpty(prev => ({ ...prev, regionId: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, regionId: false }))
		}

		// purchases
		if (resultFilterState['registration_number'] || resultFilterState['subject'] || resultFilterState['existsPIR']) {
			setFilterValueEmpty(prev => ({ ...prev, purchases: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, purchases: false }))
		}

		// paramPurchases
		if (
			resultFilterState['type'] ||
			resultFilterState['kind'] ||
			resultFilterState['status'] ||
			resultFilterState['okpd2'] ||
			resultFilterState['okved2']
		) {
			setFilterValueEmpty(prev => ({ ...prev, paramPurchases: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, paramPurchases: false }))
		}

		// publishDate
		if (
			resultFilterState['publish_date'] ||
			resultFilterState['request_receiving_end_date.min'] ||
			resultFilterState['request_receiving_end_date.max']
		) {
			setFilterValueEmpty(prev => ({ ...prev, publishDate: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, publishDate: false }))
		}

		// period
		if (resultFilterState['price.min'] || resultFilterState['price.max']) {
			setFilterValueEmpty(prev => ({ ...prev, period: true }))
		} else {
			setFilterValueEmpty(prev => ({ ...prev, period: false }))
		}
	}

	const showChevronsFilter = () => {
		if (
			url === 'common-projects' ||
			url === 'favorites-common-projects' ||
			url === 'favorites-common-projects/table' ||
			url === 'common-projects/table'
		) {
			setChevronsCommonProjects()
		} else if (
			url === 'purchases' ||
			url === 'favorites-purchases' ||
			url === 'favorites-purchases/table' ||
			url === 'purchases/table'
		) {
			setChevronsPurchases()
		}
	}

	const dispatch = useDispatch()
	const isFilterUncsModalState = useSelector((state: IState) => state.filter.isFilterUncsModalState)
	const [showDirections, setShowDirections] = useState(false)

	useEffect(() => {
		dispatch(setActualFilter(is_actualFilter))
	}, [is_actualFilter])

	const cleanObject = (obj: any) => {
		const keysToPurchases = ['kind', 'type', 'okpd2', 'status', 'okved2']
		keysToPurchases.forEach(key => {
			if (Array.isArray(obj[key])) {
				refreshFilterValue(key, [])
			}
		})
	}

	return (
		<>
			<FilterWrapper resultCnt={filterCountResult} resetFilterHandler={resetFilter} showFilterResult={showFilterResult}>
				<FilterItemGroup tagsFilter={['user-projects']} name='Проект' label='project'>
					<FilterItem
						name='Идентификатор'
						filterState={filterState}
						mode='input'
						fieldName={currentFilter ? 'project.code' : 'code'}
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Наименование'
						filterState={filterState}
						mode='input'
						fieldName={currentFilter ? 'project.name' : 'name'}
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>

				<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Проект' label='project'>
					<FilterItem
						name='Идентификатор'
						filterState={filterState}
						mode='input'
						fieldName={currentFilter ? 'project.code' : 'code'}
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Наименование'
						filterState={filterState}
						mode='textarea'
						fieldName={currentFilter ? 'project.name' : 'name'}
						setValue={refreshFilterValue}
						readonly={isSimilar}
					/>
				</FilterItemGroup>

				<FilterItemGroup tagsFilter={['common-projects']} name='Проект' label='project'>
					<FilterItem
						name='Интеллектуальный режим'
						filterState={filterState}
						mode='switcher'
						fieldName='projectSimilarId'
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>
				<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Проект' label='project'>
					<FilterItem
						name='Проектно-изыскательские работы'
						filterState={filterState}
						mode='checkbox'
						fieldName='existsPIR'
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Содержат компонентную базу'
						filterState={filterState}
						mode='checkbox'
						fieldName='existsUNC'
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Есть связанные закупки'
						filterState={filterState}
						mode='checkbox'
						fieldName='withPurchases'
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Есть паспорт'
						filterState={filterState}
						mode='checkbox'
						fieldName='withPassports'
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Актуальность'
					label='actual'
					isNoEmpty={!isNil(filterState['actual']) && filterState['actual'] !== 1}
				>
					<RadioGroup
						aria-labelledby='demo-radio-buttons-group-label'
						value={is_actualFilter}
						className='aside-filter__radioPrice'
						onChange={e => {
							handleChangeSetActual(Number(e.target.value))
						}}
						name='radio-buttons-group'
					>
						<FormControlLabel
							value={1}
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Только актуальные проекты'
						/>
						<FormControlLabel
							value={2}
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Все проекты'
						/>
						<FormControlLabel
							value={0}
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Только неактуальные проекты'
						/>
					</RadioGroup>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Предустановленные фильтры'
					label=''
					isNoEmpty={false}
				>
					<PresetFilters />
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'favorites-purchases']}
					name='Закупка'
					isNoEmpty={filterValueEmpty?.purchases}
					label='purchases'
				>
					<FilterItem
						name='Идентификатор'
						filterState={filterState}
						mode='input'
						fieldName='registration_number'
						setValue={refreshFilterValue}
					/>
					<FilterItem
						name='Наименование'
						filterState={filterState}
						mode='textarea'
						fieldName='subject'
						setValue={refreshFilterValue}
					/>

					<FilterItem
						name='Только проектно-изыскательские работы'
						filterState={filterState}
						mode='checkbox'
						fieldName='existsPIR'
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'favorites-purchases']}
					name='Параметры закупки'
					isNoEmpty={filterValueEmpty?.paramPurchases}
					label='paramPurchases'
				>
					<button type='button' className='clear_purchases_button' onClick={() => cleanObject(filterState)}>
						Сбросить
					</button>

					<FilterItem
						isMultiple
						placeholder='Способ определения поставщика (подрядчика, исполнителя)'
						filterState={filterState}
						mode='select'
						fieldName='type'
						setValue={refreshFilterValue}
						items={
							purchasesMethods && purchasesMethods.length > 0
								? purchasesMethods.filter(method => method).map(method => capitalizeFirstLetter(method))
								: []
						}
					/>
					<FilterItem
						isMultiple
						placeholder='Тип закупки'
						filterState={filterState}
						mode='select'
						fieldName={`kind`}
						setValue={refreshFilterValue}
						items={['Товар', 'Работа', 'Услуга']}
					/>
					<FilterItem
						isMultiple
						placeholder='Статус закупки'
						filterState={filterState}
						mode='select'
						fieldName='status'
						setValue={refreshFilterValue}
						items={
							purchasesStages && purchasesStages.length > 0
								? purchasesStages.filter(stage => stage).map(stage => capitalizeFirstLetter(stage))
								: []
						}
					/>
					<FilterItem
						isMultiple
						placeholder='ОКПД'
						filterState={filterState}
						mode='select'
						fieldName='okpd2'
						setValue={refreshFilterValue}
						items={
							purchasesOkpd && purchasesOkpd.length > 0
								? purchasesOkpd.filter(okpd => okpd.code).map(okpd => `${okpd.code} ${okpd.name}`)
								: []
						}
					/>
					<FilterItem
						isMultiple
						placeholder='ОКВЭД'
						filterState={filterState}
						mode='select'
						fieldName='okved2'
						setValue={refreshFilterValue}
						items={
							purchasesOkved && purchasesOkved.length > 0
								? purchasesOkved.filter(okved => okved.code).map(okved => `${okved.code} ${okved.name}`)
								: []
						}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'common-projects', 'favorites-common-projects', 'favorites-purchases']}
					name='Организация'
					isNoEmpty={filterValueEmpty?.groupId}
					label='group'
				>
					<FilterItem
						isMultiple
						placeholder='Наименование'
						filterState={filterState}
						mode='search-checkbox'
						fieldName={currentFilter ? 'company_id' : 'groupId'}
						setValue={refreshFilterValue}
						items={
							currentFilter
								? company
										?.map((company: ICompany) => ({
											name: company.name,
											id: company.id,
											projectsCount: company.projectsCount,
										}))
										.sort() || []
								: groups
										?.map((group: IGroup) => ({ name: group.name, id: group.id, projectsCount: group.projectsCount }))
										.sort() || []
						}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'common-projects', 'favorites-common-projects', 'favorites-purchases']}
					name='Регион'
					isNoEmpty={filterValueEmpty?.regionId}
					label='region'
				>
					<FilterItem
						isMultiple
						placeholder='Регион'
						filterState={filterState}
						mode='search-checkbox'
						fieldName={currentFilter ? 'region_code' : 'regionId'}
						setValue={refreshFilterValue}
						items={
							regions?.map((region: IRegion) => ({
								name: region.name,
								id: region.id,
								projectsCount: currentFilter ? region.purchasesCount : region.projectsCount,
								code: String(region?.code),
							})) || []
						}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Период'
					isNoEmpty={filterValueEmpty?.periodMin || filterValueEmpty?.periodMax}
					label='period'
				>
					<FilterItem
						name='Период реализации'
						filterState={filterState}
						mode='dates-range'
						minMax={minMaxValues}
						fieldNameStart='yearBegin.min'
						fieldNameEnd='yearEndOffer.max'
						setValue={refreshFilterValue}
						dateFormat='yyyy'
						dateOpenTo='year'
						dateViews={['year']}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Стоимость проекта'
					isNoEmpty={filterValueEmpty?.price}
					label='pricing'
				>
					<RadioGroup
						aria-labelledby='demo-radio-buttons-group-label'
						value={priceValue}
						className='aside-filter__radioPrice'
						onChange={e => handleChangePrice(e.target.value)}
						name='radio-buttons-group'
					>
						<FormControlLabel
							value='priceWithout'
							control={<Radio color='primary' size='small' />}
							classes={classes}
							label='Не учитывать стоимость'
						/>
						<Box className='aside-filter__radioPrice-item'>
							<FormControlLabel
								value='pricePsd'
								control={<Radio color='primary' size='small' />}
								classes={classes}
								label='Полная сметная стоимость согласно ПСД (с НДС)'
							/>
							<TooltipHelp title='Метод определения стоимости инвестиционного проекта на основании существующей проектно-сметной документации'></TooltipHelp>
						</Box>
						<Box className='aside-filter__radioPrice-item'>
							<FormControlLabel
								value='priceUnc'
								control={<Radio color='primary' size='small' />}
								classes={classes}
								label='Оценка полной стоимости по УНЦ'
							/>
							<TooltipHelp title='Метод определения стоимости инвестиционного проекта с применением справочника укрупненных нормативов цены типовых технологических решений капитального строительства объектов электроэнергетики в части объектов электросетевого хозяйства, утвержденного приказом Министерства энергетики РФ от 17.01.2019г. №10'></TooltipHelp>
						</Box>
						<Box className='aside-filter__radioPrice-item'>
							<FormControlLabel
								value='priceForecast'
								control={<Radio color='primary' size='small' />}
								classes={classes}
								label='Оценка в прогнозных ценах'
							/>
							<TooltipHelp title='Метод определения прогнозной стоимости инвестиционного проекта по Приказу Министерства энергетики РФ от 5 мая 2016 г. №380'></TooltipHelp>
						</Box>
					</RadioGroup>
					{priceValue !== 'priceWithout' && (
						<FilterItem
							name={priceRadioInfo[priceValue].name}
							filterState={filterState}
							mode='range-numbers'
							minMax={minMaxValues}
							fieldNameStart={
								currentFilter
									? priceRadioInfo[priceValue].fieldNameStartPurchases
									: priceRadioInfo[priceValue].fieldNameStartCommonProjects
							}
							fieldNameEnd={
								currentFilter
									? priceRadioInfo[priceValue].fieldNameEndPurchases
									: priceRadioInfo[priceValue].fieldNameEndCommonProjects
							}
							setValue={refreshFilterValue}
						/>
					)}

					{!filterState['estimatedOfferYM'] &&
					(priceValue === 'priceUnc' || priceValue === 'priceForecast' || priceValue === 'pricePsd') ? (
						<FilterItem
							name='Дата составления ПСД'
							filterState={filterState}
							mode='dates-range'
							minMax={minMaxValues}
							fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
							fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
							setValue={refreshFilterValue}
							dateOpenTo='year'
							dateViews={['year']}
						/>
					) : null}
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Остаток финансирования'
					isNoEmpty={filterValueEmpty?.balanceOfferMin || filterValueEmpty?.balanceOfferMax}
					label='balanceOffer'
				>
					<FilterItem
						name='Остаток финансирования'
						filterState={filterState}
						mode='range-numbers'
						minMax={minMaxValues}
						fieldNameStart={currentFilter ? 'project.balanceOffer.min' : 'balanceOffer.min'}
						fieldNameEnd={currentFilter ? 'project.balanceOffer.max' : 'balanceOffer.max'}
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Строительные ресурсы'
					isNoEmpty={filterValueEmpty?.uncTechnicalSpecification}
					label='uncTechnicalSpecification'
				>
					<FilterItem
						isMultiple
						placeholder='Наименование'
						filterState={filterState}
						mode='search-radio'
						fieldName={'uncTechnicalSpecification.name'}
						setValue={refreshFilterValue}
						items={uncTechnicalSpecifications?.name ? Object.values(uncTechnicalSpecifications?.name) : []}
					/>
					{filterState['uncTechnicalSpecification.name'] &&
						filterState['uncTechnicalSpecification.name'].length < 2 && (
							<FilterItem
								isMultiple
								placeholder='Параметры'
								filterState={filterState}
								mode='search-radio'
								fieldName={'uncTechnicalSpecification.paramName'}
								setValue={refreshFilterValue}
								items={
									uncTechnicalSpecifications?.paramName ? Object.values(uncTechnicalSpecifications?.paramName) : []
								}
							/>
						)}
					{filterState['uncTechnicalSpecification.paramName'] && filterState['uncTechnicalSpecification.name'] && (
						<FilterItem
							isMultiple
							placeholder='Значение'
							filterState={filterState}
							mode='search-radio'
							fieldName={'uncTechnicalSpecification.param'}
							setValue={refreshFilterValue}
							items={uncTechnicalSpecifications?.param ? Object.values(uncTechnicalSpecifications?.param) : []}
						/>
					)}
					{filterState['uncTechnicalSpecification.paramName'] &&
						filterState['uncTechnicalSpecification.name'] &&
						filterState['uncTechnicalSpecification.param'] && (
							<FilterItem
								name='Количество'
								filterState={filterState}
								mode='range-numbers'
								minMax={minMaxValues}
								fieldNameStart='unc.count.min'
								fieldNameEnd='unc.count.max'
								setValue={refreshFilterValue}
							/>
						)}
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'favorites-purchases']}
					name='Период'
					label='publishDate'
					isNoEmpty={filterValueEmpty?.publishDate}
				>
					<FilterItem
						isMultiple
						placeholder='Дата публикации закупки на площадке'
						filterState={filterState}
						mode='select'
						fieldName='publish_date'
						setValue={refreshFilterValue}
						items={purchasePublishDates.map((date: string) => formatDate(date, true)!) || []}
					/>
					<FilterItem
						name='Дата окончания подачи заявок'
						filterState={filterState}
						mode='dates-range'
						fieldNameStart='request_receiving_end_date.min'
						fieldNameEnd='request_receiving_end_date.max'
						setValue={refreshFilterValue}
						dateFormat='MM.yyyy'
						dateOpenTo='year'
						dateViews={['year']}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['purchases', 'favorites-purchases']}
					name='Начальная максимальная цена, млн руб. с НДС'
					label='period'
					isNoEmpty={filterValueEmpty?.period}
				>
					<FilterItem
						filterState={filterState}
						mode='range-numbers'
						fieldNameStart='price.min'
						fieldNameEnd='price.max'
						setValue={refreshFilterValue}
					/>
				</FilterItemGroup>
				<FilterItemGroup
					tagsFilter={['common-projects', 'favorites-common-projects']}
					name='Компонентная база'
					isNoEmpty={filterValueEmpty?.uncCell}
					label='uncCell'
				>
					<FilterSwitcher uncCells={uncCells} refreshFilterValue={refreshFilterValue} />
				</FilterItemGroup>
			</FilterWrapper>

			<Modal
				className='modal_filter_uncs'
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={isFilterUncsModalState}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<div className='scroll-wrapper'>
					<Directions
						showDirections={showDirections}
						text={uncTableData?.note ? uncTableData.note : ''}
						closeHandler={() => setShowDirections(false)}
					/>
					<div className='unc_add unc_add_filter' style={{ filter: showDirections ? 'blur(4px)' : '' }}>
						<UncList />
						<div className='wrapper-select-table'>
							<SelectTable setShowDirections={(show: boolean) => setShowDirections(show)} />
						</div>
					</div>
				</div>
			</Modal>
			<PresetFiltersModal showFilterState={showFilterState} />
		</>
	)
}
export default Filter
