import React, { FC } from 'react'
import { Tbody, TbodyProps } from '@root-gipro/shared/Table/components/Tbody'
import { Thead, TheadProps } from '@root-gipro/shared/Table/components/Thead'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'

export interface TableProps {
	theadParams: TheadProps
	bodyParams: TbodyProps
}

const Table: FC<TableProps> = ({ theadParams, bodyParams }) => {
	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<Thead {...theadParams} />
				<Tbody {...bodyParams} />
			</div>
		</div>
	)
}
export { Table }
