import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const FilterCloudPlaceholder = () => {
	return (
		<>
			<Skeleton animation='wave' variant='rect' width={140} height={32} style={{ borderRadius: '30px' }} />
			<Skeleton animation='wave' variant='rect' width={140} height={32} style={{ borderRadius: '30px' }} />
			<Skeleton animation='wave' variant='rect' width={140} height={32} style={{ borderRadius: '30px' }} />
		</>
	)
}

export default FilterCloudPlaceholder
