import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'

export function updateUrlSearch<T extends keyof IUrlsSearch>(url: T, newValue: IUrlsSearch[T]): void {
	const jsonString: string | null = localStorage.getItem('urlsSearch')

	let urlsSearhParsed: IUrlsSearch = jsonString ? JSON.parse(jsonString) : {}
	urlsSearhParsed[url] = newValue
	const updatedJsonString: string = JSON.stringify(urlsSearhParsed)
	localStorage.setItem('urlsSearch', updatedJsonString)
}
