import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'

interface СommentProps {
	comment: string
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
}

const Сomment: React.FC<СommentProps> = ({ anchorEl, setAnchorEl, comment }) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ borderRadius: 4, width: 360 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<>
				<div className='title'>Комментарий</div>
				<p className='drop-down-shrt-txt '>{comment}</p>
			</>
		</DropDown>
	)
}
export default Сomment
