import React from 'react'

export const addHTML = (number: number) => {
	if (number)
		return (
			<>
				<div>{number}</div>
				<span>млн.р</span>
			</>
		)
}
