import React from 'react'
import '@root-gipro/shared/Card/Card/Card.scss'
import '@root-gipro/shared/Card/styles/CardFull.scss'
import { ICardFull } from '@root-gipro/shared/Card/CardFull/card-full.props'

const CardFull: React.FC<ICardFull> = ({ id, classes, children }) => (
	<div id={`#${id}`} className={`card-inside-container ${classes}`}>
		{children}
	</div>
)

export default CardFull
