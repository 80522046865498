import React, { ChangeEvent, FocusEvent } from 'react'
import { Checkbox, ListItemText } from '@material-ui/core'
import { IRangePriceProps } from '@root-gipro/store/interfaces'
import { spaceFormat } from '@root-gipro/utils/helpers/helperInputFloat'
import { Input } from '@root-gipro/shared/FormElements'
import { intFormat } from '@root-gipro/utils/helpers/common'

const RangePriceFilter: React.FC<IRangePriceProps> = ({
	setStartPrice,
	setEndPrice,
	startPrice,
	endPrice,
	title,
	startText,
	endText,
	disabledAll,
	withCheckbox,
	setState,
	clearState,
}) => {
	const resetFields = () => {
		if (startPrice || endPrice) {
			setStartPrice('')
			setEndPrice('')
			if (clearState) clearState()
		}
	}

	const handlerFocusField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = intFormat(e.currentTarget.value)
		setFn(value)
	}

	const handlerChangeField = (e: ChangeEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = intFormat(e.currentTarget.value)
		setFn(value)
	}

	const handlerBlurField = (e: FocusEvent<HTMLInputElement>, setFn: FunctionStringCallback) => {
		let value = spaceFormat(e.currentTarget.value)
		setFn(value)
		if (setState) setState()
	}

	return (
		<div className='range-price'>
			<div className='range-price__head'>
				<h5 className='range-price__head-title'>{title}</h5>
				<span className='range-price__head-title link link-grey' onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<div className='range-price__fields'>
				<Input
					inputValue={startPrice}
					disabled={disabledAll || withCheckbox?.checked}
					onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setStartPrice)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setStartPrice)}
					handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setStartPrice)}
					classes='part-width'
				>
					<div className='shrt-text-input'>{startText}</div>
				</Input>
				<Input
					inputValue={endPrice}
					disabled={disabledAll || withCheckbox?.checked}
					onFocus={(e: FocusEvent<HTMLInputElement>) => handlerFocusField(e, setEndPrice)}
					handleInput={(e: ChangeEvent<HTMLInputElement>) => handlerChangeField(e, setEndPrice)}
					handleBlur={(e: FocusEvent<HTMLInputElement>) => handlerBlurField(e, setEndPrice)}
					classes='part-width'
				>
					{' '}
					<div className='shrt-text-input'>{endText}</div>
				</Input>
			</div>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox
						color='primary'
						checked={withCheckbox.checked}
						disabled={withCheckbox.disabled}
						onChange={() => {
							if (withCheckbox.checkboxChange) withCheckbox.checkboxChange()
							withCheckbox.setChecked(!withCheckbox.checked)
							if (!withCheckbox.checked) resetFields()
						}}
					/>
					<ListItemText primary={withCheckbox.label} />
				</div>
			)}
		</div>
	)
}

export default RangePriceFilter
