import produce, { Draft } from 'immer'
import { IAdminDevelopmentSchemesAndProgramsState } from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr.model'
import {
	AdminDevelopmentSchemesAndProgramsTypes,
	AdminDevelopmentSchemesAndProgramTypes,
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr.actions'

const initialState: IAdminDevelopmentSchemesAndProgramsState = {
	programId: null,
	media: null,
	mediaIsLoading: false,
	program: {
		industry: '',
		quantity: '',
		since: '',
		title: '',
		shortTitle: '',
		startedAt: '',
		finishedAt: '',
		publishedAt: '',
		resource: '',
		mediaId: null,
	},
	errors: [],
}

const investmentPrograms = (
	draft: Draft<IAdminDevelopmentSchemesAndProgramsState>,
	action: AdminDevelopmentSchemesAndProgramTypes
) => {
	switch (action.type) {
		case AdminDevelopmentSchemesAndProgramsTypes.CLEAR_DEVELOPMENT_SCHEMES_AND_PROGRAM:
			draft.programId = null
			draft.program = {
				quantity: '',
				since: '',
				title: '',
				shortTitle: '',
				startedAt: '',
				finishedAt: '',
				publishedAt: '',
				industry: '',
				resource: '',
				mediaId: null,
			}
			draft.media = null
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM:
			delete action.program.id
			draft.program = action.program
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA:
			draft.media = action.media
			draft.program.mediaId = action.media.id
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_IS_LOADING:
			return { ...draft, mediaIsLoading: action.isLoading }
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_ID:
			draft.program.mediaId = action.mediaId
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_VALIDATION_ERRORS:
			draft.errors = action.errors
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_TITLE:
			draft.program.title = action.title
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_INDUSTRY:
			draft.program.industry = action.industry
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SHORT_TITLE:
			draft.program.shortTitle = action.shortTitle
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SINCE:
			draft.program.since = action.since
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_STARTED_AT:
			draft.program.startedAt = action.startedAt
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_FINISHED_AT:
			draft.program.finishedAt = action.finishedAt
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_QUANTITY:
			draft.program.quantity = action.quantity
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_PUBLISHED_AT:
			draft.program.publishedAt = action.publishedAt
			break
		case AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_RESOURCE:
			draft.program.resource = action.resource
			break
		default:
			return draft
	}
}
export const adminDevelopmentSchemesAndProgramsReducer = produce(investmentPrograms, initialState)
