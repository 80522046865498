import React, { useState, MouseEvent, useContext, useMemo, useCallback, useEffect } from 'react'
import { Chip, IconButton } from '@material-ui/core'
import { ICommonRates, IState, IUnc, IUncCell } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import RatesFilter from './RatesFilter'
import { Info, RatesOptions, Сomment } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { ModalContext } from '@root-gipro/context/modal-context'
import '@root-gipro/modules/commonProjects/styles/Rates.scss'
import { cellData, titleData } from '@root-gipro/modules/commonProjects/models/const/rates-table-body-cell'
import { gridTemplateColumns, theadData } from '@root-gipro/modules/commonProjects/models/const/rates-table-thead.const'
import { Table } from '@root-gipro/shared/Table/container/Table'
import { TableType } from '@root-gipro/shared/Table/components/Tbody'
import { useDispatch, useSelector } from 'react-redux'
import { switchRatesFiltred } from '@root-gipro/modules/commonProjects/store/actions'
import { decode } from 'querystring'
import { useLocation } from 'react-router-dom'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isEmpty } from 'lodash'
import Loader from '@root-gipro/shared/Loader/Loader'
import { setUncCellsFullInfo } from '@root-gipro/modules/userProjects/store/actions'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'

const Rates: React.FC<ICommonRates> = ({
	uncs,
	getUncsCommonProject,
	selectedProject,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
	policesRGCO,
	policesGipro,
	rgStatuses,
}) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const url: string = location.pathname.slice(1)
	const [filteredRates, setFilteredRates] = useState<IUnc[] | null>(null)
	const { setAnchorEl, anchorEl, setOpen, open } = useContext(ModalContext)
	const [comment, setComment] = useState<string>('')
	const [anchorElCom, setAnchorElCom] = useState<HTMLElement | null>(null)
	const { loadUncs } = useSelector((state: IState) => state.commonProjects)
	const uncCodes = useSelector((state: IState) => state.filter.uncCodes)
	const [isShowFilterSelectedBtn, setIsShowFilterSelectedBtn] = useState(false)
	const handleShowComment = (event: MouseEvent<HTMLElement>, comment: string) => {
		setComment(comment)
		setAnchorElCom(event.currentTarget)
	}

	const tableObj = useMemo(() => {
		let obj: TableType = {
			1: [],
			2: [],
			3: [],
			4: [],
			5: [],
			8: [],
		}
		if (filteredRates && filteredRates.length > 0) {
			filteredRates.forEach((unc: IUnc): void => {
				let num: number = unc.tableNum
				obj[num].push(unc)
			})
		} else if (uncs && uncs.length > 0) {
			uncs.forEach((unc: IUnc): void => {
				let num: number = unc.tableNum
				obj[num] && obj[num].push(unc)
			})
		}

		delete obj[8]

		return obj
	}, [uncs, filteredRates])

	const getNumberUnc = (id: string) => {
		const index = uncs && uncs.findIndex((uncElem: IUnc) => uncElem.id === id)
		return (index || index === 0) && index + 1
	}
	const getRgStatusId = () => {
		if (selectedProject) {
			requestRgStatuses(selectedProject.id)
		}
	}

	useEffect(() => {
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}
		if (!isEmpty(urlsSearhParsed['common-projects'])) {
			const decodUrl = decode(urlsSearhParsed['common-projects'] as string)
			const uncCellsId = decodUrl.uncCellId?.toString().split(',')
			uncCellsId && uncCellsId.map(id => dispatch(setUncCellsFullInfo(id)))
			uncCellsId && setIsShowFilterSelectedBtn(Boolean(decodUrl.uncCellId))
		}
	}, [url])

	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [uncs])

	const bodyParams = useMemo(() => {
		return { gridTemplateColumns, getNumberUnc, tableObj, cellData, titleData, handleShowComment }
	}, [uncs, filteredRates])

	return (
		<>
			<div className='common-projects__rates container'>
				<div className='common-projects__rates-head'>
					<div className='common-projects__rates-title'>
						<div className='title'>Расценки УНЦ</div>
						{isShowFilterSelectedBtn ? (
							<Chip
								label='Компоненты'
								className='common-projects__rates-filter'
								onClick={() => dispatch(switchRatesFiltred(true))}
								onDelete={() => dispatch(switchRatesFiltred(false))}
							/>
						) : null}
						{isShowFilterSelectedBtn
							? uncCodes.map((unc: IUncCell) => <Chip label={unc.code} className='common-projects__rates-filter' />)
							: null}
					</div>
					<div className='common-projects__rates-actions'>
						{selectedProject && (
							<div className='rates-actions__total'>
								{prettyCost(selectedProject.valuationUncOfferForecast / 10 ** 6)} млн.р
								<IconButton type='button' data-popup='info-popup' onClick={e => setAnchorEl!(e.currentTarget)}>
									<span className='info-icon' />
								</IconButton>
								{anchorEl?.dataset.popup === 'info-popup' && <Info anchorEl={anchorEl!} setAnchorEl={setAnchorEl!} />}
							</div>
						)}
						{uncs && !!uncs?.length && (
							<div className='rates-actions__filter'>
								<IconButton
									type='button'
									data-popup='filter-popup'
									onClick={e => {
										setAnchorEl!(e.currentTarget)
										setOpen!(!open)
									}}
								>
									<span className='filter-icon' />
								</IconButton>
								{anchorEl?.dataset.popup === 'filter-popup' && (
									<RatesFilter
										uncs={uncs}
										setFilteredRates={setFilteredRates}
										setAnchorEl={setAnchorEl!}
										anchorEl={anchorEl}
									/>
								)}
								<IconButton type='button' data-popup='options-popup' onClick={e => setAnchorEl!(e.currentTarget)}>
									<span className='dots-icon' />
								</IconButton>
							</div>
						)}
						{selectedProject && anchorEl?.dataset.popup === 'options-popup' && (
							<RatesOptions
								anchorEl={anchorEl!}
								setAnchorEl={setAnchorEl!}
								policesGipro={policesGipro}
								policesRGCO={policesRGCO}
								copyHrefToClipboard={copyHrefToClipboard}
								downloadCommonProject={downloadCommonProject}
								addToUserProject={addToUserProject}
								getRgStatusId={getRgStatusId}
								rgStatuses={rgStatuses}
								project={selectedProject}
								rgStatusId={selectedProject.rgStatusId}
							/>
						)}
					</div>
				</div>
			</div>

			{loadUncs ? (
				<Loader />
			) : uncs?.length ? (
				<Table theadParams={theadParams} bodyParams={bodyParams} />
			) : (
				<div className={styles.container}>
					<div className={styles.table}>
						<span>Отсутствуют расценки</span>
					</div>
				</div>
			)}
			{anchorElCom?.dataset.popup === 'comment-popup' && (
				<Сomment comment={comment} anchorEl={anchorElCom!} setAnchorEl={setAnchorElCom} />
			)}
		</>
	)
}

export default Rates
