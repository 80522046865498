import React from 'react'

type CostWorksSVG = {
	classes?: string
}
export const CostWorksSVG: React.FC<CostWorksSVG> = ({ classes }) => {
	return (
		<svg className={classes && classes} viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.33268 12.375C5.33268 12.7397 5.19221 13.0894 4.94216 13.3473C4.69211 13.6051 4.35297 13.75 3.99935 13.75C3.64573 13.75 3.30659 13.6051 3.05654 13.3473C2.80649 13.0894 2.66602 12.7397 2.66602 12.375C2.66602 12.0103 2.80649 11.6606 3.05654 11.4027C3.30659 11.1449 3.64573 11 3.99935 11C4.35297 11 4.69211 11.1449 4.94216 11.4027C5.19221 11.6606 5.33268 12.0103 5.33268 12.375Z'
				fill='#3F4F5E'
			/>
			<path
				d='M5.33268 16.5C5.33268 16.8647 5.19221 17.2144 4.94216 17.4723C4.69211 17.7301 4.35297 17.875 3.99935 17.875C3.64573 17.875 3.30659 17.7301 3.05654 17.4723C2.80649 17.2144 2.66602 16.8647 2.66602 16.5C2.66602 16.1353 2.80649 15.7856 3.05654 15.5277C3.30659 15.2699 3.64573 15.125 3.99935 15.125C4.35297 15.125 4.69211 15.2699 4.94216 15.5277C5.19221 15.7856 5.33268 16.1353 5.33268 16.5Z'
				fill='#3F4F5E'
			/>
			<path
				d='M11.9993 13.75C12.353 13.75 12.6921 13.6051 12.9422 13.3473C13.1922 13.0894 13.3327 12.7397 13.3327 12.375C13.3327 12.0103 13.1922 11.6606 12.9422 11.4027C12.6921 11.1449 12.353 11 11.9993 11C11.6457 11 11.3066 11.1449 11.0565 11.4027C10.8065 11.6606 10.666 12.0103 10.666 12.375C10.666 12.7397 10.8065 13.0894 11.0565 13.3473C11.3066 13.6051 11.6457 13.75 11.9993 13.75Z'
				fill='#3F4F5E'
			/>
			<path
				d='M13.3327 16.5C13.3327 16.8647 13.1922 17.2144 12.9422 17.4723C12.6921 17.7301 12.353 17.875 11.9993 17.875C11.6457 17.875 11.3066 17.7301 11.0565 17.4723C10.8065 17.2144 10.666 16.8647 10.666 16.5C10.666 16.1353 10.8065 15.7856 11.0565 15.5277C11.3066 15.2699 11.6457 15.125 11.9993 15.125C12.353 15.125 12.6921 15.2699 12.9422 15.5277C13.1922 15.7856 13.3327 16.1353 13.3327 16.5Z'
				fill='#3F4F5E'
			/>
			<path
				d='M7.99935 13.75C8.35297 13.75 8.69211 13.6051 8.94216 13.3473C9.19221 13.0894 9.33268 12.7397 9.33268 12.375C9.33268 12.0103 9.19221 11.6606 8.94216 11.4027C8.69211 11.1449 8.35297 11 7.99935 11C7.64573 11 7.30659 11.1449 7.05654 11.4027C6.80649 11.6606 6.66602 12.0103 6.66602 12.375C6.66602 12.7397 6.80649 13.0894 7.05654 13.3473C7.30659 13.6051 7.64573 13.75 7.99935 13.75Z'
				fill='#3F4F5E'
			/>
			<path
				d='M9.33268 16.5C9.33268 16.8647 9.19221 17.2144 8.94216 17.4723C8.69211 17.7301 8.35297 17.875 7.99935 17.875C7.64573 17.875 7.30659 17.7301 7.05654 17.4723C6.80649 17.2144 6.66602 16.8647 6.66602 16.5C6.66602 16.1353 6.80649 15.7856 7.05654 15.5277C7.30659 15.2699 7.64573 15.125 7.99935 15.125C8.35297 15.125 8.69211 15.2699 8.94216 15.5277C9.19221 15.7856 9.33268 16.1353 9.33268 16.5Z'
				fill='#3F4F5E'
			/>
			<path
				d='M4.66602 2.75C4.13558 2.75 3.62687 2.9673 3.2518 3.35409C2.87673 3.74089 2.66602 4.26549 2.66602 4.8125V6.1875C2.66602 6.73451 2.87673 7.25911 3.2518 7.64591C3.62687 8.0327 4.13558 8.25 4.66602 8.25H11.3327C11.8631 8.25 12.3718 8.0327 12.7469 7.64591C13.122 7.25911 13.3327 6.73451 13.3327 6.1875V4.8125C13.3327 4.26549 13.122 3.74089 12.7469 3.35409C12.3718 2.9673 11.8631 2.75 11.3327 2.75H4.66602ZM3.99935 4.8125C3.99935 4.63016 4.06959 4.4553 4.19461 4.32636C4.31964 4.19743 4.4892 4.125 4.66602 4.125H11.3327C11.5095 4.125 11.6791 4.19743 11.8041 4.32636C11.9291 4.4553 11.9993 4.63016 11.9993 4.8125V6.1875C11.9993 6.36984 11.9291 6.5447 11.8041 6.67364C11.6791 6.80257 11.5095 6.875 11.3327 6.875H4.66602C4.4892 6.875 4.31964 6.80257 4.19461 6.67364C4.06959 6.5447 3.99935 6.36984 3.99935 6.1875V4.8125Z'
				fill='#3F4F5E'
			/>
			<path
				d='M16 18.5625C16 19.4742 15.6488 20.3485 15.0237 20.9932C14.3986 21.6378 13.5507 22 12.6667 22H3.33333C2.44928 22 1.60143 21.6378 0.976311 20.9932C0.351189 20.3485 0 19.4742 0 18.5625V3.4375C0 2.52582 0.351189 1.65148 0.976311 1.00682C1.60143 0.362164 2.44928 0 3.33333 0H12.6667C13.5507 0 14.3986 0.362164 15.0237 1.00682C15.6488 1.65148 16 2.52582 16 3.4375V18.5625ZM14.6667 3.4375C14.6667 2.89049 14.456 2.36589 14.0809 1.97909C13.7058 1.5923 13.1971 1.375 12.6667 1.375H3.33333C2.8029 1.375 2.29419 1.5923 1.91912 1.97909C1.54405 2.36589 1.33333 2.89049 1.33333 3.4375V18.5625C1.33333 19.1095 1.54405 19.6341 1.91912 20.0209C2.29419 20.4077 2.8029 20.625 3.33333 20.625H12.6667C13.1971 20.625 13.7058 20.4077 14.0809 20.0209C14.456 19.6341 14.6667 19.1095 14.6667 18.5625V3.4375Z'
				fill='#3F4F5E'
			/>
		</svg>
	)
}
