import { ICommonProject } from '@root-gipro/store/interfaces'

export const collapseItemsLeftColumn = (project: ICommonProject, region: string) => [
	{
		type: 'icon',
		title: 'Период реализации',
		icon: 'clock-icon',
		value: project.yearEndPlan,
	},
	{
		type: 'icon',
		title: 'Регион поставки',
		icon: 'mark-icon',
		value: region,
	},
	{
		type: 'icon',
		title: 'Составление сметной документации',
		icon: 'clock-icon',
		value: project.estimatedPlanYM,
	},
]
