import React, { useEffect } from 'react'
import './PresetFilters.scss'
import { deletePresetFilters, getPresetFilters, setOpenPresetFilterModal, setPresetFilters, setSelectedPresetFilter } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { IPresetFilter, IState } from '@root-gipro/store/interfaces'
import { Button, Chip, makeStyles } from '@material-ui/core'

const useStyleChip = makeStyles(theme => ({
	root: {
		maxWidth: 235,
	},
}))

const useStyleButton = makeStyles(theme => ({
	root: {
		textTransform: 'none',
	},
}))

interface PresetFiltersProps {}

const PresetFilters: React.FC<PresetFiltersProps> = () => {
	const classes = useStyleChip()
	const classesButton = useStyleButton()
	const dispatch = useDispatch()
	const { dataPresetFilter } = useSelector((state: IState) => state.filter)

	useEffect(() => {
		dispatch(getPresetFilters())
	}, [])

	const deleteChip = (id: string) => {
		dispatch(deletePresetFilters(id))
	}

	const setFilterPreset = (filters: any) => {
		dispatch(setPresetFilters('common-projects', filters))
	}

	const handleChipClick = (item: IPresetFilter) => {
		setFilterPreset(item.filters)		
	}

	const parseUrlParams = () => {
		//берем из урла параметры и преобразуем их для дальнешего сравнения
		const params = new URLSearchParams(window.location.search)
		const result: any = {}
		params.forEach((value, key) => {
			// регулярка для разборки строки [key: index]
			const match = key.match(/([^\[]+)(?:\[(\d+)\])?/)
			if (match) {
				const [, mainKey, index] = match
				if (index !== undefined) {
					if (!result[mainKey]) {
						result[mainKey] = []
					}
					result[mainKey][index] = value
				} else {
					result[mainKey] = value
				}
			} else {
				result[key] = value
			}
		})
		return result
	}

	const checkFilters = (filters: any) => {
		const urlParams = parseUrlParams()
		const filterKeys = Object.keys(filters)
		const urlKeys = Object.keys(urlParams)

		// Проверка что кол-во ключей совпадает
		if (urlKeys.length !== filterKeys.length) {
			return false
		}

		return filterKeys.every(key => {
			const filterValue = filters[key]
			const urlValue = urlParams[key]

			if (Array.isArray(filterValue)) {
				if (!Array.isArray(urlValue) || filterValue.length !== urlValue.length) {
					return false
				}
				return filterValue.every((value, index) => {
					return urlValue[index] === String(value)
				})
			}

			if (typeof filterValue === 'object') {
				return Object.keys(filterValue).every(subKey => {
					if (Array.isArray(filterValue[subKey])) {
						return filterValue[subKey].every((value: any, index: any) => {
							return urlValue[subKey] && urlValue[subKey][index] === String(value)
						})
					}
					return filterValue[subKey] === urlValue[subKey]
				})
			}

			if (key in urlParams) {
				return String(filters[key]) === urlParams[key]
			}

			return false
		})
	}

	const changeNameHandler = () => {
		const matchedChip = dataPresetFilter?.find(item => checkFilters(item.filters))
        if (matchedChip) {
           dispatch(setSelectedPresetFilter(matchedChip))
        }
		dispatch(setOpenPresetFilterModal(true, 'edit'))
	}


	return (
		<div className='filter_presets'>
			<div className='filter_presets__items'>
				{dataPresetFilter?.map(item => {
					const isFilterMatch = checkFilters(item.filters)
					return (
						<Chip
							classes={classes}
							style={isFilterMatch ? { boxShadow: 'inset 0 0 10px rgba(27, 170, 240, 1)' } : {}}
							key={item.id}
							label={item.name}
							onClick={() => handleChipClick(item)}
							onDelete={() => deleteChip(item.id)}
							title={item.name}
						/>
					)
				})}
			</div>
			<div className='filter_presets__save_modal'>
				<Button
					classes={classesButton}
					variant='text'
					onClick={() => {
						changeNameHandler()
					}}
				>
					Переименовать
				</Button>
				<Button
					classes={classesButton}
					variant='text'
					onClick={() => {
						dispatch(setOpenPresetFilterModal(true, 'save'))
					}}
				>
					Сохранить
				</Button>
			</div>
		</div>
	)
}

export default PresetFilters
