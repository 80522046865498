import produce, { Draft } from 'immer'
import { IDevelopmentSchemesAndProgramsState } from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.model'
import {
	DevelopmentSchemesAndProgramsType,
	DevelopmentSchemesAndProgramsTypes,
} from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.actions'

const initialState: IDevelopmentSchemesAndProgramsState = {
	programs: [],
	titles: [],
	publicationDates: [],
	since: [],
	industries: [],
	totalPrograms: 0,
	order: {
		by: 'title',
		direction: 'DESC',
	},
	filter: {
		industry: '',
		shortTitle: '',
		publishedAt: '',
		since: '',
		startedAt: '',
		finishedAt: '',
	},
}

const developmentSchemesAndPrograms = (
	draft: Draft<IDevelopmentSchemesAndProgramsState>,
	action: DevelopmentSchemesAndProgramsTypes
) => {
	switch (action.type) {
		case DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS:
			draft.programs = action.programs
			break
		case DevelopmentSchemesAndProgramsType.SET_ORDER:
			draft.order = action.order
			break
		case DevelopmentSchemesAndProgramsType.SET_DEVELOPMENT_SCHEMES_AND_PROGRAMS_TOTAL_PROGRAMS:
			draft.totalPrograms = action.total
			break
		case DevelopmentSchemesAndProgramsType.RESET_FILTER:
			draft.filter = {
				industry: '',
				shortTitle: '',
				publishedAt: '',
				since: '',
				startedAt: '',
				finishedAt: '',
			}
			break
		case DevelopmentSchemesAndProgramsType.SET_FILTER_PUBLICATION_DATES:
			draft.publicationDates = action.publicationDates
			break
		case DevelopmentSchemesAndProgramsType.SET_FILTER_INDUSTRIES:
			draft.industries = action.industries
			break
		case DevelopmentSchemesAndProgramsType.SET_FILTER_SHORT_TITLES:
			draft.titles = action.titles
			break
		case DevelopmentSchemesAndProgramsType.SET_FILTER_SINCE_DATES:
			draft.since = action.sinceDates
			break
		case DevelopmentSchemesAndProgramsType.SET_FILTER_VALUE:
			return {
				...draft,
				filter: {
					...draft.filter,
					[action.key]: action.value,
				},
			}

		default:
			return draft
	}
}

export const developmentSchemesAndProgramsReducer = produce(developmentSchemesAndPrograms, initialState)
