import React, { FC } from 'react'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { objectStylesForCard } from '@root-gipro/modules/tariffs/models/consts/objectStylesForCard'

interface OrderProps {
	currenDiscount: string
}

const Order: FC<OrderProps> = ({ currenDiscount }) => {
	const { tarif_price_id } = useSelector((state: IState) => state.tariffs.selectedSettings)
	const reportType = useSelector((state: IState) => state.tariffs.reportType)
	const calcUnlimit = useSelector((state: IState) => state.tariffs.calcUnlimit)
	const tarif = objectStylesForCard.find(tarif => tarif.id === +tarif_price_id)

	return (
		<div className={styles.order}>
			{tarif_price_id !== 3 ? (
				<React.Fragment>
					<div className={styles.heading}>Заказ</div>

					<div className={styles.orderList}>
						<div className={styles.orderItem}>
							<div>Срок</div>
							<div>{currenDiscount}</div>
						</div>
						<div>
							<div className={styles.orderItem}>
								<div>Сумма за месяц</div>
								<div>
									{currenDiscount.split(' ')[0] === '3'
										? tarif?.tarifMonth
										: currenDiscount.split(' ')[0] === '6'
										? tarif?.tarif6Month
										: tarif?.tarifYear}{' '}
									₽
								</div>
							</div>
						</div>
						{currenDiscount.split(' ')[0] === '12' ? (
							<>
								{
									<div className={styles.orderItem}>
										<div>Скидка</div>
										<div>{+tarif!.tarifYear_unlimit * 12 - +(tarif!.tarifMonth_unlimit * 12)}₽</div>
									</div>
								}
								<div className={styles.total}>
									<div className={styles.totalItem}>
										<div>Итого</div>
										<div>{calcUnlimit ? +tarif!.tarifYear * 12 + 158400 : +tarif!.tarifYear * 12}₽</div>
									</div>
								</div>
							</>
						) : currenDiscount.split(' ')[0] === '6' ? (
							<>
								{
									<div className={styles.orderItem}>
										<div>Скидка</div>
										<div>{+tarif!.tarif6Month_unlimit * 6 - +(tarif!.tarifMonth_unlimit * 6)}₽</div>
									</div>
								}
								<div className={styles.total}>
									<div className={styles.totalItem}>
										<div>Итого</div>
										<div>{calcUnlimit ? +tarif!.tarif6Month * 6 + 85500 : +tarif!.tarif6Month * 6}₽</div>
									</div>
								</div>
							</>
						) : (
							<div className={styles.total}>
								<div className={styles.totalItem}>
									<div>Итого</div>
									<div>
										{calcUnlimit
											? +tarif!.tarifMonth * +currenDiscount.split(' ')[0] + 45000
											: +tarif!.tarifMonth * +currenDiscount.split(' ')[0]}
										₽
									</div>
								</div>
							</div>
						)}
					</div>
				</React.Fragment>
			) : (
				<div className={styles.total}>
					<div className={styles.totalItem}>
						<div>Итого</div>
						<div>{reportType === 'standard' ? 10000 : 80000}₽</div>
					</div>
				</div>
			)}
		</div>
	)
}

export { Order }
