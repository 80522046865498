import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import styles from '@root-gipro/modules/commonProjects/styles/SubscriptionForm.module.scss'
import { Input } from '@root-gipro/shared/FormElements'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { ListForm } from '@root-gipro/modules/commonProjects/models/interfaces/cost-works-list-form.model'
import { IFormData } from '../../models/interfaces/cost-works.state'
import { subscribeRequest } from '@root-gipro/modules/commonProjects/store/actions'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useInput } from '@root-gipro/shared/hooks/useInput'
import { schema } from '@root-gipro/modules/commonProjects/models/const/cost-works-schema'

export interface SubscriptionFormProps {
	list: ListForm[]
	handlerStepBack: () => void
	accessory: string
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ handlerStepBack, list, accessory }) => {
	const { value, onChange } = useInput()
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema(accessory)),
	})
	const dispatch = useDispatch()

	const onSubmit = (data: IFormData) => {
		dispatch(subscribeRequest(data))
	}

	return (
		<div className='subscription-form'>
			<div className='subscription-form__description'>
				<form noValidate onSubmit={handleSubmit(onSubmit)}>
					{list &&
						list.map((item: ListForm) => (
							<div key={item.inputName} className={styles.input}>
								<Input
									labelName={item.labelName}
									inputName={item.inputName && item.inputName}
									inputValue={value && value[item.inputName]}
									handleInput={onChange}
									classes='full-width'
									inputRef={register}
									errors={!!errors[item.inputName]}
									helperText={errors && errors[item.inputName] && errors[item.inputName].message}
									mask={item.mask && item.mask}
								/>
							</div>
						))}
					<div className={styles.action}>
						<PrimaryBtn type='submit'>Оформить</PrimaryBtn>
						<SecondaryBtn onClick={handlerStepBack}>Отмена</SecondaryBtn>
					</div>
				</form>
			</div>
		</div>
	)
}
