import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'
import { IUnc } from '@root-gipro/modules/userProjects/models/interfaces/user-projects.model'
import React, { FC, MouseEvent } from 'react'
import { Row } from './Row'

export type Cell = {
	value: (value: any) => number | string | false | undefined | JSX.Element
	textAlign: string
	tooltip?: string
}
export type TableType = {
	[key: string]: any
}

export interface TbodyProps {
	titleData?: string[]
	tableObj: TableType
	handleShowComment?(event: MouseEvent<HTMLDivElement>, comment: string): void
	getNumberUnc?: ((id: string) => string | number | false) | undefined
	cellData: (
		getNumberUnc?: ((id: string) => string | number | false) | any,
		handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
	) => Cell[]
	gridTemplateColumns: string
}

const Tbody: FC<TbodyProps> = ({
	gridTemplateColumns,
	cellData,
	titleData,
	tableObj,
	handleShowComment,
	getNumberUnc,
}) => {
	const body = Object.entries(tableObj)
	return (
		<div>
			{tableObj &&
				body.map((item: TableType, index: number) => (
					<Row
						handleShowComment={handleShowComment}
						gridTemplateColumns={gridTemplateColumns}
						getNumberUnc={getNumberUnc}
						title={titleData && titleData[index]}
						uncs={item[1]}
						tableNum={item[0]}
						cellData={cellData}
						key={index}
					/>
				))}
		</div>
	)
}

export { Tbody }
