import React from 'react'
import { Tabs } from '@material-ui/core/'
import { Tab } from '@material-ui/core/'
import { IToggleTabsProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

export const ToggleTabs: React.FC<IToggleTabsProps> = ({ items, clickHandler }) => {
	const [value, setValue] = React.useState(0)
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	return (
		<Tabs value={value} onChange={handleChange} textColor='primary' className='toggler' variant='fullWidth'>
			{items.map((item, ind) => {
				return <Tab label={item.name} key={ind} onClick={() => clickHandler(item)} />
			})}
		</Tabs>
	)
}
