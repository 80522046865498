import { createBrowserHistory } from 'history'

export enum Routes {
	COMMON_PROJECTS = '/common-projects',
	COMMON_PROJECTS_TABLE= '/common-projects/table',
	PURCHASES_PROJECT_TABLE= '/purchases/project/table',
	COMMON_PROJECTS_PARAMETERS = '/common-projects/project/:id/parameters',
	COMMON_PROJECTS_RATES = '/common-projects/project/:id/rates',
	COMMON_PROJECTS_CONSTRUCTION_RESOURCES = '/common-projects/project/:id/construction-resources',

	USER_PROJECTS = '/user-projects',
	USER_PROJECTS_PROJECT = '/user-projects/project/:id',
	USER_PROJECTS_PARAMETERS = '/user-projects/project/:id/parameters',
	USER_PROJECTS_RATES = '/user-projects/project/:id/rates',

	PURCHASES = '/purchases',
	PURCHASES_TABLE = '/purchases/table',
	PURCHASES_PROJECT = '/purchases/project/:id',
	PURCHASES_DETAIL = `/purchases/:type/:id`,
	PURCHASES_PROJECT_INVESTITION = '/purchases/project/:id/investition',

	TARIFFS = '/', // '/tariffs',

	PERSONAL_AREA = '/personal-area',

	FAVORITES_COMMON_PROJECTS = '/favorites-common-projects',
	FAVORITES_COMMON_PROJECTS_TABLE = '/favorites-common-projects/table',

	FAVORITES_PURCHASES = '/favorites-purchases',
	FAVORITES_PURCHASES_TABLE= '/favorites-purchases/table',

	// INVESTMENT_PROGRAMS = '/ipr',
	// DEVELOPMENT_SCHEMES_AND_PROGRAMS = '/sipr',

	ADMIN_INVESTMENT_PROGRAMS = '/admin/ipr',
	ADMIN_STATISTICS = '/admin/statistic',

	ADMIN_EDIT_INVESTMENT_PROGRAM = '/admin/ipr/:id',
	ADMIN_CREATE_INVESTMENT_PROGRAM = '/admin/ipr/create',
	ADMIN_DEVELOPMENT_SCHEMES_AND_PROGRAMS = '/admin/sipr',
	ADMIN_EDIT_DEVELOPMENT_SCHEME_AND_PROGRAM = '/admin/sipr/:id',
	ADMIN_CREATE_DEVELOPMENT_SCHEME_AND_PROGRAM = '/admin/sipr/create',
}

export const history = createBrowserHistory()
