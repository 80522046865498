export const gridTemplateColumns = '36px 160px minmax(200px, 412px) 40px 80px 96px 160px 72px 96px 96px'
export const theadData = [
	{
		title: '№',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Техническое решение',
		textAlign: 'textAlignLeft',
	},
	{
		title: '',
		textAlign: 'textAlignCenter',
	},
	{
		title: 'Кол-во',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Ед.измерения',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Укр. норматив цены, тыс.руб.',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Величина затрат',
		textAlign: 'textAlignLeft',
	},
]
