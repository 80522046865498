import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { ISortItem } from '@root-gipro/shared/FilterHeader/components/sort-item.model'
import { ArrowDownSVG } from '@root-gipro/shared/SVGComponents/ArrowDownSVG'
import { unLoadWindow } from '@root-gipro/shared/FilterHeader/utils/unLoadWindow'
import { getIdentifier } from '@root-gipro/shared/FilterHeader/utils/getIdentifier'
import { IState } from '@root-gipro/store/interfaces'
import { setAllowMoreProjects } from '@root-gipro/modules/commonProjects/store/actions'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isEmpty } from 'lodash'

const SortItems: React.FC<{
	sortItems(name: string, sortName: string, order?: boolean): void
	sortList: ISortItem[]
}> = ({ sortItems, sortList }) => {
	if (!sortList || !sortList) {
		throw Error('SortItems: not enough arguments')
	}
	const { allowMoreProjects } = useSelector((state: IState) => state.commonProjects)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
	const [orderSort, setOrderSort] = React.useState<boolean>(true)
	const location = useLocation()
	const routeMatch = useRouteMatch()
	const url: string = routeMatch.path.slice(1)
	const history = useHistory()
	const dispatch = useDispatch()

	const handleClickListItem = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			if (!event?.currentTarget) {
				return
			}
			setAnchorEl(event.currentTarget)
		},
		[anchorEl]
	)

	const handleMenuItemClick = useCallback(
		(event: React.MouseEvent<HTMLElement>, index: number) => {
			setSelectedIndex(index)
			if (location.pathname === '/common-projects') {
				sessionStorage.setItem('sortProjects', index.toString())
			}

			setAnchorEl(null)
			sortItems(sortList[index].name, sortList[index].sortName, orderSort)
		},
		[selectedIndex, orderSort]
	)

	const handleReverse = useCallback(() => {
		setOrderSort(!orderSort)
		setAnchorEl(null)
		if (selectedIndex !== undefined) {
			sortItems(sortList[selectedIndex].name, sortList[selectedIndex].sortName, !orderSort)
			console.log(selectedIndex)
		} else {
			const index = location.pathname === '/common-projects' ? 9 : 9
			console.log(index)
			sortItems(sortList[index].name, sortList[index].sortName, !orderSort)
		}
		if (!allowMoreProjects) {
			dispatch(setAllowMoreProjects(true))
		}
	}, [orderSort, selectedIndex, allowMoreProjects])

	const handleClose = useCallback(() => {
		setAnchorEl(null)
	}, [anchorEl])

	useEffect(() => {
		if (location.pathname === '/common-projects') {
			let sortProjects = Number(sessionStorage.getItem('sortProjects'))
			setSelectedIndex(sortProjects)
		}
	}, [])

	const [isFirstVisit, setIsFirstVisit] = useState(false)

	useEffect(() => {
		// Проверяем наличие флага в sessionStorage
		const hasVisited = sessionStorage.getItem('hasVisited')

		if (!hasVisited) {
			// Если флаг отсутствует, это первое посещение или обновление страницы
			sessionStorage.setItem('hasVisited', 'true')
			setIsFirstVisit(true)
		} else {
			setIsFirstVisit(false)
		}
	}, [])

	const checkUrl = () => {
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

		return !isEmpty(urlsSearhParsed[url]) ? true : false
	}

	useEffect(() => {
		let indexIdentifier = getIdentifier(location, sortList)
		let sortProjects = Number(localStorage.getItem('sortProjects'))
		unLoadWindow(location, history) // убирает hash из url

		if (location.pathname === '/common-projects' && sortProjects && !location.hash) {
			if (isFirstVisit && !checkUrl()) {
				sortItems(sortList[sortProjects].name, sortList[sortProjects].sortName, !orderSort)
			}
			setSelectedIndex(sortProjects)
			setOrderSort(false)
		} else if (indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, !orderSort)
			setSelectedIndex(indexIdentifier)
			setOrderSort(false)
		} else if (location.pathname === '/favorites-common-projects' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, !orderSort)
			setOrderSort(false)
		} else if (location.pathname === '/favorites-common-projects/table' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, !orderSort)
			setOrderSort(false)
		} else if (location.pathname === '/purchases' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, orderSort)
			setSelectedIndex(indexIdentifier)
		}
		if (location.pathname === '/favorites-purchases' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, orderSort)
			setSelectedIndex(indexIdentifier)
		}
		if (location.pathname === '/favorites-purchases/table' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, orderSort)
			setSelectedIndex(indexIdentifier)
		}
		if (location.pathname === '/user-projects' && indexIdentifier && !location.hash) {
			sortItems(sortList[indexIdentifier]?.name, sortList[indexIdentifier]?.sortName, orderSort)
			setSelectedIndex(indexIdentifier)
		}

		// setSelectedIndex(indexIdentifier)
	}, [location.pathname])

	return (
		<div className={`projects__sort`}>
			<div className='projects__sort-select'>
				<span className='projects__sort-select-option' onClick={handleClickListItem}>
					{selectedIndex === undefined ? sortList[0]?.name : sortList[selectedIndex]?.name}
				</span>
				<div
					onClick={handleReverse}
					className={`projects__sort__arrow-options ${orderSort ? 'active-up' : 'active-down'}`}
				>
					<ArrowDownSVG />
					<ArrowDownSVG />
				</div>
			</div>
			<Menu
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: -6,
					horizontal: 12,
				}}
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{sortList.map((option, index) => (
					<MenuItem key={index} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
						{option.name}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

export default SortItems
