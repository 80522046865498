enum AuthType {
	SET_AUTH = 'SET_AUTH',
	AUTHORIZE = 'AUTHORIZE',
	LOGOUT = 'LOGOUT',
}

const authorize = () => ({
	type: AuthType.AUTHORIZE,
})

const logout = () => ({
	type: AuthType.LOGOUT,
})

const setAuth = (accessToken: string, expires_in: number) => ({
	type: AuthType.SET_AUTH,
	accessToken,
	expires_in,
})

export { AuthType, authorize, setAuth, logout }
