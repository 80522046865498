import React, { useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/ru';
import DataTable from 'react-data-table-component'
import PenIcon from '@root-gipro/components/Icons/PenIcon'
import { formatMoney } from '@root-gipro/modules/investmentPrograms/utils/format-money'
import { NavLink } from 'react-router-dom'
import { Routes } from '@root-gipro/router'
import { useDispatch, useSelector } from 'react-redux'
import { getInvestmentPrograms } from '@root-gipro/modules/investmentPrograms/store/actions'
import { IState } from '@root-gipro/store/interfaces'

const Table = () => {
    const dispatch = useDispatch()
    const localLocale = moment()
    localLocale.locale('ru')

    useEffect(() => {
        dispatch(getInvestmentPrograms())
    }, [])

    const programs = useSelector((state:IState) => state.investmentPrograms.programs)

    const columns = [
        {
            name: 'Заголовок',
            selector: (row: any) => row.title,
            maxWidth: '400px'
        },
        {
            name: 'Год утверждения',
            selector: (row: any) => row.since,
            maxWidth: '150px'
        },
        {
            name: 'Период реализации',
            selector: (row: any) => `с ${row.startedAt} по ${row.finishedAt}`,
            maxWidth: '250px'
        },
        {
            name: 'Оценка стоимости (млн.р)',
            selector: (row: any) => formatMoney(row.cost),
            maxWidth: '200px'
        },
        {
            name: 'Ресурс',
            cell: (row: any) => <a target="_blank" href={row.resource}>Открытый источник</a>,
            maxWidth: '200px'
        },
        {
            name: 'Редактирование',
            maxWidth: '150px',
            cell: (row: any) => (
                <NavLink to={`/admin/ipr/${row.id}`} className="admin-table__icon" type="button">
                    <PenIcon />
                </NavLink>
            ),
        }
    ]

    return (
        <div className="admin-container">
            <div className="admin-form__header">
                <h3>Таблица инвестиционных программ</h3>

                <div className="admin-form__header-buttons">
                    <NavLink to={Routes.ADMIN_CREATE_INVESTMENT_PROGRAM} className="admin-button primary">Создать</NavLink>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={programs}
                highlightOnHover
            />
        </div>
    )
}

export default React.memo(Table)
