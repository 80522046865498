import React, { FC } from 'react'
import '@root-gipro/shared/Card/Card/Card.scss'

interface Props {
	projectId: string
}

const Card: FC<Props> = ({ projectId, children }) => (
	<div id={`#${projectId}`} className='card-container'>
		{children}
	</div>
)

export default Card
