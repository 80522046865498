import { call, put, takeEvery } from 'redux-saga/effects'
import { MediaType } from '@root-gipro/modules/media/interfaces/media.actions'
import { createMedia } from '@root-gipro/store/api'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import {
	setInvestmentProgramMedia,
	setInvestmentProgramMediaIsLoading,
} from '@root-gipro/modules/admin/investmentPrograms/store/actions'
import {
	setDevelopmentSchemesAndProgramMedia,
	setDevelopmentSchemesAndProgramMediaIsLoading,
} from '@root-gipro/modules/admin/developmentSchemesAndPrograms/store/actions'

function* uploadIprPdf({ media }: { media: any; type: MediaType.UPLOAD_IPR_PDF }) {
	try {
		yield put(setInvestmentProgramMediaIsLoading(true))
		const response = yield call(createMedia, media)
		yield put(setInvestmentProgramMedia(response))
		yield put(setInvestmentProgramMediaIsLoading(false))
		yield put(
			showNotify({
				type: 'success',
				message: 'Файл успешно загружен!',
				duration: 5000,
			})
		)
	} catch (e) {
		console.error(e)
	}
}

function* uploadSiprPdf({ media }: { media: any; type: MediaType.UPLOAD_SIPR_PDF }) {
	try {
		yield put(setDevelopmentSchemesAndProgramMediaIsLoading(true))
		const response = yield call(createMedia, media)
		yield put(setDevelopmentSchemesAndProgramMedia(response))
		yield put(setDevelopmentSchemesAndProgramMediaIsLoading(false))

		yield put(
			showNotify({
				type: 'success',
				message: 'Файл успешно загружен!',
				duration: 5000,
			})
		)
	} catch (e) {
		console.error(e)
	}
}

export default function* watchMedia() {
	yield takeEvery(MediaType.UPLOAD_IPR_PDF, uploadIprPdf)
	yield takeEvery(MediaType.UPLOAD_SIPR_PDF, uploadSiprPdf)
}
