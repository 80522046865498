import { AdminInvestmentProgramsTypes } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-programs.actions'
import { IInvestmentProgram } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-program'

const getInvestmentProgram = (id: number | string) => ({
	type: AdminInvestmentProgramsTypes.GET_INVESTMENT_PROGRAM,
	id,
})

const createInvestmentProgram = (program: IInvestmentProgram) => ({
	type: AdminInvestmentProgramsTypes.CREATE_INVESTMENT_PROGRAM,
	program,
})

const deleteInvestmentProgram = (id: string) => ({
	type: AdminInvestmentProgramsTypes.DELETE_INVESTMENT_PROGRAM,
	id,
})

const updateInvestmentProgram = (program: IInvestmentProgram, id: string) => ({
	type: AdminInvestmentProgramsTypes.UPDATE_INVESTMENT_PROGRAM,
	program,
	id,
})

const setInvestmentProgram = (program: IInvestmentProgram) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM,
	program,
})

const setInvestmentProgramMedia = (media: any) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA,
	media,
})
const setInvestmentProgramMediaIsLoading = (isLoading: boolean) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_IS_LOADING,
	isLoading,
})

const setInvestmentProgramValidationErrors = (errors: any) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_VALIDATION_ERRORS,
	errors,
})

const setInvestmentProgramSince = (since: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SINCE,
	since,
})

const setInvestmentProgramMediaId = (id: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_MEDIA_ID,
	id,
})

const setInvestmentProgramTitle = (title: string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_TITLE,
	title,
})

const setInvestmentProgramIndustry = (industry: string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_INDUSTRY,
	industry,
})

const setInvestmentProgramShortTitle = (shortTitle: string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_SHORT_TITLE,
	shortTitle,
})

const setInvestmentProgramCost = (cost: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_COST,
	cost,
})

const setInvestmentProgramStartedAt = (startedAt: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_STARTED_AT,
	startedAt,
})

const setInvestmentProgramFinishedAt = (finishedAt: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_FINISHED_AT,
	finishedAt,
})

const setInvestmentProgramQuantity = (quantity: number | string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_QUANTITY,
	quantity,
})

const setInvestmentProgramPublishedAt = (publishedAt: string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_PUBLISHED_AT,
	publishedAt,
})

const setInvestmentProgramResource = (resource: string) => ({
	type: AdminInvestmentProgramsTypes.SET_INVESTMENT_PROGRAM_RESOURCE,
	resource,
})

const clearInvestmentProgram = () => ({
	type: AdminInvestmentProgramsTypes.CLEAR_INVESTMENT_PROGRAM,
})

export {
	clearInvestmentProgram,
	setInvestmentProgram,
	createInvestmentProgram,
	getInvestmentProgram,
	updateInvestmentProgram,
	deleteInvestmentProgram,
	setInvestmentProgramValidationErrors,
	setInvestmentProgramQuantity,
	setInvestmentProgramSince,
	setInvestmentProgramTitle,
	setInvestmentProgramShortTitle,
	setInvestmentProgramStartedAt,
	setInvestmentProgramFinishedAt,
	setInvestmentProgramPublishedAt,
	setInvestmentProgramCost,
	setInvestmentProgramResource,
	setInvestmentProgramMediaId,
	setInvestmentProgramMedia,
	setInvestmentProgramMediaIsLoading,
	setInvestmentProgramIndustry,
}
