import * as yup from 'yup'
import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

export const schema = (step: any) => {
	switch (step) {
		case StepType.RECEIVE_BUILD_WORK:
		case StepType.RECEIVE_DESIGN_WORK:
			return yup.object().shape({
				name: yup
					.string()
					.matches(/^([^0-9]*)$/, 'В имени должны быть только буквы')
					.required('Поле должно быть заполненно'),
				phone: yup
					.string()
					.matches(/\+\d{1}\(\d{3}\)\d{3}-\d{4}/, 'Некорректный  телефон')
					.required('Поле должно быть заполненно'),
				email: yup
					.string()
					.matches(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+/, 'Некорректный  Email')
					.required('Поле должно быть заполненно'),
				company: yup.string().required('Поле должно быть заполненно'),
				projectDevelopmentStage: yup.string().required('Поле должно быть заполненно'),
			})
		case StepType.TKP_BUILD_WORK:
		case StepType.TKP_DESIGN_WORK:
		default:
			return yup.object().shape({
				parameter1: yup.string().required('Поле должно быть заполненно'),
				parameter2: yup.string().required('Поле должно быть заполненно'),
			})
	}
}
