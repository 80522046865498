import { MediaType } from '@root-gipro/modules/media/interfaces/media.actions'

const uploadIprPdf = (media: any) => ({
    type: MediaType.UPLOAD_IPR_PDF,
    media,
    entity: 'ipr'
})

const uploadSiprPdf = (media: any) => ({
    type: MediaType.UPLOAD_SIPR_PDF,
    media,
    entity: 'sipr'
})

export {
    uploadIprPdf,
    uploadSiprPdf
}
