import { RouteComponentProps } from 'react-router-dom'

export const unLoadWindow = (location: RouteComponentProps['location'], history: RouteComponentProps['history']) => {
	const unLoad = () => {
		if (location.hash) {
			history.push({
				hash: '',
				pathname: location.pathname,
			})
		}
	}
	window.onload = unLoad
}
