import React from 'react'
import { formatDate, formateDateTime } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'

type CardLeftType = {
	title: string
	value: (item: IPurchase) => string | null | JSX.Element
	click?: () => void
	width?: number
}

export const CardNoticeContentItem: CardLeftType[] = [
	{
		title: 'Дата начала подачи заявок',
		value: (item: IPurchase) =>
			item.request_receiving_date ? formatDate(item.request_receiving_date) : formatDate(item.publish_date),
	},
	{
		title: 'Дата окончания подачи заявок',
		value: (item: IPurchase) => formatDate(item.request_receiving_end_date),
	},
	{
		title: 'Дата рассмотрения заявок',
		value: (item: IPurchase) =>
			item.request_examination_date
				? formatDate(item.request_examination_date)
				: formatDate(item.request_receiving_end_date),
	},
	{
		title: 'Дата подведения результатов',
		value: (item: IPurchase) =>
			item.result_date ? formatDate(item.result_date) : formatDate(item.request_receiving_end_date),
	},
	// {
	// 	title: 'Срок исполнения договора',
	// 	value: (item: IPurchase) => formatDate(item.notice_summingUpDate),
	// },
	{
		title: 'Цена победителя',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).price : ''),
	},
	{
		title: 'Победитель',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).name : ''),
		width: 2,
	},
	{
		title: 'Победитель. ИНН',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).inn : ''),
	},
	{
		title: 'Победитель. КПП',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).kpp : ''),
	},
	{
		title: 'Победитель. ОГРН',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).ogrn : ''),
	},
	{
		title: 'Победитель. Телефон',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).phone : ''),
	},
	{
		title: 'Победитель. E-mail',
		value: (item: IPurchase): string => (item.winner ? JSON.parse(item.winner).email : ''),
	},
]
