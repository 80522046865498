import { StepType } from '@root-gipro/modules/commonProjects/models/enums/step.enum'

export const filterTitle = (step: string | undefined) => {
	let title
	switch (step) {
		case StepType.BUILD_WORK:
			title = [
				{
					classes: 'leftTitle',
					value: 'Подробный расчет стоимости строительных работ',
					action: undefined,
				},
			]
			return title
		case StepType.DESIGN_WORK:
			title = [
				{
					classes: 'leftTitle',
					value: 'Подробный расчет стоимости проектных работ',
					action: undefined,
				},
			]
			return title
		case StepType.RECEIVE_BUILD_WORK:
		case StepType.RECEIVE_DESIGN_WORK:
			title = [
				{
					classes: 'leftTitle',
					value: 'Оформить подписку',
					action: undefined,
				},
			]
			return title
		case StepType.TKP_BUILD_WORK:
		case StepType.TKP_DESIGN_WORK:
			title = [
				{
					classes: 'leftTitle',
					value: 'Проект',
					action: undefined,
				},
			]
			return title

		default:
			break
	}
}
