import React, { useContext, useState, FC, useEffect } from 'react'
import { formatDate, prettyCost } from '@root-gipro/utils/helpers/common'
import { IParametersProps } from '@root-gipro/store/interfaces'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import { Detailed } from '@root-gipro/modules/commonProjects/components/SelectProjects/Parameters/Detailed'
import { Justification, ParamsOptions } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { CardFull, CardLeft, CardRight, CardItem, CardList } from '@root-gipro/shared/Card'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import { addHTML } from '@root-gipro/utils/helpers/addHTML'
import { ModalContext } from '@root-gipro/context/modal-context'
import '@root-gipro/modules/commonProjects/styles/Parameters.scss'
import { useLocation } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { PassportSVG } from '@root-gipro/shared/SVGComponents/PassporstSVG'
import { useDispatch } from 'react-redux'

const Parameters: FC<IParametersProps> = ({
	editToggleType,
	group,
	project,
	toggleType,
	region,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
	policesRGCO,
	policesGipro,
	rgStatuses,
}) => {
	const [toggler, setToggler] = useState(false)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()
	let location = useLocation()
	const url: string = location.pathname.slice(1)

	const [currUrl, setCurrUrl] = useState<null | string>(null)
	const getRgStatusId = () => {
		requestRgStatuses(project.id)
	}

	useEffect(() => {
		setCurrUrl(url)
	}, [url])

	return (
		<>
			<div className='container-full'>
				<CardFull classes='detail-common-projects'>
					<div className='card-full__item'>
						<CardLeft>
							<div className='card-full__header'>
								<div className={`card-full__code link-${project.is_actual ? 'blue' : 'grey'}`}>
									<span className='common-project-icon' />
									<span className='code'>{project.code}</span>
								</div>
								<div className='card-full__actions'>
									<Tooltip title={!project.passportUrl ? 'Паспорт отсутствует' : 'Скачать паспорт'}>
										<span>
											<IconButton
												href={project?.passportUrl}
												download={`${project?.name}-passport`}
												title={!project?.passportUrl ? 'Паспорт отсутствует' : 'Скачать паспорт'}
												type='button'
												style={{ padding: '5px', marginLeft: '7px' }}
												disabled={!project?.passportUrl}
											>
												<PassportSVG disabled={!project.passportUrl} />
											</IconButton>
										</span>
									</Tooltip>
									<div
										className='link-blue link card-full__actions-item'
										data-popup='justification'
										onClick={e => setAnchorEl!(e.currentTarget)}
									>
										Обоснование
									</div>
									<span
										data-popup='options'
										onClick={e => setAnchorEl!(e.currentTarget)}
										className='popup-icon card-full__actions-item'
									/>
								</div>
							</div>
							<CardDate>Изменено {formatDate(project.publicationDate)}</CardDate>
							{group && <CardTitle>{group.name}</CardTitle>}
							<CardDescription>{project.name}</CardDescription>
						</CardLeft>
						<CardRight>
							{region && <CardList title={'Регион'} values={region} limit={3} />}
							{(project.valuationOfferForecast || project.valuationPlanForecast) && (
								<CardItem
									title={'Оценка полной стоимости в прогнозных ценах'}
									value={
										toggleType === 'offer'
											? addHTML(prettyCost(project.valuationOfferForecast / 10 ** 6))
											: addHTML(prettyCost(project.valuationPlanForecast / 10 ** 6))
									}
								/>
							)}
						</CardRight>
					</div>
				</CardFull>
			</div>
			<Detailed project={project} toggleType={toggleType} editToggleType={editToggleType} />

			{anchorEl?.dataset.popup === 'justification' && (
				<Justification anchorEl={anchorEl!} setAnchorEl={setAnchorEl!} offerRationale={project.offerRationale} />
			)}
			{anchorEl?.dataset.popup === 'options' && (
				<ParamsOptions
					anchorEl={anchorEl!}
					setAnchorEl={setAnchorEl!}
					policesGipro={policesGipro}
					policesRGCO={policesRGCO}
					copyHrefToClipboard={copyHrefToClipboard}
					downloadCommonProject={downloadCommonProject}
					project={project}
					addToUserProject={addToUserProject}
					getRgStatusId={getRgStatusId}
					rgStatuses={rgStatuses}
					rgStatusId={project.rgStatusId}
					toggler={toggler}
					togglerSubmenu={() => setToggler(!toggler)}
				/>
			)}
		</>
	)
}

export default Parameters
