import { AdminDevelopmentSchemesAndProgramsTypes } from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr.actions'
import { IDevelopmentSchemesAndProgram } from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr'

const getDevelopmentSchemesAndProgram = (id: number | string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.GET_DEVELOPMENT_SCHEMES_AND_PROGRAM,
	id,
})

const createDevelopmentSchemesAndProgram = (program: IDevelopmentSchemesAndProgram) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.CREATE_DEVELOPMENT_SCHEMES_AND_PROGRAM,
	program,
})

const deleteDevelopmentSchemesAndProgram = (id: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.DELETE_DEVELOPMENT_SCHEMES_AND_PROGRAM,
	id,
})

const updateDevelopmentSchemesAndProgram = (program: IDevelopmentSchemesAndProgram, id: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.UPDATE_DEVELOPMENT_SCHEMES_AND_PROGRAM,
	program,
	id,
})

const setDevelopmentSchemesAndProgram = (program: IDevelopmentSchemesAndProgram) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM,
	program,
})

const setDevelopmentSchemesAndProgramValidationErrors = (errors: any) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_VALIDATION_ERRORS,
	errors,
})

const setDevelopmentSchemesAndProgramSince = (since: number | string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SINCE,
	since,
})

const setDevelopmentSchemesAndProgramTitle = (title: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_TITLE,
	title,
})

const setDevelopmentSchemesAndProgramIndustry = (industry: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_INDUSTRY,
	industry,
})

const setDevelopmentSchemesAndProgramShortTitle = (shortTitle: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_SHORT_TITLE,
	shortTitle,
})

const setDevelopmentSchemesAndProgramStartedAt = (startedAt: number | string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_STARTED_AT,
	startedAt,
})

const setDevelopmentSchemesAndProgramFinishedAt = (finishedAt: number | string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_FINISHED_AT,
	finishedAt,
})

const setDevelopmentSchemesAndProgramQuantity = (quantity: number | string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_QUANTITY,
	quantity,
})

const setDevelopmentSchemesAndProgramPublishedAt = (publishedAt: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_PUBLISHED_AT,
	publishedAt,
})

const setDevelopmentSchemesAndProgramResource = (resource: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_RESOURCE,
	resource,
})

const setDevelopmentSchemesAndProgramMedia = (media: any) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA,
	media,
})
const setDevelopmentSchemesAndProgramMediaIsLoading = (isLoading: boolean) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_IS_LOADING,
	isLoading,
})

const setDevelopmentSchemesAndProgramMediaId = (mediaId: string) => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.SET_DEVELOPMENT_SCHEMES_AND_PROGRAM_MEDIA_ID,
	mediaId,
})

const clearDevelopmentSchemesAndPrograms = () => ({
	type: AdminDevelopmentSchemesAndProgramsTypes.CLEAR_DEVELOPMENT_SCHEMES_AND_PROGRAM,
})

export {
	clearDevelopmentSchemesAndPrograms,
	setDevelopmentSchemesAndProgram,
	createDevelopmentSchemesAndProgram,
	getDevelopmentSchemesAndProgram,
	updateDevelopmentSchemesAndProgram,
	deleteDevelopmentSchemesAndProgram,
	setDevelopmentSchemesAndProgramValidationErrors,
	setDevelopmentSchemesAndProgramQuantity,
	setDevelopmentSchemesAndProgramSince,
	setDevelopmentSchemesAndProgramTitle,
	setDevelopmentSchemesAndProgramShortTitle,
	setDevelopmentSchemesAndProgramStartedAt,
	setDevelopmentSchemesAndProgramFinishedAt,
	setDevelopmentSchemesAndProgramPublishedAt,
	setDevelopmentSchemesAndProgramResource,
	setDevelopmentSchemesAndProgramMedia,
	setDevelopmentSchemesAndProgramMediaIsLoading,
	setDevelopmentSchemesAndProgramMediaId,
	setDevelopmentSchemesAndProgramIndustry,
}
