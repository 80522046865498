import { withStyles, Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export const PrimaryBtn = withStyles((theme: Theme) => {
	return {
		root: {
			color: '#fff',
			fontSize: 15,
			lineHeight: '16px',
			fontWeight: 'normal',
			fontStyle: 'normal',
			textTransform: 'inherit',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			borderRadius: 5,
			border: `1px solid ${theme.palette.secondary.main}`,
			height: '36px',
			minWidth: '100px',
			backgroundColor: theme.palette.secondary.main,
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.palette.secondary.light,
			},
			'&:disabled': {
				border: '1px solid rgba(0, 0, 0, 0.12)',
				backgroundColor: '#e1e1e1',
				color: '#rgba(0, 0, 0, 0.26)',
			},
			'&:active': {
				backgroundColor: theme.palette.secondary.dark,
				border: `1px solid ${theme.palette.secondary.dark}`,
			},
		},
	}
})(Button)

export const SecondaryBtn = withStyles((theme: Theme) => {
	return {
		root: {
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: 15,
			lineHeight: '16px',
			textAlign: 'center',
			fontWeight: 'normal',
			fontStyle: 'normal',
			textTransform: 'inherit',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			// boxShadow: 'none',
			border: '1px solid #E0E0E0',
			borderRadius: 4,
			backgroundColor: '#fff',
			height: '36px',
			minWidth: '100px',
			'&:hover': {
				border: '1px solid #3F4F5E',
				backgroundColor: '#fff',
			},
			'&:active': {
				backgroundColor: 'rgba(27, 170, 240, 0.1)',
				border: '1px solid #1baaf0',
				color: '#1baaf0',
			},
		},
		label: {
			display: 'flex',
			alignItems: 'center',
			height: '100%',
		},
	}
})(Button)
export const ExtraButtonBtn = withStyles((theme: Theme) => {
	return {
		root: {
			color: '#02405E',
			fontSize: 13,
			lineHeight: '16px',
			textAlign: 'center',
			fontWeight: 'normal',
			fontStyle: 'normal',
			textTransform: 'inherit',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			boxShadow: 'none',
			borderRadius: 4,
			padding: '0 4px ',

			backgroundColor: '#E0E0E0',
			height: '32px',
			minWidth: '100px',
			'&:hover': {
				backgroundColor: '#E0E0E0',
			},
		},
	}
})(Button)
