import React, { useEffect, useState } from 'react'
import { CardCollapseProps } from '@root-gipro/shared/Card/CardCollapse/card-collapse.props'
import { Collapse } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const CardCollapse: React.FC<CardCollapseProps> = ({ isExpanded, children }) => {
	const [isExpand, setIsExpand] = useState(false)
	const [isProject, setIsProject] = useState(false)
	const history = useHistory()

	useEffect(() => {
		setIsExpand(isExpanded || false)
	}, [isExpanded])

	useEffect(() => {
		if (
			(history.location.pathname === '/favorites-common-projects' || history.location.pathname === '/favorites-common-projects/table') ||
			(history.location.pathname === '/common-projects'||history.location.pathname === '/common-projects/table')
		) {
			setIsProject(true)
		}
	}, [history.location.pathname])

	return (
		<Collapse in={isExpand} unmountOnExit style={{ width: '100%' }}>
			<div
				className={isProject ? 'card-container__detailed' : 'card-container__detailed card-container__detailed--column'}
			>
				{children}
			</div>
		</Collapse>
	)
}

export default CardCollapse
