import { showNotify } from '@root-gipro/modules/notify/store/actions'
import store from '@root-gipro/store'
import { fetchAuthHeaders, authApp } from '@root-gipro/store/api'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'
import { Method } from 'axios'

export const tariffsFetchData = (
	url: string,
	callback: any,
	method: Method = 'GET',
	data?: any,
	headers: object = fetchAuthHeaders()
) => {
	const request = authApp({
		method,
		url: `https://${process.env.REACT_APP_ENV_AUTH}/api/v1${url}`,
		headers,
		data,
	})
		.then(res => {
			if (res.status >= 200 && res.status < 300) {
				return res.data
			} else {
				throw res
			}
		})
		.then(res => {
			if (process.env.NODE_ENV === 'development') {
				if (res.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.message,
						})
					)
					throw new Error(res)
				}
				if (res.error) {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.error,
						})
					)
					throw new Error(res)
				}
			}
			return callback(res)
		})
		.catch(err => {
			const { data } = err.response
			handlerErrors(data)
		})

	return request
}
