export enum InvestmentProgramsType {
    GET_INVESTMENT_PROGRAMS = 'GET_INVESTMENT_PROGRAMS',
    FILTER_INVESTMENT_PROGRAMS = 'FILTER_INVESTMENT_PROGRAMS',
    SET_INVESTMENT_PROGRAMS = 'SET_INVESTMENT_PROGRAMS',
    GET_STATISTICS = 'GET_STATISTICS',
    SET_STATISTICS = "SET_STATISTICS",
    SET_ORDER = 'SET_INVESTMENT_PROGRAMS_ORDER',
    SET_FILTER_SHORT_TITLES = 'SET_INVESTMENT_PROGRAMS_FILTER_SHORT_TITLES',
    SET_FILTER_INDUSTRIES = 'SET_INVESTMENT_PROGRAMS_FILTER_INDUSTRIES',
    SET_FILTER_SINCE_DATES = 'SET_INVESTMENT_PROGRAMS_FILTER_SINCE_DATES',
    SET_INVESTMENT_PROGRAMS_TOTAL_COST = 'SET_INVESTMENT_PROGRAMS_TOTAL_COST',
    SET_INVESTMENT_PROGRAMS_TOTAL_PROGRAMS = 'SET_INVESTMENT_PROGRAMS_TOTAL_PROGRAMS',
    SET_FILTER_PUBLICATION_DATES = 'SET_INVESTMENT_PROGRAMS_FILTER_PUBLICATION_DATES',
    SET_FILTER_VALUE = 'SET_INVESTMENT_PROGRAMS_FILTER_VALUE',
    RESET_FILTER = 'RESET_INVESTMENT_PROGRAMS_FILTER',
}

interface GetInvestmentPrograms {
    type: InvestmentProgramsType.GET_INVESTMENT_PROGRAMS
}

interface SetInvestmentPrograms {
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS,
    programs: []
}

interface SetInvestmentProgramsOrder {
    type: InvestmentProgramsType.SET_ORDER,
    order: {
        by: 'title' | 'since',
        direction: 'ASC' | 'DESC'
    }
}

interface SetInvestmentProgramsTotalPrograms {
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_PROGRAMS,
    total: number
}

interface SetInvestmentProgramsTotalCost {
    type: InvestmentProgramsType.SET_INVESTMENT_PROGRAMS_TOTAL_COST,
    cost: number
}

interface SetFilterShortTitles {
    type: InvestmentProgramsType.SET_FILTER_SHORT_TITLES,
    titles: string[]
}

interface SetFilterIndustries {
    type: InvestmentProgramsType.SET_FILTER_INDUSTRIES,
    industries: string[]
}

interface SetFilterSinceDates {
    type: InvestmentProgramsType.SET_FILTER_SINCE_DATES,
    sinceDates: string[]
}

interface SetFilterPublicationDates {
    type: InvestmentProgramsType.SET_FILTER_PUBLICATION_DATES,
    publicationDates: string[]
}

interface ResetFilter {
    type: InvestmentProgramsType.RESET_FILTER
}

interface SetFilterValue {
    type: InvestmentProgramsType.SET_FILTER_VALUE,
    value: string,
    key: 'shortTitle' | 'publishedAt' | 'since' | 'startedAt' | 'finishedAt' | 'minCost' | 'maxCost' | 'industry'
}

interface GetStatisticsAction {
    type: InvestmentProgramsType.GET_STATISTICS,
}
interface SetStatisticsAction {
    type: InvestmentProgramsType.SET_STATISTICS,
    statData: any
}

export type InvestmentProgramsTypes =
    | SetInvestmentPrograms
    | GetInvestmentPrograms
    | SetFilterShortTitles
    | SetFilterPublicationDates
    | SetFilterSinceDates
    | SetFilterValue
    | ResetFilter
    | SetInvestmentProgramsTotalCost
    | SetInvestmentProgramsTotalPrograms
    | SetInvestmentProgramsOrder
    | SetFilterIndustries
    | GetStatisticsAction
    | SetStatisticsAction