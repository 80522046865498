import React from 'react'
import '@root-gipro/shared/ProjectList/ProjectList.scss'
import Loader from '../Loader/Loader'
import { LoaderContainerProps } from '@root-gipro/shared/LoaderContainer/loader-container.props'

const LoaderContainer: React.FC<LoaderContainerProps> = ({ isLoading, children }) => (
	<>{isLoading ? <Loader /> : children}</>
)

export default LoaderContainer
