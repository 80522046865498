import '@root-gipro/modules/commonProjects/styles/RangePriceFilter.scss'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	cleanUncCodeFromFilter,
	filtersRequest,
	isLoadingCountResult,
	isShowFilterCloud,
	isSimilarProjectFilterState,
	refreshFilterState,
	resetFilterState,
	setCallbacks,
	setPresetFilters,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { Filter } from '@root-gipro/modules/purchases/containers'
import '@root-gipro/modules/purchases/styles/PurchasesContainer.scss'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import { IState } from '@root-gipro/store/interfaces/state'
import { decode, encode } from '@root-gipro/utils/helpers/queryString'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
	getPurchasesList,
	refreshList,
	setPurchasesPaginationCounter,
	toggleLoadingPurchaseList,
} from '../purchases/store/actions'
import { getCommonProjects, loadingCommonProjects, setCommonProjects } from '../commonProjects/store/actions'
import { commonProjectsApi } from '@root-gipro/store/api'
import { isEmpty } from 'lodash'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { updateUrlSearch } from '@root-gipro/modules/filter/utils/updateUrlSearch'
import { getPurchasesListByParams } from '@root-gipro/modules/purchases/api/purchases'

const FilterContainer: FC = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { countPublicInfoFetches } = useSelector((state: IState) => state.public)
	const publicInfo = useSelector((state: IState) => state.public)
	const { purchasesType } = useSelector((state: IState) => state.purchases)
	const uncInfoCommonProjects = useSelector((state: IState) => state.commonProjects.uncInfo)
	const uncInfoPurchases = useSelector((state: IState) => state.purchases.uncInfo)

	const url: string = location.pathname.slice(1)

	const { getInitList, isSelectPresetFilter } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'purchases/table'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'common-projects/table'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)

	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	useEffect(() => {
		if (countPublicInfoFetches >= 13) {
			dispatch(isShowFilterCloud(true))
		}
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

		if (!isEmpty(urlsSearhParsed[url])) {
			const params = decode(urlsSearhParsed[url])
			setTimeout(() => {
				dispatch(setPresetFilters(url, params))
			}, 0)
		}
	}, [])

	const refresh = (fieldName: string, value: string) => {
		dispatch(isLoadingCountResult(true))
		dispatch(
			refreshFilterState(
				fieldName,
				value,
				url,
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains
				)
			)
		)
	}

	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: encode(showFilterState),
		})
	}, [isSelectPresetFilter])

	const showResult = useCallback(() => {
		if (url === 'common-projects' || url === 'common-projects/table') {
			updateUrlSearch(url, encode(showFilterState))
			history.push({
				pathname: location.pathname,
				search: encode(showFilterState),
			})
			dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		} else if (url === 'purchases' || url === 'purchases/table') {
			updateUrlSearch(url, encode(showFilterState))
			history.push({
				pathname: location.pathname,
				search: encode(showFilterState),
			})

			dispatch(setCallbacks(getPurchasesList, getPurchasesListByParams, refreshList, toggleLoadingPurchaseList))
		}

		if (!!Number(countResult)) dispatch(showFilterResultHandler(url, true))
		dispatch(setPurchasesPaginationCounter(5))
	}, [countResult])

	const reset = () => {
		url === 'common-projects' && updateUrlSearch(url, '')
		url === 'common-projects' && dispatch(isSimilarProjectFilterState(false))
		url === 'purchases' && updateUrlSearch(url, '')
		url === 'common-projects/table' && updateUrlSearch(url.replace('/table', ''), '')
		url === 'purchases/table' && updateUrlSearch(url.replace('/table', ''), '')
		dispatch(getInitList!())
		dispatch(resetFilterState(url))
		dispatch(filtersRequest(url))
		dispatch(cleanUncCodeFromFilter())
		history.push({
			pathname: location.pathname,
			search: '',
		})
	}
	return (
		<div className='aside-filter-container'>
			<LoaderContainer isLoading={!getInitList}>
				{getInitList && (
					<Filter
						showFilterResult={showResult}
						refreshFilterState={refresh}
						resetFilter={reset}
						filterCountResult={countResult}
						filterState={resultFilterState}
						purchasesType={purchasesType}
						minMaxValues={publicInfo.minMax}
						uncInfo={url === 'purchases' || url === 'purchases/table' ? uncInfoPurchases : uncInfoCommonProjects}
						{...publicInfo}
						rgStatuses={rgStatuses}
					/>
				)}
			</LoaderContainer>
		</div>
	)
}

export default memo(FilterContainer)
