import React, { FC } from 'react'
import styles from '@root-gipro/modules/tariffs/style/ExtensionAccess.module.scss'
import cn from 'classnames'
import { Collapse } from '@material-ui/core'
import { DropdownSVG } from '@root-gipro/shared/SVGComponents/DropdownSVG'
import { objectStylesForCard } from '@root-gipro/modules/tariffs/models/consts/objectStylesForCard'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'

interface TariffPlanProps {
	openIncludes: boolean
	handleClickIncludes(): void
	currenDiscount: string
}

const TariffPlan: FC<TariffPlanProps> = ({ openIncludes, handleClickIncludes, currenDiscount }) => {
	const { tarif_price_id } = useSelector((state: IState) => state.tariffs.selectedSettings)
	const reportType = useSelector((state: IState) => state.tariffs.reportType)
	const tarif = objectStylesForCard.find(tarif => tarif.id === +tarif_price_id)
	return (
		<div className={styles.tariffPlan}>
			<div>
				<div className={styles.item}>
					<div className={styles.blockTitle}>
						{/* <div className={styles.icon} onClick={e => console.log()}>
							<CrossSVG className={styles.svg} />
						</div> */}
						<div style={{ color: `${tarif?.color}` }} className={styles.title}>
							{tarif?.name}
						</div>
					</div>

					<div className={styles.descriptions}>
						{tarif_price_id !== 3 ? (
							<React.Fragment>
								<div className={styles.price}>
									{' '}
									{currenDiscount.split(' ')[0] === '3'
										? tarif?.tarifMonth
										: currenDiscount.split(' ')[0] === '6'
										? tarif?.tarif6Month
										: tarif?.tarifYear}{' '}
									₽
								</div>
								<div className={styles.text}>в месяц</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className={styles.price}>{reportType === 'standard' ? 10000 : 80000}₽</div>
							</React.Fragment>
						)}

						<div className={styles.includes}>
							{tarif_price_id !== 3 ? (
								<>
									<div className={styles.txt} onClick={handleClickIncludes}>
										<div>Включает в себя</div>
										<DropdownSVG
											className={cn(styles.arrowSVG, {
												[styles.rotateSVG]: openIncludes,
											})}
										/>
									</div>

									<Collapse in={openIncludes} timeout='auto' unmountOnExit>
										<div>
											{tarif?.data.map(info => {
												return (
													<div className={styles.includesItem}>
														<div>✔</div>
														<div className={styles.text}>{info}</div>
													</div>
												)
											})}
										</div>
									</Collapse>
								</>
							) : (
								<div>
									<div>
										{tarif?.data.map(info => {
											return (
												<div className={styles.includesItem}>
													<div className={styles.text}>{info}</div>
												</div>
											)
										})}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export { TariffPlan }
