import React, { memo, useCallback, ReactText, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { appendPurchases, setExpandCardState } from '@root-gipro/modules/purchases/store/actions'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import Card from '@root-gipro/shared/Card/Card/Card'
import uniqueId from 'lodash/uniqueId'
import CardLeft from '@root-gipro/shared/Card/CardSides/CardLeft'
import { getPurchaseDetailRoute } from '@root-gipro/modules/purchases/utils/getPurchaseDetailRoute'
import { CardDate } from '@root-gipro/shared/Card/Card/CardDate'
import { CardTitle } from '@root-gipro/shared/Card/Card/CardTitle'
import { CardDescription } from '@root-gipro/shared/Card/Card/CardDescription'
import CardRight from '@root-gipro/shared/Card/CardSides/CardRight'
import CardItem from '@root-gipro/shared/Card/CardItem/CardItem'
import { useHistory } from 'react-router-dom'
import { CardRightSidePrices } from '@root-gipro/modules/purchases/models/consts/card-right-side-prices.const'
import { PurchaseListProps } from '@root-gipro/modules/purchases/models/props/purchase-list.model'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import PurchasesCollapse from './PurchasesCollapse/PurchasesCollapse'
import { formatDate } from '@root-gipro/utils/helpers/common'
import { CardHeaderTitle } from '@root-gipro/shared/Card'

const PurchaseList: React.FC<PurchaseListProps> = memo(
	({
		details,
		setDetails,
		list,
		groups,
		company,
		regions,
		isLoadingInitList,
		getProjectById,
		isMoreItemsLoading,
		allowMoreItems,
		handlerFavoritesActions,
		handlerLinkClick,
	}) => {
		const dispatch = useDispatch()
		const history = useHistory()
		const appendItems = useCallback(() => {
			dispatch(appendPurchases())
		}, [])

		useEffect(() => {
			document.addEventListener('scroll', onScroll)

			return function () {
				document.removeEventListener('scroll', onScroll)
			}
		}, [])

		const onScroll = (e: any) => {
			let scrollTop = e.target.documentElement.scrollTop
			let scrollHeight = e.target.documentElement.scrollHeight
			const height = window.innerHeight

			if (scrollHeight - (scrollTop + height) < 100) {
				appendItems()
			}
		}
		const [investProjectDetail, setInvestProjectDetail] = useState<null | HTMLElement>(null)

		return (
			<LoaderContainer isLoading={isLoadingInitList}>
				<ScrollHandler />
				<div className='purchase__list' onScroll={onScroll}>
					<>
						{list &&
							list.map((purchase, i) => {
								const openInvestProjectDetail = (event: React.MouseEvent<HTMLDivElement>) =>
									setInvestProjectDetail(event.currentTarget)

								const detailPurchase = {
									purchase: purchase,
									toggleInvestDetail: (event: React.MouseEvent<HTMLDivElement>) => {
										return purchase?.linkedProjectIds?.length ? openInvestProjectDetail(event) : null
									},
								}
								return (
									<Card projectId={purchase.id} key={purchase.id}>
										<CardLeft>
											<CardHeaderTitle
												getProjectById={getProjectById}
												path={{
													pathname: getPurchaseDetailRoute('plan', purchase.id),
													state: {
														from: history.location.pathname,
														fromName: 'Закупки',
													},
												}}
												title={purchase.registration_number}
												iconType='purchase-icon'
												isExpanded={details === purchase.id && !!details ? true : false}
												expandCardHandler={(id: ReactText) => {
													setDetails(id === details ? '' : id)
													dispatch(setExpandCardState(purchase))
												}}
												url_public={purchase.url}
												purchaseId={purchase.id}
												handlerFavoritesActions={handlerFavoritesActions}
												isFavorite={!!+purchase.isFavorite}
												handlerLinkClick={handlerLinkClick}
											>
												<CardDate>{`Изменено ${formatDate(purchase.last_modified_date)}`}</CardDate>
												<CardTitle>
													{company?.find(company => company.id === purchase.company_id)?.name || ''}
												</CardTitle>
												<CardDescription>{purchase.subject}</CardDescription>
												<div className='purchase_status'>{purchase.status ? purchase.status : '-'}</div>
											</CardHeaderTitle>
										</CardLeft>
										<CardRight>
											{CardRightSidePrices.map(
												price =>
													price.value(purchase) && (
														<CardItem title={price.title} value={price.value(purchase)} key={uniqueId('CardItem_')} />
													)
											)}
										</CardRight>
										<PurchasesCollapse
											{...detailPurchase}
											isExpand={details === purchase.id && !!details ? true : false}
											onList={true}
										/>
									</Card>
								)
							})}
					</>
				</div>
			</LoaderContainer>
		)
	}
)
export default PurchaseList
