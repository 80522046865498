import { Reducer } from 'redux'

import { AuthType } from '@root-gipro/store/actions'
import { IAuthState } from '@root-gipro/store/interfaces/state'

const initialState: IAuthState = {
	accessToken: localStorage.getItem('access_token'),
	expires_in: 0,
	user: { roleId: Number(localStorage.getItem('roleId')) || null, companyId: Number(localStorage.getItem('companyId')) },
}

export const authReducer: Reducer<IAuthState> = (state = initialState, action) => {
	switch (action.type) {
		case AuthType.SET_AUTH:
			return {
				...state,
				accessToken: action.accessToken,
				expires_in: action.expires_in,
			}
		default:
			return state
	}
}
