import React, { useEffect, useState } from 'react'
import { IAdminInputProps } from '@root-gipro/modules/admin/models/interfaces/admin-partials'
import { useDispatch } from 'react-redux'

const AdminInput: React.FC<IAdminInputProps> = props => {
    const [className, setClassName] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        setClassName(`admin-input ${props.error ? 'admin-input--error' : ''} ${props.postfix ? 'admin-input--postfix' : ''}`)
    }, [props.error, props.postfix])

    return (
        <label className="admin-label">
            <p>{ props.label }</p>

            <div className="admin-input__postfix-container">
                <input
                    step="0.01"
                    min="1900-01-01"
                    max="9999-12-31"
                    className={ className }
                    type={ props.inputType ?? 'text' }
                    placeholder={ props.placeholder }
                    defaultValue={ props.value }
                    onInput={(e: React.FormEvent<HTMLInputElement>) => props.onInput ? dispatch(props.onInput(e.currentTarget.value)) : null}
                />
                <span className="admin-input__postfix">{ props.postfix }</span>
            </div>

            <span className="admin-input__error">{ props.error }</span>
        </label>
    )
}

export default React.memo(AdminInput)
